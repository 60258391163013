import { t, plural } from '@lingui/macro';

export const lotteryTranslations = (value: string, amount: number) =>
    t({
        id: 'wizard.form.lottery.tickets',
        comment:
            'This is a translation for the amount of tickets in the lottery form. The value is the amount of tickets and the amount is the number of tickets.',
        message: plural(amount, {
            one: `${value} - # ticket`,
            other: `${value} - # tickets`,
        }),
    });
