import useAppConfig from '@hooks/useAppConfig';
import { useMemo } from 'react';
import {
    resolveComplianceListComponentOrThrow,
    ComplianceListIdentifier,
} from '../registry/complianceListRegistry';
import { ComplianceListComponent } from '../types/ComplianceList';

export default function useResolveComplianceListComponentForActiveCampaign(): ComplianceListComponent | null {
    const appConfig = useAppConfig();

    return useMemo(() => {
        const identifier = appConfig?.complianceListIdentifier;

        if (!identifier) {
            return null;
        }

        return resolveComplianceListComponentOrThrow(
            identifier as ComplianceListIdentifier,
        );
    }, [appConfig]);
}
