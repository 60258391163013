import React from 'react';
import { Trans } from '@lingui/macro';
import SubmitButton from '@primitives/form/SubmitButton';
import trans from './WizardFormFooter.trans';
import WizardFormComplianceLinks from './components/wizardFormComplianceLinks/WizardFormComplianceLinks';
import WizardFormUuid from './components/WizardFormUuid';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods}  props.form
 * @returns {React.ReactElement}
 */
function WizardFormFooter({ form }) {
    return (
        <div className="d-flex mt-4 align-items-center justify-content-between">
            <SubmitButton
                className="btn-lg btn-dark theme-btn mr-auto"
                isSubmitting={form.formState.isSubmitting}
            >
                <Trans id={trans.submit.id} />
            </SubmitButton>
            <WizardFormUuid form={form} />
            <WizardFormComplianceLinks />
        </div>
    );
}

WizardFormFooter.propTypes = {
    form: formPropTypes.isRequired,
};

export default WizardFormFooter;
