/**
 * Flattens a nested object
 *
 * @param {object} input
 * @param {string|null=} keyPrefix
 * @returns {object}
 */
export function flattenObject(input, keyPrefix = null) {
    let out = {};

    Object.entries(input).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            throw new Error('This utility does not support array values');
        }

        const outKey =
            typeof keyPrefix === 'string'
                ? [keyPrefix, key[0].toUpperCase() + key.slice(1)].join('')
                : key;

        if (
            typeof value === 'number' ||
            typeof value === 'boolean' ||
            typeof value === 'string' ||
            value === null ||
            value === undefined
        ) {
            out[outKey] = value;
        } else if (typeof value === 'object') {
            const nestedFlattened = flattenObject(value, outKey);

            out = {
                ...out,
                ...nestedFlattened,
            };
        } else {
            throw new Error();
        }
    });

    return out;
}
