import React from 'react';
import { Trans } from '@lingui/macro';

export const getEntriesOverviewCardLabels = () => ({
    noForms: (
        <Trans
            id="entries.message.noForms"
            comment="message that is shown when no forms have been filled in"
        >
            No forms have been filled in.
        </Trans>
    ),
    date: (
        <Trans
            id="entries.table.header.date"
            comment="table header for the date column"
        >
            Date
        </Trans>
    ),
    campaign: (
        <Trans
            id="entries.table.header.campaign"
            comment="table header for the campaign column"
        >
            Campaign
        </Trans>
    ),
    recruiter: (
        <Trans
            id="entries.table.header.recruiter"
            comment="table header for the recruiter column"
        >
            Recruiter
        </Trans>
    ),
    value: (
        <Trans
            id="entries.table.header.value"
            comment="table header for the value column"
        >
            Value
        </Trans>
    ),
    attribute: (
        <Trans
            id="entries.table.header.attribute"
            comment="table header for the date column"
        >
            Attribute
        </Trans>
    ),
});
