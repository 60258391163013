import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import FormSection from '@primitives/form/components/FormSection';
import FormRow from '@primitives/form/components/FormRow';
import DateTextInput from '@primitives/form/components/DateTextInput';
import AmountRadioInput from '../amountRadioInput/AmountRadioInput';
import AlternativeAmountInput from '../alternativeAmountInput/AlternativeAmountInput';
import labels from './WizardFormPayment.trans';
import IntervalSelectInput from '../intervalSelectInput/IntervalSelectInput';
import PreferredPaymentDayRadio from '../preferredPaymentDayRadio/PreferredPaymentDayRadio';
import usePreferredPaymentDayOptions from '../../hooks/usePreferredPaymentDayOptions';
import IBANInput from '../ibanInput/IBANInput';
import { WizardFormState } from '../../types/wizardFormState';
import AmountAfterTaxDeduction from './components/amountAfterTaxDeduction/AmountAfterTaxDeduction';

type Props = {
    extended?: boolean;
    defaultOption?: string;
    form: UseFormMethods<WizardFormState>;
};

const WizardFormPayment: FC<Props> = ({
    extended = false,
    defaultOption,
    form,
}) => {
    const [isExtended, setExtended] = useState(extended);
    const extendedClassName = { 'd-none': !isExtended };

    const onToggleOptions = () => {
        setExtended(!isExtended);
    };

    const options = usePreferredPaymentDayOptions();

    return (
        <FormSection
            title={labels.headerTitle}
            onToggleOptions={onToggleOptions}
        >
            <FormRow>
                <IntervalSelectInput
                    form={form}
                    className={classNames('col-sm-7', extendedClassName)}
                    defaultOption={defaultOption}
                />
                <DateTextInput
                    form={form}
                    className={classNames('col-sm-5', extendedClassName)}
                    name="startDate"
                    label={labels.startDate}
                    isDisabled={() => true}
                />

                <AmountRadioInput form={form} className="col-sm-9" />
                <AlternativeAmountInput form={form} className="col-sm-3" />
                {options && (
                    <PreferredPaymentDayRadio form={form} options={options} />
                )}
                <AmountAfterTaxDeduction form={form} />

                <IBANInput
                    form={form}
                    className="col-sm-12"
                    name="iban"
                    label={labels.iban}
                />
            </FormRow>
        </FormSection>
    );
};

export default WizardFormPayment;
