import { Workbox } from 'workbox-window';
import * as notificationSlice from './store/slice/notification/notificationSlice';
import store from './store';

const workbox =
    'serviceWorker' in navigator ? new Workbox('/service-worker.js') : null;

export default function registerServiceWorker() {
    const showSkipWaitingPrompt = () => {
        store.dispatch(notificationSlice.setUpdateAvailable(true));
    };

    workbox?.addEventListener('waiting', showSkipWaitingPrompt);
    workbox?.addEventListener('externalwaiting', showSkipWaitingPrompt);
    workbox?.register();
}

export function skipWaitingAndRefresh() {
    workbox?.addEventListener('controlling', () => {
        window.location.reload();
    });

    workbox?.messageSkipWaiting();
}
