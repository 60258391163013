import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Interval from '@constants/interval';
import useResolveComplianceListComponentForActiveCampaign from './hooks/useResolveComplianceListComponent';
import { WizardFormState } from '../../types/wizardFormState';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

const ComplianceList: FC<Props> = ({ form }) => {
    const interval = form.watch('interval');

    const ComplianceListForCampaign =
        useResolveComplianceListComponentForActiveCampaign();

    if (!interval) {
        return null;
    }

    return ComplianceListForCampaign ? (
        <ComplianceListForCampaign interval={interval as Interval} />
    ) : null;
};

export default ComplianceList;
