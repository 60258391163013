import firebase from 'firebase';
import getFirebaseAuth from './getFirebaseAuth';
import wrapFirebaseError from './wrapFirebaseError';
import { GetAuthContextResponseBody } from '../../../../shared/src/contracts/response/getAuthContext';

export async function signInWithEmailAndPassword(
    email: string,
    password: string,
    authContext: GetAuthContextResponseBody,
): Promise<{ id: string; name: string; tenant: string }> {
    return wrapFirebaseError(async () => {
        const auth = await getFirebaseAuth();
        auth.tenantId = authContext.tenantId;

        const credential = await auth.signInWithEmailAndPassword(
            email,
            password,
        );

        if (!credential.user) {
            throw new Error(
                'Expecting user to be available on signin credential',
            );
        }

        const { claims } = await credential.user.getIdTokenResult();

        return {
            id: claims.user_id,
            name: claims.name,
            tenant: claims.tenantId,
        };
    });
}

export async function getAuthorizationHeader() {
    return wrapFirebaseError(async () => {
        const auth = await getFirebaseAuth();
        const token = await auth.currentUser?.getIdToken();

        // noinspection SuspiciousTypeOfGuard
        return typeof token === 'string'
            ? { Authorization: `Bearer ${token}` }
            : null;
    });
}

/**
 * @returns {Promise<void>}
 */
export async function signOut() {
    return wrapFirebaseError(async () => {
        const auth = await getFirebaseAuth();
        await auth.signOut();
    });
}

export function onAuthStateChanged(listener: (signedIn: boolean) => void) {
    getFirebaseAuth().then((auth: firebase.auth.Auth) => {
        auth.onAuthStateChanged((user) => {
            listener(user !== null);
        });
    });
}
