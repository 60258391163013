import React, { FC, ComponentProps, MouseEventHandler } from 'react';
import { TextInput } from '@primitives/form/Form';
import FormText from '@primitives/form/FormText';
import { Trans } from '@lingui/react';
import Button from '@primitives/button/Button';
import { ensureFirstLetterIsUppercase } from '@utilities/textUtilities';
import labels from './SurnameInputWithSplitToPrefixSuggestion.trans';
import useSplitPrefixFromSurnameIfApplicable from './hooks/useSplitPrefixFromSurnameIfApplicable';

type Props = Omit<
    ComponentProps<typeof TextInput>,
    'name' | 'formatter' | 'label'
>;

const SurnameInputWithSplitToPrefixSuggestion: FC<Props> = ({
    form,
    children,
    ...otherProps
}) => {
    const splitPrefixFromSurname = useSplitPrefixFromSurnameIfApplicable(form);

    const onApplyClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (!splitPrefixFromSurname) {
            return;
        }

        form.setValue(
            'surname',
            ensureFirstLetterIsUppercase(splitPrefixFromSurname.surname),
        );
        form.setValue('surnamePrefix', splitPrefixFromSurname.surnamePrefix);
    };

    return (
        <TextInput
            form={form}
            name="surname"
            formatter="firstcase"
            label={labels.surnameFieldLabel.id}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
        >
            {children}
            {splitPrefixFromSurname && (
                <FormText role="alert">
                    <Trans
                        id={labels.suggestion.id}
                        values={{
                            prefix: (
                                <strong>{`'${splitPrefixFromSurname.surnamePrefix}'`}</strong>
                            ),
                        }}
                    />{' '}
                    <Button
                        type="button"
                        deflated
                        className="btn-link"
                        onClick={onApplyClick}
                    >
                        <Trans id={labels.applySuggestionButtonLabel.id} />
                    </Button>
                </FormText>
            )}
        </TextInput>
    );
};

export default SurnameInputWithSplitToPrefixSuggestion;
