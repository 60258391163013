import React, { FC } from 'react';
import { composeTailwindClassNames } from '@utilities/classNamesUtilities';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Card: FC<Props> = ({
    className: additionalClassName,
    children,
    ...props
}) => {
    const className = composeTailwindClassNames(
        'tw-bg-white tw-border tw-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] tw-m-auto tw-rounded-xl tw-border-solid tw-border-[#EAECF0]',
        additionalClassName,
    );

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...props} className={className}>
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-self-stretch tw-p-6">
                {children}
            </div>
        </div>
    );
};

export default Card;
