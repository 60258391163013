/* eslint-disable jsx-a11y/no-autofocus */

import React, {
    ReactNode,
    FC,
    HTMLInputTypeAttribute,
    ChangeEventHandler,
    FocusEventHandler,
    useMemo,
} from 'react';
import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import { resolveFormatterOrThrow } from '@resolver/formatterResolver';
import { InvalidFeedback } from '../../InvalidFeedback';
import { FormGroup } from '../../FormGroup';
import FormLabel from '../../FormLabel';

export type IsDisabledCallback = (watch: UseFormMethods['watch']) => boolean;

type Props = {
    form: UseFormMethods<any>;
    name: string;
    label?: string | ReactNode;
    className?: string;
    type?: HTMLInputTypeAttribute;
    spinner?: boolean;
    isDisabled?: IsDisabledCallback;
    formatter?: string;
    step?: string | number;
    sup?: string;
    autoFocus?: boolean;
    children?: ReactNode;
    required?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: ChangeEventHandler<HTMLInputElement>;
    tabIndex?: number;
    placeholderText?: string;
};

const TextInput: FC<Props> = ({
    form,
    name,
    label,
    className,
    type = 'text',
    spinner = false,
    isDisabled,
    formatter: formatterIdentifier,
    step,
    sup,
    autoFocus = false,
    children,
    required = false,
    onChange: customOnChangeHandler,
    onBlur: customOnBlurHandler,
    tabIndex,
    placeholderText,
}) => {
    const {
        register,
        watch,
        formState: { errors },
    } = form;

    const formatter = useMemo(
        () =>
            formatterIdentifier
                ? resolveFormatterOrThrow(formatterIdentifier)
                : null,
        [formatterIdentifier],
    );

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (formatter && formatter.onChange) {
            // eslint-disable-next-line no-param-reassign
            event.target.value = formatter.onChange(event.target.value);
        }

        if (customOnChangeHandler) {
            customOnChangeHandler(event);
        }
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        if (formatter && formatter.onBlur) {
            // eslint-disable-next-line no-param-reassign
            event.target.value = formatter.onBlur(event.target.value);
        }

        if (customOnBlurHandler) {
            customOnBlurHandler(event);
        }
    };

    return (
        <FormGroup className={classNames(className, 'position-relative')}>
            <FormLabel htmlFor={name} sup={sup}>
                {label}
            </FormLabel>
            <input
                readOnly={spinner || (isDisabled && isDisabled(watch))}
                type={type}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={(event) => event.target.select()}
                id={name}
                step={step}
                autoComplete="chrome-off" // @see https://stackoverflow.com/questions/15738259/disabling-chrome-autofill/30976223#30976223 for more context
                ref={register({ required })}
                className={classNames('form-control', {
                    'is-invalid': errors[name],
                })}
                autoFocus={autoFocus}
                tabIndex={tabIndex}
                placeholder={placeholderText}
            />
            {spinner && (
                <span
                    className="spinner-grow text-muted spinner-grow-sm"
                    role="alert"
                    aria-busy="true"
                />
            )}
            <InvalidFeedback error={errors[name]} />
            {children}
        </FormGroup>
    );
};

export default TextInput;
