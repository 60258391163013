/* eslint-disable no-param-reassign */

import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { postIdealQrCharge } from '@integrations/service/api';
import { IdealQrStatus } from '../../../../constants/idealQrStatus';
import {
    captureBreadcrumb,
    captureReduxError,
} from '../../../../utilities/errorCapturingUtilities';
import { IdealQrState } from '../types';

export const createIdealQrChargePendingReducer: CaseReducer<IdealQrState> = (
    state,
) => {
    state.isGenerating = true;
    state.hasGenerationError = false;
    state.codeId = null;
    state.codeUrl = null;
    state.isPolling = false;
    state.status = null;
};
export const createIdealQrChargeFulfilledReducer: CaseReducer<
    IdealQrState,
    PayloadAction<Awaited<ReturnType<typeof postIdealQrCharge>>>
> = (state, action) => {
    const { payload } = action;

    captureBreadcrumb(
        'Create Ideal QR charge fulfilled',
        'info',
        'idealqr',
        payload,
    );

    const codeId = payload.qrCodeId;

    state.isGenerating = false;
    state.codeId = codeId;
    state.codeUrl = payload.qrCodeUrl;
    state.status = IdealQrStatus.UNINITIALIZED;
};

export const createIdealQrChargeErrorReducer: CaseReducer<
    IdealQrState,
    PayloadAction<void, string, void, Error>
> = (state, action) => {
    captureBreadcrumb(
        'Create Ideal QR charge failed',
        'warning',
        'idealqr',
        action.error,
    );
    captureReduxError(action.error, 'warning');

    state.isGenerating = false;
    state.hasGenerationError = true;
    state.status = IdealQrStatus.FAILURE;
};
