import { IdealQrState } from '../types';

export function validateAmountIsAvailable(state: IdealQrState): void {
    if (!state.amount) {
        throw new Error(
            'Expecting iDeal QR amount to be available at this point',
        );
    }
}

export function validateStateContainsCodeReference(state: IdealQrState): void {
    if (!state.codeId || !state.codeUrl) {
        throw new Error(
            'Expecting codeId and codeUrl to be available at this point',
        );
    }
}
