import React from 'react';
import createClassName from 'classnames';
import useAppConfig from '@hooks/useAppConfig';
import version from '../../../../version.json';
import { getHomeFooterLabels } from './HomeFooter.trans';

export default function HomeFooter() {
    const labels = getHomeFooterLabels();
    const appConfig = useAppConfig();

    const showComplianceDocument =
        appConfig.complianceDocumentation !== undefined;

    const className = createClassName('tw-text-muted tw-mb-5 tw-text-center', {
        'sm:tw-w-1/3': showComplianceDocument,
    });

    return (
        <section className="xsmax:tw-text-center sm:tw-flex sm:tw-flex-wrap sm:tw-justify-end">
            <p className={className}>{version}</p>
            {showComplianceDocument && (
                <a
                    href={appConfig.complianceDocumentation}
                    target="_blank"
                    className="sm:tw-w-1/3 sm:tw-text-end tw-text-muted"
                    rel="noreferrer"
                >
                    {labels.complianceDocumentation}
                </a>
            )}
        </section>
    );
}
