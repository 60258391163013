export async function parseResponseError(response) {
    try {
        const responseBody = await response.json();
        return responseBody.error;
    } catch {
        return null;
    }
}

export function formatErrorMessage(message, additionalInfo = false) {
    return additionalInfo ? `${message} (${additionalInfo})` : message;
}

async function getErrorMessage(
    { status, statusText },
    serverError,
    localizedMessages,
) {
    const localizedMessage = localizedMessages[serverError?.key];
    if (localizedMessage) {
        return formatErrorMessage(localizedMessage, serverError?.message);
    }

    if (serverError?.message) {
        return serverError?.message;
    }

    const fallbackErrorMessage =
        status >= 500
            ? localizedMessages.serverError
            : localizedMessages.clientError;

    return formatErrorMessage(fallbackErrorMessage, statusText);
}

export default async function createErrorMessage(response, localizedMessages) {
    const serverError = await parseResponseError(response);
    return getErrorMessage(response, serverError, localizedMessages);
}
