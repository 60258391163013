import { createSlice } from '@reduxjs/toolkit';
import type { AppDispatch } from '../../index';
import { SliceNamespace } from '../../constants/namespace';
import { captureBreadcrumb } from '../../../utilities/errorCapturingUtilities';

export type OnlineState = boolean;

const initialState: OnlineState = navigator.onLine;

const onlineSlice = createSlice({
    name: SliceNamespace.Online,
    initialState,
    reducers: {
        updateOnline() {
            const isOnline = navigator.onLine;

            captureBreadcrumb('update online status', 'info', 'connection', {
                isOnline,
            });

            return isOnline;
        },
    },
});

export function attachOnlineListeners(dispatch: AppDispatch) {
    const listener = () => {
        dispatch(onlineSlice.actions.updateOnline());
    };

    window.addEventListener('online', listener);
    window.addEventListener('offline', listener);
}

export default onlineSlice;
