import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormContainer } from '@primitives/form/Form';
import TextInput from '@primitives/tapraise/form/TextInput';
import SubmitButton from '@primitives/tapraise/form/SubmitButton';
import { useAppSelector } from '@hooks/useAppSelector';
import Alert from '@primitives/alert/Alert';
import { getAuthFormLabels } from './AuthForm.trans';
import useIsOnline from '../../../../hooks/useIsOnline';
import useAuthFormState from './hooks/useAuthFormState';

export const AuthForm: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
    const hasUser = useAppSelector((state) => state.user.details !== null);
    const result = useAppSelector((state) => state.user.result);
    const online = useIsOnline();
    const labels = getAuthFormLabels();

    const form = useAuthFormState(onSuccess);

    return (
        <FormContainer onSubmit={form.onSubmit} form={form}>
            <Alert>{result?.errorMessage}</Alert>
            <TextInput
                form={form}
                name="email"
                label={labels.emailaddress}
                placeholder="Enter your email"
                required
            />
            <TextInput
                form={form}
                name="password"
                label={labels.password}
                type="password"
                placeholder="Enter your password"
                required
            />
            <SubmitButton
                aria-label="Log in"
                disabled={!online}
                spinner={result?.pending || form.formState.isSubmitting}
                className="tw-mt-1.5 tw-border-none primary-button tw-w-full hover:tw-text-[#FFF]"
            >
                {labels.login}
            </SubmitButton>
            {hasUser && (
                <Link
                    to="/"
                    className="tw-inline-block tw-leading-normal tw-text-center tw-no-underline tw-align-middle tw-cursor-pointer tw-text-base tw-w-full tw-select-none tw-mt-2.5 tw-p-2 tw-rounded-lg tw-border-[none] tw-text-[#212529] tw-bg-[#f8f9fa] hover:tw-text-[#212529] tw-shadow-xs tw-font-semibold"
                >
                    {labels.cancel}
                </Link>
            )}
        </FormContainer>
    );
};
