import { t } from '@lingui/macro';

export const getConnectionStatus = {
    loggedIn: () =>
        t({
            id: 'wizard.form.connectionStatus.loggedIn',
            comment: 'text for logged in status of user',
            message: 'logged in',
        }),
    loggedOut: () =>
        t({
            id: 'wizard.form.connectionStatus.loggedOut',
            comment: 'text for logged out status of user',
            message: 'logged out',
        }),
};
