import { getLocalizedErrorMessages } from '@integrations/errorMessages.trans';

const key = 'ArNLiGNuxa56FOgULWXxj9AlvQD68MHw9ho4RiVu';
const url = 'https://postcode-api.apiwise.nl';

async function unpack(response) {
    const responseBody = await response.json();
    if (response.status >= 200 && response.status <= 210) {
        // eslint-disable-next-line no-underscore-dangle
        const address = responseBody._embedded?.addresses?.[0];

        return {
            street: address?.street,
            city: address?.city?.label,
        };
    }

    const errors = getLocalizedErrorMessages();
    const error = new Error(errors.cantValidate);
    if (typeof responseBody === 'string') {
        error.message = responseBody;
    } else if (typeof responseBody === 'object') {
        error.message = responseBody.message || responseBody.error.message;
    }

    throw error;
}

async function get(path, signal) {
    const headers = { 'X-Api-Key': key };
    const response = await fetch(`${url}/v2/${path}`, { headers, signal });

    return unpack(response);
}

export async function getAddress(zip, number, signal) {
    const query = `?postcode=${encodeURIComponent(
        zip,
    )}&number=${encodeURIComponent(number)}`;

    return get(`addresses${query}`, signal);
}
