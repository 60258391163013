import { t } from '@lingui/macro';

export default {
    finishInstruction: () =>
        t({
            id: 'landing-page.payment.success.instructionsMessage',
            comment:
                'Message containing instructions to follow after successfull payment',
            message: 'Continue conversation with our ambassador.',
        }),
    visitWebsite: () =>
        t({
            id: 'landing-page.payment.success.visitWebsiteButton',
            comment: 'Text for button to visit website',
            message: 'Visit our website',
        }),
};
