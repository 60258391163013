import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { WizardFormState } from '../../types/wizardFormState';
import useHandleCategoryOnAmountChange from './hooks/useHandleCategoryOnAmountChange';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

const WizardFormOneTimePurchase: React.FC<Props> = ({ form }) => {
    const { register } = form;

    useHandleCategoryOnAmountChange(form);

    return <input type="hidden" name="categories" ref={register} />;
};

export default WizardFormOneTimePurchase;
