import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@primitives/form/Form';
import WizardFormAddressCompletion from '../wizardFormAddressCompletion/WizardFormAddressCompletion';
import labels from './WizardFormAddress.trans';
import CountryInput from '../countryInput/CountryInput';
import WizardFormAddressOptInsFormSection from '../wizardFormAddressOptInsFormSection/WizardFormAddressOptInsFormSection';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {boolean=} props.hideOptIns
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function WizardFormAddress({ hideOptIns = false, form }) {
    const [isSpinning, setSpinning] = useState(false);

    return (
        <WizardFormAddressOptInsFormSection hideOptIns={hideOptIns} form={form}>
            <WizardFormAddressCompletion
                form={form}
                setSpinning={setSpinning}
            />
            <CountryInput
                className="col-sm-2"
                form={form}
                label={labels.country.id}
            />
            <TextInput
                className="col-sm-4"
                name="zipCode"
                label={labels.zipCode.id}
                formatter="identifier"
                form={form}
            />
            <TextInput
                className="col-sm-3"
                name="houseNumber"
                label={labels.houseNumber.id}
                form={form}
            />
            <TextInput
                className="col-sm-3"
                name="houseNumberExtension"
                label={labels.houseNumberExtension.id}
                formatter="lowercase"
                form={form}
            />
            <TextInput
                className="col-sm-6"
                name="street"
                label={labels.street.id}
                spinner={isSpinning}
                form={form}
            />
            <TextInput
                className="col-sm-6"
                name="city"
                label={labels.city.id}
                spinner={isSpinning}
                form={form}
            />
        </WizardFormAddressOptInsFormSection>
    );
}

WizardFormAddress.propTypes = {
    hideOptIns: PropTypes.bool,
    form: formPropTypes.isRequired,
};

export default WizardFormAddress;
