/* eslint-disable no-param-reassign */

const margin = 16;
const headerFontSize = 16;
const footerFontSize = 11;
const contentFontSize = 36;

function fillRect(canvas) {
    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
}

export function fillWrappedText(
    canvas,
    text,
    y,
    fontSize,
    fill,
    font = 'Helvetica',
) {
    const x = margin;
    const maxWidth = canvas.width - margin * 2;
    const lineHeight = Math.round(fontSize * 1.4);

    const context = canvas.getContext('2d');
    context.font = `${fontSize}px ${font}`;
    context.fillStyle = '#444';
    y -= fontSize;

    const paragraphs = text.split('\n\n');
    for (let p = 0; p < paragraphs.length; p++) {
        y += fontSize * 2;

        const words = paragraphs[p].split(' ');
        // eslint-disable-next-line no-shadow
        let text = '';
        for (let n = 0; n < words.length; n++) {
            const testLine = `${text + words[n]} `;
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                if (fill) {
                    context.fillText(text, x, y);
                }
                text = `${words[n]} `;
                y += lineHeight;
            } else {
                text = testLine;
            }
        }

        if (fill) {
            context.fillText(text, x, y);
        }
    }

    return y;
}

function fillWrappedTexts(
    canvas,
    fill,
    verticalSpacing,
    headerText,
    footerText,
    contentText,
) {
    let endY = margin;

    if (headerText) {
        endY = fillWrappedText(canvas, headerText, endY, headerFontSize, fill);
    }
    endY += verticalSpacing;

    if (contentText) {
        endY = fillWrappedText(
            canvas,
            contentText,
            endY,
            contentFontSize,
            fill,
            'monospace',
        );
        endY += verticalSpacing;
    }

    if (footerText) {
        endY = fillWrappedText(canvas, footerText, endY, footerFontSize, fill);
    }

    return endY + margin;
}

export default function drawText(
    canvas,
    verticalSpacing,
    headerText,
    footerText,
    contentText = null,
) {
    canvas.height = fillWrappedTexts(
        canvas,
        false,
        verticalSpacing,
        headerText,
        footerText,
        contentText,
    );
    fillRect(canvas);
    fillWrappedTexts(
        canvas,
        true,
        verticalSpacing,
        headerText,
        footerText,
        contentText,
    );
}
