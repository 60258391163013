import React, { FC } from 'react';
import { composeTailwindClassNames } from '@utilities/classNamesUtilities';

type Props = React.HTMLAttributes<HTMLHeadingElement>;

const CardTitle: FC<Props> = ({
    className: additionalClassName,
    children,
    ...props
}) => {
    const className = composeTailwindClassNames('tw-m-0', additionalClassName);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <h6 {...props} className={className}>
            {children}
        </h6>
    );
};

export default CardTitle;
