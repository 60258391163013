import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@primitives/modal/Modal';
import { getCampaignNotificationModalLabels } from './CampaignNotificationModal.trans';
import { getWizardLabels } from '../../../../wizard/Wizard.trans';

/**
 * @param {object} props
 * @param {Function} props.onConfirm
 * @returns {React.ReactElement}
 */
function CampaignNotificationModal({ onConfirm }) {
    const labels = getWizardLabels();
    const campaignLabels = getCampaignNotificationModalLabels();
    return (
        <Modal>
            <div className="modal-header">
                <h5 className="modal-title">{campaignLabels.modalTitle}</h5>
                <button
                    type="button"
                    className="btn-close"
                    aria-label={labels.close}
                    onClick={() => onConfirm(false)}
                />
            </div>
            <div className="modal-body">
                <p>{campaignLabels.message}</p>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onConfirm(false)}
                >
                    {labels.close}
                </button>
            </div>
        </Modal>
    );
}

CampaignNotificationModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
};

export default CampaignNotificationModal;
