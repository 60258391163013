import { useMemo } from 'react';
import useAmountPresets from '@hooks/useAmountPresets';
import { asCurrencyFormat } from '@formatter/asCurrencyFormat';
import { AppConfigAmountPresets } from '../../../../../../../shared/src/contracts/model/appConfig';

function getAmountPresetsAsOptionMap(
    tenantAmountPresets: AppConfigAmountPresets,
): Map<string, string> {
    return Object.keys(tenantAmountPresets)
        .sort()
        .reduce(
            (map, key) =>
                map.set(
                    key,
                    tenantAmountPresets[key].map(asCurrencyFormat).join(' ∙ '),
                ),
            new Map(),
        );
}

export default function useAmountPresetsAsOptionMap() {
    const amountPresets = useAmountPresets();

    return useMemo(
        () => getAmountPresetsAsOptionMap(amountPresets),
        [amountPresets],
    );
}
