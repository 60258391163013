/* eslint-disable react/display-name */

import { t } from '@lingui/macro';
import { IdealQrStatus } from '@constants/idealQrStatus';

const translations = {
    title: () =>
        t({
            id: 'wizard.form.payment.ideal.title',
            comment: 'iDEAL QR code title',
            message: 'iDEAL QR',
        }),
    recurringDonationHeader: ({ amount, to }) =>
        t({
            id: 'wizard.form.payment.ideal.recurringDonationHeader',
            comment: 'Summary of iDEAL payment',
            message: `First payment of ${amount} to ${to}:`,
        }),
    oneTimeDonationHeader: ({ amount, to }) =>
        t({
            id: 'wizard.form.payment.ideal.oneTimeDonationHeader',
            comment: 'Summary of iDEAL payment',
            message: `One-time donation of ${amount} to ${to}:`,
        }),
    recurringPurchaseHeader: ({ amount, to }) =>
        t({
            id: 'wizard.form.payment.ideal.recurringPurchaseHeader',
            comment: 'Summary of iDEAL payment',
            message: `First payment of ${amount} to ${to}:`,
        }),
    oneTimePurchaseHeader: ({ amount, to }) =>
        t({
            id: 'wizard.form.payment.ideal.oneTimePurchaseHeader',
            comment: 'Summary of iDEAL payment',
            message: `One-time purchase of ${amount} to ${to}:`,
        }),
    notEnoughDataForMandateMessage: () =>
        t({
            id: 'wizard.form.payment.ideal.notEnoughDataForMandateMessage',
            comment:
                'Status message that tells the user that there is not yet enough data for a mandate, so the iDeal QR process cannot be started yet',
            message:
                'All required personal data and contact information above needs to be filled-in, before you can start the iDeal QR flow.',
        }),
    noAmountMessage: () =>
        t({
            id: 'wizard.form.payment.ideal.noAmountMessage',
            comment:
                'Status message that tells the user no amount was selected yet',
            message:
                'Select an amount to be able to start an iDeal QR payment.',
        }),
    startMessage: () =>
        t({
            id: 'wizard.form.payment.ideal.retrieveCode',
            comment: 'Button label to retrieve iDEAL QR code',
            message: 'Request a code to start an iDEAL QR payment.',
        }),
    scanCode: () =>
        t({
            id: 'wizard.form.payment.ideal.scanCode',
            comment: 'Brief description of iDEAL payment',
            message:
                'Scan the iDEAL QR code with your personal phone to complete an initial verification payment.',
        }),
    requestCode: () =>
        t({
            id: 'wizard.form.payment.ideal.requestCode',
            comment: 'Label for requesting iDEAL QR code',
            message: 'Request code',
        }),
    statusPaymentReceived: () =>
        t({
            id: 'wizard.form.payment.ideal.statusPaymentReceived',
            comment: 'label for successful payment received',
            message: 'Payment received from',
        }),
    retrieveStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.retrieveStatus',
            comment: 'Label for polling transaction status button',
            message: 'Retrieve status',
        }),
    failureError: () =>
        t({
            id: 'wizard.form.payment.ideal.statusError',
            comment: "label for ideal's status error",
            message:
                'Something went wrong, the payment could not be completed.',
        }),
    cancelledMessage: () =>
        t({
            id: 'wizard.form.payment.ideal.cancelledMessage',
            comment: "label for ideal's status cancelled message",
            message: 'Payment was cancelled, please try again.',
        }),
    pendingMessage: () =>
        t({
            id: 'wizard.form.payment.ideal.pendingMessage',
            comment: "label for ideal's status pending message",
            message: 'Payment is pending, please wait..',
        }),
    qrGenerationError: () =>
        t({
            id: 'wizard.form.payment.ideal.qrGenerationError',
            comment: "label for ideal's error status",
            message: 'Something went wrong, cannot generate iDEAL QR code.',
        }),
    connectionError: () =>
        t({
            id: 'wizard.form.payment.ideal.connectionError',
            comment:
                'error message that is shown if there is no internet connection',
            message:
                'An internet connection is required for iDEAL QR payments.',
        }),
    uninitializedStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.uninitializedStatus',
            comment: 'Label for the uninitialized status code',
            message: 'Payment progress has not started yet.',
        }),
    openStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.openStatus',
            comment: 'Label for the open status code',
            message: 'Payment pending…',
        }),
    cancelledStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.cancelledStatus',
            comment: 'Label for the cancelled status code',
            message: 'The payment was cancelled.',
        }),
    expiredStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.expiredStatus',
            comment: 'Label for the expired status code',
            message: 'The payment was not completed in time and has expired.',
        }),
    failureStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.failureStatus',
            comment: 'Label for the failure status code',
            message: 'The payment has failed.',
        }),
    successStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.successStatus',
            comment: 'Label for the success status code',
            message: 'The payment has succeeded.',
        }),
    pendingStatus: () =>
        t({
            id: 'wizard.form.payment.ideal.pendingStatus',
            comment: 'Label for the pending status code',
            message: 'The payment is pending.',
        }),
};

export default translations;

/**
 * @param {string} status
 * @returns {string}
 * @throws {Error}
 */
export function toHumanReadableStatus(status) {
    switch (status) {
        case IdealQrStatus.UNINITIALIZED:
            return translations.uninitializedStatus();

        case IdealQrStatus.OPEN:
            return translations.openStatus();

        case IdealQrStatus.CANCELLED:
            return translations.cancelledStatus();

        case IdealQrStatus.EXPIRED:
            return translations.expiredStatus();

        case IdealQrStatus.FAILURE:
            return translations.failureStatus();

        case IdealQrStatus.SUCCESS:
            return translations.successStatus();

        case IdealQrStatus.PENDING:
            return translations.pendingStatus();

        default:
            throw new Error(`Status '${status}' is not supported`);
    }
}
