import React from 'react';
import { Trans } from '@lingui/macro';

export const getSelectedCampaignLabels = () => ({
    noSelection: (
        <Trans
            id="campaign.message.noSelection"
            comment="text that is shown when no campaign has been selected"
        >
            No selection
        </Trans>
    ),
    noCampaignDownloaded: (
        <Trans
            id="campaign.message.noCampaignDownloaded"
            comment="text that is shown when no campaigns have been downloaded"
        >
            No campaigns have been downloaded
        </Trans>
    ),
    updatedOn: (
        <Trans
            id="campaign.updatedOn"
            comment="text for a timestamp that shows the last time the available campaigns were updated"
        >
            Last updated on
        </Trans>
    ),
});
