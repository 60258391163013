import './WizardFormComplianceLinks.trans';
import React from 'react';
import { Trans } from '@lingui/macro';
import useAppConfig from '@hooks/useAppConfig';

export default function WizardFormComplianceLinks() {
    const appConfig = useAppConfig();

    return Object.entries(appConfig.complianceLinks || {}).map(
        ([key, value]) => (
            <a
                rel="noreferrer"
                className="ml-2 text-muted"
                key={key}
                href={value}
                target="_blank"
            >
                <Trans id={key} />
            </a>
        ),
    );
}
