import React, { FC, MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@primitives/tapraise/form/Button';
import useActiveCampaign from '@hooks/useActiveCampaign';
import useUserDetails from '@hooks/useUserDetails';
import CampaignNotificationModal from './components/CampaignNotificationModal';
import useStorageStats from '../../hooks/useStorageStats';
import routes from '../../../../routes';
import { getStartNewFormLabels } from './StartNewForm.trans';

export const StartNewForm: FC = () => {
    const history = useHistory();

    const [modalVisible, setModalVisible] = useState(false);

    const labels = getStartNewFormLabels();

    const { isFull } = useStorageStats();

    const hasUserDetails = !!useUserDetails();

    const hasActiveCampaign = !!useActiveCampaign(false);

    const isDisabled = !hasUserDetails || isFull;

    const onClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (hasActiveCampaign) {
            history.push(routes.wizard.splash);
        }

        setModalVisible(true);
    };

    return (
        <>
            <Button
                disabled={isDisabled}
                aria-label="New"
                className="tw-bg-blue tw-text-base tw-border-none tw-flex tw-justify-center tw-items-center tw-gap-1.5 tw-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] tw-px-4 tw-py-2.5 tw-rounded-lg tw-m-0"
                onClick={onClick}
                type="button"
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.99996 4.16667V15.8333M4.16663 10H15.8333"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <p className="tw-font-semibold tw-text-white tw-m-0 tw-leading-6">
                    {labels.new}
                </p>
            </Button>
            {modalVisible && (
                <CampaignNotificationModal
                    onConfirm={() => setModalVisible(false)}
                />
            )}
        </>
    );
};
