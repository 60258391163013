/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { getIdealQrChargeStatus } from '@integrations/service/api';
import {
    IdealQrStatus,
    isFailureStatus,
    validateStatusIsSupported,
} from '../../../../constants/idealQrStatus';
import { captureReduxError } from '../../../../utilities/errorCapturingUtilities';
import { IdealQrState } from '../types';

export const fetchIdealQrPaymentStatusPendingReducer: CaseReducer<
    IdealQrState
> = (state) => {
    state.isPolling = true;
    state.hasStatusError = false;
};

export const fetchIdealQrPaymentStatusFulfilledReducer: CaseReducer<
    IdealQrState,
    PayloadAction<Awaited<ReturnType<typeof getIdealQrChargeStatus>>>
> = (state, action) => {
    const { payload } = action;

    const status = payload.status as IdealQrStatus;

    validateStatusIsSupported(status);

    state.isPolling = false;
    state.status = status;
    state.hasStatusError = isFailureStatus(status);
};

export const fetchIdealQrPaymentStatusErrorReducer: CaseReducer<
    IdealQrState,
    PayloadAction<void, string, void, Error>
> = (state, action) => {
    captureReduxError(action.error, 'warning');

    state.hasStatusError = true;
    state.isPolling = false;
    state.status = IdealQrStatus.FAILURE;
};
