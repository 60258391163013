/* eslint-disable no-shadow */

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedEffect } from '@hooks/useDebouncedEffect';
import 'abort-controller/polyfill';
import * as addressApi from '@integrations/address/addressApi';
import useIsOnline from '@hooks/useIsOnline';
import { formPropTypes } from '../../../../../../propTypes';
import {
    captureBreadcrumb,
    captureError,
} from '../../../../../../utilities/errorCapturingUtilities';

const dutchCountryCode = 'NL';

/**
 * @deprecated @todo move this to a hook, as this is not a component!
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {Function} props.setSpinning
 * @returns {null}
 */
function WizardFormAddressCompletion({ form, setSpinning }) {
    const { watch, setValue } = form;

    const online = useIsOnline();

    const country = watch('countryCode');
    const zip = watch('zipCode');
    const number = watch('houseNumber');
    const suffix = watch('houseNumberExtension');

    const isValid = () =>
        country === dutchCountryCode &&
        zip &&
        zip.length === 6 &&
        number &&
        number !== '';

    const getAddress = useCallback(
        (zip, number) => {
            setSpinning(true);
            const abortController = new AbortController();

            addressApi
                .getAddress(zip, number, abortController.signal)
                .then((address) => {
                    setValue('street', address.street);
                    setValue('city', address.city);
                })
                .catch((error) => {
                    captureError(error, {
                        level: 'warning',
                        extra: {
                            country,
                            zip,
                            number,
                            suffix,
                            error,
                        },
                    });
                })
                .then(() => setSpinning(false));

            return () => abortController.abort();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSpinning, setValue],
    );

    useDebouncedEffect(
        () => {
            if (online && isValid()) {
                captureBreadcrumb(
                    'autocompleting address',
                    'info',
                    'validation',
                    {
                        country,
                        zip,
                        number,
                        suffix,
                    },
                );

                getAddress(zip, number + (suffix || ''));
            }
        },
        1000,
        [country, zip, number, suffix],
    );

    return null;
}

WizardFormAddressCompletion.propTypes = {
    form: formPropTypes.isRequired,
    setSpinning: PropTypes.func.isRequired,
};

export default WizardFormAddressCompletion;
