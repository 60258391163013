import React from 'react';
import PropTypes from 'prop-types';
import T from './LandingPageSuccess.trans';

/**
 * @param {object} props
 * @param {string} props.websiteUrl
 * @returns {React.ReactElement}
 */
export function LandingPageSuccess({ websiteUrl }) {
    return (
        <div className="landing-page-backdrop theme">
            <div className="landing-page-backdrop-effect" />
            <div className="landing-page-backdrop-content py-5">
                <div className="container-fluid">
                    <h1 className="mb-4">{T.finishInstruction()}</h1>
                    <a
                        href={websiteUrl}
                        className="btn btn-light theme-btn btn-sm"
                    >
                        {T.visitWebsite()}
                    </a>
                </div>
            </div>
        </div>
    );
}

LandingPageSuccess.propTypes = {
    websiteUrl: PropTypes.string.isRequired,
};
