import { useMemo } from 'react';
import { resolveSelectedTemplate } from '@resolver/appConfigValueResolver';
import useAppConfig from './useAppConfig';

/**
 * Hook that determines whether a campaign is meant for non-profit or commercial.
 * This is mostly used to determine whether to show the copy "donation" or "purchase".
 *
 * @todo Discuss with team to introduce a new Firestore field to determine this, instead based on templatePath.
 */

export default function useIsCommercialCampaign() {
    const appConfig = useAppConfig();

    // @todo Stabilize and limit Second Nature usage of categories for addons:
    // https://tapraise.atlassian.net/browse/PD-5072
    const isCommercialCampaign = useMemo(
        () =>
            ['tmg/sn', 'tmg/snOneTimePurchase'].some((templatePath) =>
                resolveSelectedTemplate(appConfig, templatePath),
            ),
        [appConfig],
    );

    return isCommercialCampaign;
}
