import { parseFormattedDate, toISODateString } from '@formatter/dateFormatter';
import { RootState } from '@store/index';
import { Entry } from '@store/slice/buffer/types';
import version from '../../../../../version.json';
import { WizardFormState } from '../types/wizardFormState';

function determineAmount(
    amount: WizardFormState['amount'],
    amountAlternative: WizardFormState['amountAlternative'],
): number | null {
    if (!amount) {
        return null;
    }

    if (amount !== 'alt') {
        return parseFloat(amount);
    }

    if (!amountAlternative) {
        return null;
    }

    return parseFloat(amountAlternative);
}

export default function prepareFormData(
    data: WizardFormState,
    fieldAppOnline?: boolean,
    recruiter?: RootState['user']['details'],
    idealQrId?: string | null,
): Entry['data'] {
    const { userAgent } = window.navigator;
    const { dateOfBirth, startDate, amount, amountAlternative, ...rest } = data;

    return {
        fieldAppVersion: version,
        userAgent,
        startDate: toISODateString(parseFormattedDate(startDate)),
        dateOfBirth: toISODateString(parseFormattedDate(dateOfBirth)),
        amount: determineAmount(amount, amountAlternative),
        fieldAppOnline:
            typeof fieldAppOnline === 'boolean' ? fieldAppOnline : null,
        recruiter: recruiter?.id || null,
        idealQrId: idealQrId || null,
        ...rest,
    };
}
