import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { resolveTaxDeductionPercentage } from '@resolver/appConfigValueResolver';
import useAppConfig from '@hooks/useAppConfig';
import { FormGroup } from '@primitives/form/FormGroup';
import { asCurrencyFormat } from '@formatter/asCurrencyFormat';
import Interval from '@constants/interval';
import useMandateAmount from '../../../../hooks/useMandateAmount';
import { calculateAmountAfterTaxDeduction } from './calculator/amountAfterTaxDeducationCalculator';
import { getAmountAfterTaxDeductionLabels } from './AmountAfterTaxDeduction.trans';
import { WizardFormState } from '../../../../types/wizardFormState';
import { calculateTotalAmountPerYear } from './calculator/totalAmountPerYearCalculator';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

export const minTotalAmountThreshold = 40;

const AmountAfterTaxDeduction: FC<Props> = ({ form }) => {
    const appConfig = useAppConfig();
    const deductionPercentage = resolveTaxDeductionPercentage(appConfig);
    const amount = useMandateAmount(form);

    if (deductionPercentage === 0) {
        // No tax deduction (configured), so no need to show anything

        return null;
    }

    if (amount === null) {
        // No amount to do the calculation with, yet

        return null;
    }

    const interval = form.watch('interval');
    if (!interval) {
        // We need the interval to calculate the amount per year, that is a threshold for
        // displaying this alert.

        return null;
    }

    const totalAmountPerYear = calculateTotalAmountPerYear(
        amount,
        interval as Interval,
    );

    if (totalAmountPerYear < minTotalAmountThreshold) {
        // The total amount per year is below the threshold, so the user will not get any
        // tax deduction.

        return null;
    }

    const amountAfterTaxDeduction = calculateAmountAfterTaxDeduction(
        amount,
        deductionPercentage,
    );

    const labels = getAmountAfterTaxDeductionLabels(
        asCurrencyFormat(amountAfterTaxDeduction) as string,
        asCurrencyFormat(minTotalAmountThreshold) as string,
    );

    return (
        <FormGroup>
            <div className="alert my-2 alert-info">
                <span>{labels.description}</span>
            </div>
        </FormGroup>
    );
};

export default AmountAfterTaxDeduction;
