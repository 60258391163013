import blobToDataUrl from '../transformer/blobToDataUrl';

export default function dataUrlFromCanvas(canvas) {
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            blobToDataUrl(blob).then((dataUrl) => {
                resolve(dataUrl);
            });
        }, 'image/png');
    });
}
