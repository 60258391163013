/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import createClassName from 'classnames';

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {string} props.htmlBody
 * @returns {React.ReactElement}
 */
function Card({ className: additionalClassName, htmlBody }) {
    const className = createClassName(
        'card my-2 bg-light',
        additionalClassName,
    );

    return (
        <div className={className}>
            <div
                className="card-body small"
                dangerouslySetInnerHTML={{ __html: htmlBody }}
            />
        </div>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    htmlBody: PropTypes.string.isRequired,
};

export default Card;
