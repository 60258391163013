/* eslint-disable no-param-reassign, react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { formatAsLocaleDateTime } from '@utilities/dateTimeUtilities';
import { Entry } from '@store/slice/buffer/types';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { clearResultFromEntry } from '@store/slice/buffer/bufferSlice';
import { useLongPress } from 'react-use';
import { resolveIsEmergencyReSendEnabled } from '@resolver/appConfigValueResolver';
import useAppConfig from '@hooks/useAppConfig';
import EntryValue from './components/entryValue/EntryValue';
import EntryId from './components/entryId/EntryId';
import SendEntry from './components/sendEntry/SendEntry';
import { getEntriesOverviewCardLabels } from '../../EntriesOverview.trans';

type Props = {
    timestamp: string;
    entry: Entry;
};

const EntryTableRow: FC<Props> = ({ entry, timestamp }) => {
    const appConfig = useAppConfig();
    const dispatch = useAppDispatch();
    const labels = getEntriesOverviewCardLabels();

    const emergencyResendEnabled = resolveIsEmergencyReSendEnabled(appConfig);

    const onLongPress = async (event: TouchEvent | MouseEvent) => {
        if (!emergencyResendEnabled) {
            return;
        }

        // Although deprecated, this will prevent the context menu from showing up on iOS
        event.returnValue = false;

        await dispatch(clearResultFromEntry(timestamp));
    };

    const longPressEvent = useLongPress(onLongPress, {
        delay: 5000,
    });

    return (
        <tr>
            <td
                data-label={labels.date.props.message}
                className="disable-selection"
                {...longPressEvent}
            >
                {formatAsLocaleDateTime(parseInt(timestamp, 10))}
            </td>
            <td data-label={labels.campaign.props.message}>
                {entry.campaign?.name}
            </td>
            <td data-label={labels.recruiter.props.message}>
                {entry.recruiter.name}
            </td>
            <td data-label={labels.value.props.message}>
                <EntryValue entry={entry} />
            </td>
            <td>
                {entry.result ? (
                    <EntryId id={entry.result.id} />
                ) : (
                    <SendEntry timestamp={timestamp} />
                )}
            </td>
        </tr>
    );
};

export default EntryTableRow;
