import { useMemo } from 'react';
import { generateV4Uuid } from '@utilities/uuidGenerator';
import useAppConfig from '@hooks/useAppConfig';
import dateFormat from '@formatter/dateFormatter';
import {
    resolveDefaultCountryCode,
    resolveDefaultSignatureType,
} from '@resolver/appConfigValueResolver';
import { getDefaultLanguageOption } from '../resolver/languageOptionsResolver';
import { WizardFormState } from '../types/wizardFormState';
import { captureTag } from '../../../../../utilities/errorCapturingUtilities';

export default function useDetermineDefaultValues(): WizardFormState {
    const appConfig = useAppConfig();

    return useMemo(() => {
        const prefilled = localStorage.getItem('prefilled');
        const prefilledValues = prefilled && JSON.parse(prefilled);
        const defaultCountryCode = resolveDefaultCountryCode(appConfig);

        const formUuid = generateV4Uuid();

        // Capture the form uuid so that, if something goes wrong, we can track it back to the form
        captureTag('formUuid', formUuid);

        return {
            formUuid,
            preferredPaymentDay: null,
            language: getDefaultLanguageOption(
                appConfig.languagePreferenceOptions,
            ),
            countryCode: defaultCountryCode,
            iban: '',
            startDate: dateFormat(new Date()),
            subscribeToPost: appConfig.receiveMailDefaultValue ?? true,
            subscribeToNewsletter: appConfig.receiveEmailDefaultValue ?? true,
            subscribeToCalls: appConfig.receiveCallsDefaultValue ?? true,
            subscribeToSms: appConfig.receiveSmsDefaultValue ?? true,
            signatureType: resolveDefaultSignatureType(appConfig),
            ...prefilledValues,
        };
    }, [appConfig]);
}
