import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import RadioInput from '@primitives/tapraise/form/RadioInput';
import { getChangeUserPreferencesLabels } from './ChangeUserPreferences.trans';
import useAmountPresetsAsOptionMap from './hooks/useAmountPresetsAsOptionMap';
import useUserPreferencesFormState from './hooks/useUserPreferencesFormState';
import useUpdateAmountPresetValueOnFormChange from './hooks/useUpdateAmountPresetValueOnFormChange';

const ChangeUserPreferences: FC = () => {
    const form = useUserPreferencesFormState();

    useUpdateAmountPresetValueOnFormChange(form.watch);

    const amountPresetsOptions = useAmountPresetsAsOptionMap();

    const labels = getChangeUserPreferencesLabels();

    return (
        // eslint-disable-next-line jsx-a11y/no-redundant-roles
        <form role="form">
            <RadioInput
                form={form as UseFormMethods<any>}
                className="mb-0 tw-text-[#344054] tw-text-dark-gray tw-not-italic tw-font-medium tw-text-sm"
                name="amountPreset"
                label={labels.currencyOptions}
                required
                options={amountPresetsOptions}
            />
        </form>
    );
};

export default ChangeUserPreferences;
