import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import FormSection from '@primitives/form/components/FormSection';
import useActiveCampaign from '@hooks/useActiveCampaign';
import labels from './WizardFormProduct.trans';
import { formPropTypes } from '../../../../../../propTypes';

const AMOUNT_FIELD_NAME = 'amount';
const AMOUNT_ALTERNATIVE_FIELD_NAME = 'amountAlternative';
const CATEGORIES_FIELD_NAME = 'categories';

// @todo Stabilize and limit Second Nature usage of categories for addons:
// https://tapraise.atlassian.net/browse/PD-5072
const DEFAULT_AMOUNT = '3';
const DEFAULT_WITH_ADDON_AMOUNT = '12.50';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement|null}
 */
function WizardFormProduct({ form }) {
    const { register, control } = form;

    const campaign = useActiveCampaign(true);
    const products = campaign.categories;
    const productId = Object.keys(products)[0];

    const amount = useWatch({ control, name: AMOUNT_FIELD_NAME });
    const categories = useWatch({ control, name: CATEGORIES_FIELD_NAME });

    // We are changing here the amount field, which is in another component
    //
    // @todo Re-architecture React component structure for selling add-ons in the Field app:
    // https://tapraise.atlassian.net/wiki/spaces/PD/pages/73760808/Wall+of+Technical+Debt
    const setDefaultPrice = () => {
        form.setValue(AMOUNT_FIELD_NAME, DEFAULT_AMOUNT);
        form.setValue(AMOUNT_ALTERNATIVE_FIELD_NAME, '');
        form.setValue(CATEGORIES_FIELD_NAME, '');
    };

    const setDefaultPriceWithAddon = () => {
        form.setValue(AMOUNT_FIELD_NAME, 'alt');
        form.setValue(AMOUNT_ALTERNATIVE_FIELD_NAME, DEFAULT_WITH_ADDON_AMOUNT);
        form.setValue(CATEGORIES_FIELD_NAME, productId);
    };

    useEffect(() => {
        if (amount === 'alt') {
            setDefaultPriceWithAddon();
        } else {
            setDefaultPrice();
        }
    }, [amount]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!products || !Object.keys(products).length) {
        return null;
    }

    return (
        <FormSection title={labels.headerTitle.id}>
            {Object.keys(products).map((option) => {
                const checked = categories && categories === option;

                const { name } = products[option];

                return (
                    <div
                        className="card"
                        style={{ width: '18rem' }}
                        key={option}
                    >
                        <div className="card-body">
                            <h5 className="card-title">{name}</h5>
                            <p className="card-text">
                                <Trans id={labels.productDescription.id} />
                            </p>
                            <input
                                id={CATEGORIES_FIELD_NAME}
                                name={CATEGORIES_FIELD_NAME}
                                type="checkbox"
                                value={option}
                                ref={register}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setDefaultPriceWithAddon();
                                    } else {
                                        setDefaultPrice();
                                    }
                                }}
                                className="btn-check"
                                autoComplete="off"
                            />
                            <label
                                className="btn btn-outline-primary"
                                htmlFor={CATEGORIES_FIELD_NAME}
                            >
                                {checked ? (
                                    <Trans id={labels.addedToCart.id} />
                                ) : (
                                    <Trans id={labels.addToCart.id} />
                                )}
                            </label>
                        </div>
                    </div>
                );
            })}
        </FormSection>
    );
}

WizardFormProduct.propTypes = {
    form: formPropTypes.isRequired,
};

export default WizardFormProduct;
