import Mailcheck from 'mailcheck';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

// List of most used domains in emails in the past
const domains = [
    'gmail.com',
    'hotmail.com',
    'live.nl',
    'outlook.com',
    'ziggo.nl',
    'home.nl',
    'kpnmail.nl',
    'yahoo.com',
    'hotmail.nl',
    'icloud.com',
    'planet.nl',
    'hetnet.nl',
    'xs4all.nl',
    'upcmail.nl',
    'casema.nl',
    'web.de',
    'gmx.de',
    'msn.com',
    'me.com',
    'kpnplanet.nl',
    'telenet.be',
    'chello.nl',
    'zeelandnet.nl',
    'quicknet.nl',
    'skynet.be',
    'telfort.nl',
    'yahoo.fr',
    'live.com',
    'hotmail.fr',
    'hotmail.be',
    'live.be',
    'online.nl',
    't-online.de',
    'zonnet.nl',
    'hotmail.de',
    'yahoo.de',
    'tele2.nl',
    'outlook.be',
    'gmx.net',
    'solcon.nl',
    'caiway.nl',
    'yahoo.co.uk',
    'mac.com',
    'hccnet.nl',
    'wxs.nl',
    'googlemail.com',
    'protonmail.com',
    'trustmarketing.nl',
    'kliksafe.nl',
];

/**
 * @param {string} email
 * @returns {boolean}
 */
function checkHasBasicEmailFormat(email) {
    if (!email) {
        return false;
    }

    const parts = email.split('@');

    if (parts.length !== 2) {
        return false;
    }

    const domain = parts[1];

    return domain.includes('.');
}

/**
 * @param {string} email
 * @returns {string|null}
 */
export default function useEmailSuggestion(email) {
    const [suggestion, setSuggestion] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkForSuggestion = useCallback(
        debounce(() => {
            Mailcheck.run({
                email,
                domains,
                suggested: ({ full }) => setSuggestion(full),
                empty: () => setSuggestion(null),
            });
        }, 500),
        [email],
    );

    useEffect(() => {
        // Ensure that the input at least has a basic email like format, to make sure that
        // no irrelevant suggestions are returned
        if (!checkHasBasicEmailFormat(email)) {
            setSuggestion(null);

            return;
        }

        checkForSuggestion(email);

        // eslint-disable-next-line consistent-return
        return () => checkForSuggestion.cancel();
    }, [email, checkForSuggestion]);

    return suggestion;
}
