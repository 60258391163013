import React, { FC } from 'react';
import Button from '@primitives/button/Button';
import { isDevEnvironment } from '@utilities/environmentIdentificationUtilities';
import routes from '../../../routes';
import { getErrorBoundaryLabels } from '../ErrorBoundary.trans';

type Props = {
    error: Error;
    resetErrorBoundary: () => void;
};

const OnErrorFallback: FC<Props> = ({ error, resetErrorBoundary }) => {
    const labels = getErrorBoundaryLabels();

    const isNotHomepage = window.location.pathname !== routes.home;

    return (
        <div className="container-md mt-3" role="alert">
            <div className="card text-danger">
                <header className="card-header">
                    <h1>{labels.title}</h1>
                    <h2>{error.message}</h2>
                </header>
                <div className="card-body">
                    <p>{labels.supportIntroduction}</p>
                    {isDevEnvironment && error.stack && (
                        <pre>{error.stack}</pre>
                    )}
                </div>
                <footer className="card-footer">
                    <p>{labels.whatToDoNow}</p>
                    <ul>
                        <li>
                            <Button
                                type="button"
                                className="btn-link p-0"
                                onClick={resetErrorBoundary}
                            >
                                {labels.retry}
                            </Button>
                        </li>
                        {isNotHomepage && (
                            <li>
                                <a
                                    href={routes.home}
                                    className="btn btn-link p-0"
                                >
                                    {labels.backToDashboard}
                                </a>
                            </li>
                        )}
                    </ul>
                </footer>
            </div>
        </div>
    );
};

export default OnErrorFallback;
