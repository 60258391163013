/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';
import { BufferState, Entry } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const addEntryReducer: CaseReducer<
    BufferState,
    PayloadAction<{
        data: Entry['data'];
        recruiter: NonNullable<RootState['user']['details']>;
        campaign: NonNullable<RootState['tenant']['campaigns']['active']>;
        timestamp: string;
    }>
> = (state, action) => {
    const { data, recruiter, campaign, timestamp } = action.payload;
    const { signaturePayload, ...rest } = data;

    const entry: Entry = {
        data: rest,
        signaturePayload,
        recruiter: {
            name: recruiter.name,
        },
        campaign: {
            id: campaign.id,
            name: campaign.name,
            slug: campaign.slug,
        },
    };

    captureBreadcrumb('Entry added', 'info', 'submit', entry);

    state.entries[timestamp] = entry;
};
