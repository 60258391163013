import React, { FC } from 'react';
import { i18n } from '@lingui/core';
import { asCurrencyFormat } from '@formatter/asCurrencyFormat';
import { Entry } from '@store/slice/buffer/types';
import trans from './EntryValue.trans';

type Props = {
    entry: Entry;
};

const EntryValue: FC<Props> = ({ entry }) => {
    const { data } = entry;

    const value = entry.data.interval;
    if (!value) {
        return null;
    }

    const message = trans[value];
    if (!message) {
        throw new Error(`No message for interval '${value}'`);
    }

    const transId = message.id as string;

    return <>{`${i18n._(transId)} × ${asCurrencyFormat(data.amount)}`}</>;
};

export default EntryValue;
