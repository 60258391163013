import React, { FC } from 'react';
import { composeTailwindClassNames } from '@utilities/classNamesUtilities';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    spinner?: boolean;
}

const Button: FC<Props> = ({
    type = 'button',
    disabled,
    children,
    className: additionalClassName,
    spinner = false,
    ...props
}) => {
    const className = composeTailwindClassNames(
        'tw-no-underline tw-border tw-bg-white tw-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] tw-text-[#344054] tw-text-sm tw-not-italic tw-font-semibold tw-m-0 tw-px-3.5 tw-py-2.5 tw-rounded-lg tw-border-solid tw-border-[#D4DBDF]',
        additionalClassName,
    );

    return (
        <button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={className}
            disabled={spinner || disabled}
        >
            {spinner && (
                <span
                    className="tw-mr-1 spinner-grow spinner-grow-sm"
                    aria-busy={spinner}
                    role="alert"
                />
            )}
            {children}
        </button>
    );
};

export default Button;
