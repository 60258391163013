import React from 'react';
import WizardBackdrop from '../wizardBackdrop/WizardBackdrop';
import { getThanksLabels } from './WizardThanks.trans';

export default function WizardThanks() {
    const labels = getThanksLabels();

    return (
        <WizardBackdrop prefix="thanks" defaultTitle={labels.defaultTitle} />
    );
}
