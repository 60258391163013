import { defineMessage } from '@lingui/macro';

export default {
    headerTitle: defineMessage({
        id: 'wizard.form.products.headerTitle',
        comment: 'Title of the products section of the form',
        message: 'Products',
    }),
    productDescription: defineMessage({
        id: 'wizard.form.products.product.description',
        comment: 'Description of the product',
        message: 'Choose this add-on to upgrade your subscription.',
    }),
    addToCart: defineMessage({
        id: 'wizard.form.products.addToCart',
        comment: 'Button to add product to cart',
        message: 'Add to cart',
    }),
    addedToCart: defineMessage({
        id: 'wizard.form.products.addedToCart',
        comment: 'Button that shows product is added to cart',
        message: 'Added',
    }),
};
