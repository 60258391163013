import React from 'react';
import { Trans } from '@lingui/macro';
import { StorageStats } from '../../hooks/useStorageStats';

export const getStorageUsageLabels = (stats: StorageStats) => ({
    isFull: (
        <Trans
            id="storagespace.alert.isFull"
            comment="alert that is shown when the available storagespace is full, submitted forms will have to be sent first to clear up space"
        >
            The storage space is full. Send the submitted forms before filling
            in a new form.
        </Trans>
    ),
    almostFull: (
        <Trans
            id="storagespace.alert.almostFull"
            comment="alert that is shown when the available storagespace is almost full, submitted forms will have to be sent first to clear up space"
        >
            The storage space is almost full. Send the submitted forms before
            filling in a new form.
        </Trans>
    ),
    title: (
        <Trans
            id="storagespace.title"
            comment="title for the storage space progress bar"
        >
            Storage Space
        </Trans>
    ),
    percentageInUse: (
        <Trans
            id="storagespace.percentageInUse"
            comment="shows how much storage space is in use as a percentage"
        >
            {stats.percentage}% in use
        </Trans>
    ),
});
