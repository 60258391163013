import React, { FC, ReactElement } from 'react';
import { CheckInput } from '@primitives/form/components/CheckInput';
import useAppConfig from '@hooks/useAppConfig';
import { UseFormMethods } from 'react-hook-form';
import { resolveIsMailOptInLocked } from '@resolver/appConfigValueResolver';
import labels from '../../wizardFormAddress/WizardFormAddress.trans';
import resolveForOptInEnabledAndLabel from '../../wizardFormPersonalOptInsFormSection/components/personalOptIns/resolver/resolveForOptInEnabledAndLabel';

type Props = {
    form: UseFormMethods<any>;
};

const AddressOptIns: FC<Props> = ({ form }): ReactElement => {
    const appConfig = useAppConfig();

    // When there are no label(s), we hide the opt-in(s) from view. This keeps the component mounted and able to pass on its state

    const [mailOptInEnabled, mailOptInLabel] = resolveForOptInEnabledAndLabel(
        appConfig.receiveMailLabel,
        labels.mailLabelText.id as string,
    );

    const mailOptInLocked = resolveIsMailOptInLocked(appConfig);

    return (
        <CheckInput
            disabled={mailOptInLocked}
            form={form}
            name="subscribeToPost"
            label={mailOptInLabel}
            className={mailOptInEnabled ? undefined : 'd-none'}
        />
    );
};

export default AddressOptIns;
