import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const util = PhoneNumberUtil.getInstance();
const defaultCountryCode = 'NL';

function parsePhoneNumber(value: string, countryCode: string) {
    return util.parse(value, countryCode);
}

export function phoneNumberFormatterOnBlur(
    value: string,
    countryCode: string | null,
): string {
    try {
        const number = parsePhoneNumber(
            value,
            countryCode || defaultCountryCode,
        );

        return util.format(number, PhoneNumberFormat.INTERNATIONAL);
    } catch {
        return value;
    }
}

export function phoneNumberIsValid(
    value: string,
    countryCode: string | null,
): boolean {
    try {
        const number = parsePhoneNumber(
            value,
            countryCode || defaultCountryCode,
        );
        return util.isValidNumber(number);
    } catch {
        return false;
    }
}
