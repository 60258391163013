import { UseFormMethods } from 'react-hook-form';
import { parseFormattedDate } from '@formatter/dateFormatter';
import { differenceInYears } from '@utilities/dateTimeUtilities';
import { WizardFormState } from '../../../types/wizardFormState';

export default function useDetermineDonorAge(
    form: UseFormMethods<WizardFormState>,
): number | null {
    const dateOfBirthAsFormattedString = form.watch('dateOfBirth');
    if (!dateOfBirthAsFormattedString) {
        return null;
    }

    const dateOfBirth = parseFormattedDate(
        dateOfBirthAsFormattedString,
    ) as Date;

    const today = new Date();

    return differenceInYears(today, dateOfBirth);
}
