/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { BufferState, PushEntryResponse } from '../types';
import {
    captureBreadcrumb,
    captureReduxError,
} from '../../../../utilities/errorCapturingUtilities';

export const fetchPushEntryPendingReducer: CaseReducer<BufferState> = (
    state,
    action,
) => {
    const timestamp: string = action.meta.arg;

    state.result.pending = true;
    state.result.pendingTimestamp = parseInt(timestamp, 10);
    state.result.errorMessage = null;
};

export const fetchPushEntryFulfilledReducer: CaseReducer<
    BufferState,
    PayloadAction<PushEntryResponse>
> = (state, action) => {
    const { timestamp, ...result } = action.payload;

    captureBreadcrumb('Push entry fulfilled', 'info', 'submit', action.payload);

    state.result.pending = false;
    state.result.lastSuccess = new Date().getTime();
    state.entries[timestamp].result = result;

    delete state.entries[timestamp].signaturePayload;
};

export const fetchPushEntryPendingRejectedReducer: CaseReducer<
    BufferState,
    PayloadAction<void, string, void, Error>
> = (state, action) => {
    captureBreadcrumb('Push entry failed', 'warning', 'submit', action.error);
    captureReduxError(action.error, 'warning');

    state.result.pending = false;
    state.result.pendingTimestamp = false;
    state.result.errorMessage = action.error.message;
};
