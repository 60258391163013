import mapValues from 'lodash/mapValues';
import { i18n } from '@lingui/core';
import { asCurrencyFormat } from '@formatter/asCurrencyFormat';
import Interval from '@constants/interval';
import { resolveIdealQrIsEnabled } from '@resolver/appConfigValueResolver';
import { intervalLabels } from '../../../WizardForm.trans';

/**
 * @param {string|null|undefined} iban
 * @returns {string|undefined}
 */
const maskIban = (iban) =>
    iban?.replace(
        /^(\w{2})([\w.]+)(\w{3})$/,
        (_, start, mid, end) => start + '*'.repeat(mid.length) + end,
    );

const mapValuesPlaceholders = (object) =>
    mapValues(object, (value) => (value && value !== '' ? value : '...'));

const or = (a, b) => (a?.length > 0 ? a : b);
const concat = (values) =>
    values.filter((component) => component && component !== '').join(' ');

/**
 * @param {Object<string, any>} formData
 * @param {module:Campaign.Campaign} campaign
 * @param {module:AppConfig.AppConfig} appConfig
 * @returns {{ isIdeal: boolean, campaign: {name: string}, isRecurring: boolean, formData: Object<string, any> }}
 */
export default function formatTemplateData(formData, campaign, appConfig) {
    const maskedIban = maskIban(formData.iban);

    return {
        campaign: {
            name: appConfig?.idealQr?.beneficiary ?? campaign.name,
        },
        isRecurring: formData.interval !== Interval.oneTime,
        isIdeal: resolveIdealQrIsEnabled(appConfig),
        formData: mapValuesPlaceholders({
            ...formData,
            iban: maskedIban,
            ibanSuffix: maskedIban ? maskedIban.slice(-3) : null,
            periodLabel:
                formData.interval &&
                i18n._(intervalLabels[formData.interval]).toLowerCase(),
            amount: asCurrencyFormat(
                formData.amount === 'alt'
                    ? formData.amountAlternative
                    : formData.amount,
            ),
            fullHouseNumber: concat([
                formData.houseNumber,
                formData.houseNumberExtension,
            ]),
            fullName: concat([
                or(formData.firstname, formData.initials),
                formData.surnamePrefix,
                formData.surname,
            ]),
        }),
    };
}
