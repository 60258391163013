import React from 'react';
import { Trans } from '@lingui/macro';

export const getAlternativeAmountInputLabels = () => ({
    alternativeAmount: (
        <Trans
            id="wizard.form.alternativeAmount.label"
            comment="text for the alternative amount label"
        >
            Other amount
        </Trans>
    ),
});
