/* eslint-disable react/display-name */

import { t, Trans } from '@lingui/macro';
import React from 'react';

export default {
    Label: () => (
        <Trans
            id="wizard.form.confirmation.smsSignature.label"
            comment="label for the signature verification code"
        >
            Verification code (SMS)
        </Trans>
    ),
    Send: () => (
        <Trans
            id="wizard.form.confirmation.smsSignature.button"
            comment="button to send verification code"
        >
            Send code
        </Trans>
    ),
    Retry: () => <Trans id="button.retry">Retry</Trans>,
    statusLabelDefault: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelDefault',
            comment: "label for the signature's default status",
            message: 'Send a SMS verification',
        }),
    statusLabelCorrect: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelCorrect',
            comment: 'text that is shown in label if the SMS code is correct',
            message: 'Code is correct',
        }),
    statusLabelIncorrect: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelIncorrect',
            comment: 'text that is shown in label if the SMS code is incorrect',
            message: 'Code does not match',
        }),
    statusLabelSending: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelSending',
            comment: "label for the signature's sending status",
            message: 'Sending…',
        }),
    statusLabelSent: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelSent',
            comment: "label for the signature's sent status",
            message: 'SMS has been sent',
        }),
    statusLabelError: () =>
        t({
            id: 'wizard.form.confirmation.smsSignature.statusLabelError',
            comment: "label for the signature's error status",
            message:
                'Something went wrong, please try again or use a different signature.',
        }),
};
