import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';
import FormSection from '@primitives/form/components/FormSection';
import FormRow from '@primitives/form/components/FormRow';
import DateTextInput from '@primitives/form/components/DateTextInput';
import AmountRadioInput from '../amountRadioInput/AmountRadioInput';
import AlternativeAmountInput from '../alternativeAmountInput/AlternativeAmountInput';
import translations from './WizardFormIdealPledge.trans';
import IntervalSelectInput from '../intervalSelectInput/IntervalSelectInput';
import useDetermineAmountShouldBeDisabled from './hooks/useDetermineAmountShouldBeDisabled';
import useResetAmountOnIntervalChange from './hooks/useResetAmountOnIntervalChange';
import { WizardFormState } from '../../types/wizardFormState';

type Props = {
    form: UseFormMethods<WizardFormState>;
    title?: string;
    extended?: boolean;
    showAlternativeAmount?: boolean;
};

const WizardFormIdealPledge: React.FC<Props> = ({
    form,
    title = translations.headerTitle,
    extended = false,
    showAlternativeAmount = true,
}) => {
    const [showExtendedSection, setShowExtendedSection] = useState(extended);

    useResetAmountOnIntervalChange(form);

    const onToggleOptions = () => {
        setShowExtendedSection((currentValue) => !currentValue);
    };

    const amountShouldBeDisabled = useDetermineAmountShouldBeDisabled(
        form.watch,
    );

    return (
        <FormSection title={title} onToggleOptions={onToggleOptions}>
            <FormRow>
                <IntervalSelectInput
                    form={form}
                    className={classNames(
                        'col-sm-6',
                        showExtendedSection ? '' : 'd-none',
                    )}
                />
                <DateTextInput
                    form={form}
                    className={classNames(
                        'col-sm-6',
                        showExtendedSection ? '' : 'd-none',
                    )}
                    name="startDate"
                    label={translations.startDate}
                    isDisabled={() => true}
                />

                <AmountRadioInput
                    form={form}
                    disabled={amountShouldBeDisabled}
                    className="col-sm-9"
                    applyDefaultValue={false}
                    showOther={showAlternativeAmount}
                />

                {showAlternativeAmount && (
                    <AlternativeAmountInput
                        form={form}
                        disabled={amountShouldBeDisabled}
                        className="col-sm-3"
                    />
                )}
            </FormRow>
        </FormSection>
    );
};

export default WizardFormIdealPledge;
