import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@primitives/form/Form';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {Function} props.watch
 * @param {Function} props.getValues
 * @param {Function} props.setValue
 */
function useInitialsCompletion({ watch, getValues, setValue }) {
    const firstName = watch('firstname');
    const initials = firstName?.substring(0, 1);

    useEffect(() => {
        const currentValue = getValues('initials');
        if (!currentValue && initials) {
            setValue('initials', `${initials.toUpperCase()}.`);
        }
    }, [getValues, initials, setValue]);
}

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string|Record<string, any>} props.label
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
function InitialsTextInput({ form, label, className }) {
    useInitialsCompletion(form);

    return (
        <TextInput
            form={form}
            name="initials"
            formatter="initials"
            label={label || 'initials'}
            className={className}
        />
    );
}

InitialsTextInput.propTypes = {
    form: formPropTypes.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired, // = Translation definition
    ]), // Not required, as it is sometimes hidden in layouts, and then no label is applied
    className: PropTypes.string,
};

export default InitialsTextInput;
