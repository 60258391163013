import { set } from 'lodash';

export default function transformToMap(list, key, value = false) {
    return list
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce(
            (map, item) => set(map, item[key], value ? item[value] : item),
            {},
        );
}
