import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactCanvas from 'react-signature-canvas';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { FormGroup } from '@primitives/form/FormGroup';
import { phoneNumberIsValid } from '@formatter/phoneNumberFormatter';
import Button from '@primitives/button/Button';
import useAppConfig from '@hooks/useAppConfig';
import useIsOnline from '@hooks/useIsOnline';
import useActiveCampaign from '@hooks/useActiveCampaign';
import drawText from '../../handler/drawText';
import '../signatureCanvas/SignatureCanvas.scss';
import useTextTemplate from '../../hooks/useTextTemplate';
import formatTemplateData from '../../formatter/formatTemplateData';
import T from './SignatureVerificationSms.trans';
import sendVerificationCode from './handler/sendVerificationCode';
import './SignatureVerificationSms.scss';
import templateKeys from '../../constants/templateKeys';
import RetryTimeoutButton from './components/retryTimeoutButton/RetryTimeoutButton';
import dataUrlFromCanvas from '../../factory/dataUrlFromCanvas';
import useDetermineVerificationSMSMessageBody from './hooks/useDetermineVerificationSMSMessageBody';
import { formPropTypes } from '../../../../../../../../propTypes';

const signatureHeight = 200;
const signatureWidth = 508;
const verticalSpacing = 25;

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string} props.name
 * @returns {React.ReactElement}
 */
function SignatureVerificationSms({ form, name }) {
    const {
        watch,
        setValue,
        trigger,
        formState: { errors },
    } = form;

    const campaign = useActiveCampaign(true);
    const appConfig = useAppConfig();

    const canvasRef = useRef();
    const online = useIsOnline();

    const [inputValue, setInputValue] = useState('');
    const [code, setCode] = useState(null);
    const [isSending, setSending] = useState(false);
    const [smsLabel, setLabel] = useState(T.statusLabelDefault());
    const [isLabelError, setLabelError] = useState(false);

    const mobilePhoneNumber = watch('mobilePhoneNumber');
    const hasMobilePhoneNumber = useMemo(
        () => phoneNumberIsValid(mobilePhoneNumber),
        [mobilePhoneNumber],
    );
    const hasCode = code !== null;
    const isVerified = code === inputValue;

    const showTextInput = !isSending && hasCode;
    const showSendButton = !hasCode;

    const formData = formatTemplateData(watch(), campaign, appConfig);
    const footerText = useTextTemplate(
        appConfig,
        templateKeys.footer,
        formData,
    );
    const headerText = useTextTemplate(
        appConfig,
        templateKeys.header,
        formData,
    );

    const verificationSMSMessageBody = useDetermineVerificationSMSMessageBody(
        appConfig,
        formData,
    );

    const reset = useCallback(() => {
        setInputValue('');
        setValue(name, '');
        setCode(null);
        setLabelError(false);
        setLabel(T.statusLabelDefault());
    }, [name, setValue]);

    const onSendClick = useCallback(async () => {
        reset();
        setSending(true);
        setLabel(T.statusLabelSending);
        try {
            setCode(
                await sendVerificationCode(
                    mobilePhoneNumber,
                    verificationSMSMessageBody,
                ),
            );
            setLabel(T.statusLabelSent);
        } catch (error) {
            setLabelError(true);
            setLabel(`${T.statusLabelError()} - ${error.message}`);
        }
        setSending(false);
    }, [reset, mobilePhoneNumber, verificationSMSMessageBody]);

    const onTextInputChange = useCallback(
        (event) => {
            const formatted = event.target.value
                .match(/([0-9]{1,3})/g)
                ?.join('-');

            setInputValue(formatted || '');
        },
        [setInputValue],
    );

    const onInputBlur = useCallback(() => {
        if (!isVerified && inputValue !== '') {
            setLabelError(true);
            setLabel(T.statusLabelIncorrect);
        }
    }, [inputValue, isVerified]);

    const getImageDataUrl = useCallback(() => {
        const canvas = canvasRef.current.getCanvas();
        drawText(
            canvas,
            verticalSpacing,
            headerText,
            footerText,
            `SMS ${code}`,
        );
        return dataUrlFromCanvas(canvas);
    }, [canvasRef, headerText, footerText, code]);

    useEffect(() => {
        if (!isVerified) {
            setValue(name, '');
            return;
        }

        setLabelError(false);
        setLabel(T.statusLabelCorrect);

        getImageDataUrl().then((dataURL) => {
            setValue(name, dataURL);

            trigger(name);
        });
    }, [isVerified, setValue, trigger, name, getImageDataUrl]);

    useEffect(reset, [headerText, reset, mobilePhoneNumber]);

    return (
        <FormGroup className="col-12">
            <div className="mb-2">
                <T.Label />
            </div>
            <div
                className={classNames('signature-verification-sms', {
                    'border-danger': errors[name],
                })}
            >
                <div className="signature-verification-sms-header">
                    {headerText}
                </div>
                <div className="signature-verification-sms-container">
                    {showTextInput && (
                        <input
                            autoFocus
                            type="text"
                            pattern="[0-9\-]*"
                            value={inputValue}
                            onChange={onTextInputChange}
                            onBlur={onInputBlur}
                            readOnly={!hasMobilePhoneNumber || !hasCode}
                            placeholder="000-000"
                            maxLength="7"
                            autoComplete="chrome-off"
                            className={classNames(
                                'form-control',
                                'form-control-lg',
                                { 'is-invalid': isLabelError },
                                { 'is-valid': isVerified },
                            )}
                            aria-label="Verification code"
                        />
                    )}
                    {showSendButton && (
                        <Button
                            type="button"
                            disabled={!hasMobilePhoneNumber || !online}
                            className="btn-lg btn-primary"
                            onClick={onSendClick}
                            spinner={isSending}
                        >
                            <T.Send />
                        </Button>
                    )}
                    <small
                        className={classNames('mt-2', {
                            'text-danger': isLabelError,
                        })}
                    >
                        {smsLabel}
                    </small>
                </div>
                <div className="signature-verification-sms-footer">
                    {footerText}
                </div>
            </div>

            <RetryTimeoutButton
                className="btn-secondary"
                type="button"
                disabled={!hasCode}
                onClick={reset}
                enableDelay={15}
            >
                <T.Retry />
            </RetryTimeoutButton>

            <InvalidFeedback error={errors[name]} />

            <div className="d-none">
                <ReactCanvas
                    canvasProps={{
                        width: signatureWidth,
                        height: signatureHeight,
                    }}
                    ref={canvasRef}
                    clearOnResize={false}
                />
            </div>
        </FormGroup>
    );
}

SignatureVerificationSms.propTypes = {
    name: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
};

export default SignatureVerificationSms;
