/* eslint-disable func-names */

import * as Yup from 'yup';
import XRegExp from 'xregexp';
import { parseFormattedDate } from '@formatter/dateFormatter';
import { phoneNumberIsValid } from '@formatter/phoneNumberFormatter';
import { dataUrlToByteString } from '@transformer/dataUrlToBlob';
import countryCodes from '@constants/countryCodes';
import validateIban from '@integrations/service/validateIban';
import { yupMethods, yupLocale } from './yupMethods.trans';
import validateValueInGivenRange from './validateValueInGivenRange';
import { captureError } from '../utilities/errorCapturingUtilities';

/**
 * @param {object} testContext
 * @returns {module:AppConfig.AppConfig}
 */
function resolveAppConfigFromTestContext(testContext) {
    const appConfig = testContext?.options?.context?.appConfig;
    if (!appConfig) {
        throw new Error('Expecting app config to be available in test context');
    }

    return appConfig;
}

Yup.setLocale(yupLocale);

Yup.addMethod(Yup.string, 'minAge', function (age) {
    return this.test('minAge', yupMethods.minAge({ minAge: age }), (value) => {
        if (!value) {
            return false;
        }

        const date = parseFormattedDate(value);
        if (!(date instanceof Date)) {
            return false;
        }

        const maxDate = new Date();
        maxDate.setHours(0);
        maxDate.setMinutes(0);
        maxDate.setFullYear(maxDate.getFullYear() - age);

        return date.getTime() < maxDate.getTime();
    });
});

Yup.addMethod(Yup.string, 'maxAge', function (age) {
    return this.test('maxAge', yupMethods.maxAge({ maxAge: age }), (value) => {
        if (!value) {
            return false;
        }

        const date = parseFormattedDate(value);
        if (!(date instanceof Date)) {
            return false;
        }

        const maxDate = new Date();
        maxDate.setHours(0);
        maxDate.setMinutes(0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear() - age);

        return date.getTime() >= maxDate.getTime();
    });
});

Yup.addMethod(Yup.string, 'name', function (message) {
    return this.matches(XRegExp("^[\\pL '-]+$"), {
        message,
        excludeEmptyString: true,
    });
});

Yup.addMethod(Yup.string, 'phoneNumber', function () {
    return this.test(
        'phoneNumber',
        yupMethods.phoneNumber,
        (value, testContext) => {
            const appConfig = resolveAppConfigFromTestContext(testContext);

            return (
                value === undefined ||
                value === '' ||
                phoneNumberIsValid(value, appConfig?.defaultCountryCode || null)
            );
        },
    );
});

Yup.addMethod(
    Yup.string,
    'validateAlternativeAmountInRange',
    function (minAmountAlternative, maxAmountAlternative) {
        const message = yupMethods.currencyInRange({
            minAmountAlternative,
            maxAmountAlternative,
        });
        return this.test(
            'validateAlternativeAmountInRange',
            message,
            (value) => {
                const valueAsFloat = parseFloat(value);
                return validateValueInGivenRange(
                    valueAsFloat,
                    minAmountAlternative,
                    maxAmountAlternative,
                );
            },
        );
    },
);

Yup.addMethod(Yup.string, 'iban', function () {
    return this.test('iban', yupMethods.iban, async (value) =>
        validateIban(value),
    );
});

Yup.addMethod(Yup.string, 'countryCode', function () {
    return this.uppercase().oneOf(countryCodes, yupMethods.invalidCountryCode);
});

Yup.addMethod(Yup.boolean, 'true', function () {
    return this.oneOf([true], yupLocale.mixed.required);
});

Yup.addMethod(Yup.string, 'matchesDateFormat', function () {
    return this.matches(/^([0-9/]{10})$/, {
        message: yupMethods.matchesDateFormat,
        excludeEmptyString: true,
    });
});

Yup.addMethod(Yup.string, 'dataUrl', function () {
    return this.test('dataUrl', yupMethods.invalidDataUrl, (value) => {
        if (!value) {
            return false;
        }

        try {
            dataUrlToByteString(value);

            return true;
        } catch (error) {
            captureError(error, {
                level: 'warning',
                extra: { value },
            });

            return false;
        }
    });
});
