import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {React.ReactNode=} props.children
 * @param {string=} props.className
 * @returns {React.ReactElement|null}
 */
function Alert({ children, className }) {
    return children ? (
        <p className={classNames('alert alert-danger', className)} role="alert">
            {children}
        </p>
    ) : null;
}

Alert.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Alert;
