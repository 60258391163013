/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { NotificationState } from '../notificationSlice';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const setUpdateAvailableReducer: CaseReducer<
    NotificationState,
    PayloadAction<NotificationState['updateAvailable']>
> = (state, action) => {
    captureBreadcrumb('Update available', 'info', 'update', {
        updateAvailable: action.payload,
    });

    state.updateAvailable = action.payload;
};
