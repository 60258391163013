import { captureError } from '@utilities/errorCapturingUtilities';

function captureDecodingError(error: Error | unknown, dataUrl: string): void {
    const sampleLength = 250;

    captureError(error, {
        level: 'warning',
        contexts: {
            dataUrl: {
                length: dataUrl?.length,
                start: dataUrl?.slice(0, sampleLength),
                end: dataUrl?.slice(-sampleLength),
            },
        },
    });
}

export function dataUrlToByteString(dataUrl: string): string {
    try {
        return window.atob(dataUrl.split(',')[1]);
    } catch (error) {
        captureDecodingError(error, dataUrl);
        throw error;
    }
}

function dataUrlToMimeString(dataUrl: string): string {
    return dataUrl.split(',')[0].split(':')[1].split(';')[0];
}

export default function dataUrlToBlob(dataUrl: string): Blob {
    const byteString = dataUrlToByteString(dataUrl);
    const mimeString = dataUrlToMimeString(dataUrl);

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
}
