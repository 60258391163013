/* eslint-disable */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IdealQrState } from '../types';

export const setAmountIfAllowedReducer: CaseReducer<
    IdealQrState,
    PayloadAction<string>
> = (state, action) => {
    const { payload: mandateAmount } = action;

    // Qr Codes are generated for a specified amount, but it is possible for a recruiter to change                                                           │
    // the donation amount after generation. To make sure that the amount displayed alongside the                                                            │
    // Qr Code does not change, we lock it once the iDeal QR code is generated.
    if (state.codeId && state.amount) {
        return;
    }

    state.amount = mandateAmount ? parseFloat(mandateAmount) : null;
};
