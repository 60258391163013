import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@primitives/button/Button';

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {boolean=} props.isSubmitting
 * @param {React.ReactNode} props.children
 * @param {boolean=} props.disabled
 * @param {string=} props.'aria-label'
 * @param {Function=} props.onClick
 * @param {string=} props.type
 * @returns {React.ReactElement}
 */
function SubmitButton({
    className,
    isSubmitting = false,
    children,
    disabled = false,
    'aria-label': ariaLabel,
    onClick,
    type = 'submit',
}) {
    return (
        <Button
            name="wizard-form-submit"
            type={type}
            className={classNames('btn-primary', className)}
            spinner={isSubmitting}
            disabled={disabled}
            aria-label={ariaLabel}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}

SubmitButton.propTypes = {
    className: PropTypes.string,
    isSubmitting: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    'aria-label': PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

export default SubmitButton;
