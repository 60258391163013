import { defineMessage } from '@lingui/macro';

export default {
    suggestion: defineMessage({
        id: 'wizard-form.personal.surname.suggestion',
        comment:
            'Suggestion displayed when a surname is typed that might include a surname prefix',
        message: 'Do you want to apply {prefix} as a surname prefix?',
    }),
    surnameFieldLabel: defineMessage({
        id: 'wizard.form.personal.lastName',
        comment: 'label for the last name input field',
        message: 'Surname',
    }),
    applySuggestionButtonLabel: defineMessage({
        id: 'wizard-form.personal.surname.apply-suggestion',
        comment:
            'Label of button to apply the suggestion to split surname and surname prefix',
        message: 'apply',
    }),
};
