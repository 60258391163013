export type Storage<CacheValue> = Map<
    string,
    {
        expiresAt: number;
        value: CacheValue;
    }
>;

export type CacheAccess<CacheValue> = {
    get: (key: string) => CacheValue | null;
    persist: (key: string, value: CacheValue) => void;
};

export function createInMemoryCache<CacheValue>(
    storage: Storage<CacheValue>,
    lifetimeInMinutes: number,
): CacheAccess<CacheValue> {
    function clearExpiredEntries() {
        const now = new Date().getTime();

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, entry] of storage.entries()) {
            if (entry.expiresAt <= now) {
                storage.delete(key);
            }
        }
    }

    return {
        get: (key: string) => {
            clearExpiredEntries();

            const entry = storage.get(key);
            if (!entry) {
                return null;
            }

            return entry.value;
        },

        persist: (key: string, value: CacheValue) => {
            const expiresAt =
                new Date().getTime() + lifetimeInMinutes * 1000 * 60;

            storage.set(key, { expiresAt, value });
        },
    };
}
