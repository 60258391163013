import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { detect, fromNavigator, fromUrl } from '@lingui/detect-locale';
import { messages as messagesEN } from './locales/en/messages';
import { messages as messagesNL } from './locales/nl/messages';
import { messages as messagesFR } from './locales/fr/messages';
import { messages as messagesDE } from './locales/de/messages';

const defaultLocale = 'nl';
const supportedLocales = ['en', 'nl', 'fr', 'de'];

function loadLocales() {
    i18n.load('en', messagesEN);
    i18n.load('nl', messagesNL);
    i18n.load('fr', messagesFR);
    i18n.load('de', messagesDE);
}

function activate() {
    const detected = detect(
        fromUrl('lang', window.location),
        fromNavigator(navigator),
    );
    const detectedLocale = detected?.substring(0, 2);
    if (supportedLocales.indexOf(detectedLocale) >= 0) {
        i18n.activate(detectedLocale);
    } else {
        i18n.activate(defaultLocale);
    }
}

loadLocales();
activate();

export default ({ children }) => I18nProvider({ children, i18n });
