export function configure(id, version) {
    global.gtag?.('config', id, {
        anonymize_ip: true,
        app_version: version,
    });
}

export function logEvent(name) {
    global.gtag?.('event', name);
}

export function setUserId(userId) {
    global.gtag?.('set', {
        user_id: userId,
    });
}
