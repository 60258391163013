import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { postIdealQrCharge } from '@integrations/service/api';
import type { RootState } from '../../../index';
import version from '../../../../version.json';
import { validateAmountIsAvailable } from '../utilities/idealQrStateValidators';
import { SliceNamespace } from '../../../constants/namespace';
import { WizardFormState } from '../../../../features/wizard/components/wizardForm/types/wizardFormState';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

type CreateIdealQrChargeArgs = {
    campaignId: string;
    formData: WizardFormState;
};

export const createIdealQrCharge: AsyncThunk<
    Awaited<ReturnType<typeof postIdealQrCharge>>,
    CreateIdealQrChargeArgs,
    {
        state: RootState;
    }
> = createAsyncThunk<
    Awaited<ReturnType<typeof postIdealQrCharge>>,
    CreateIdealQrChargeArgs,
    {
        state: RootState;
    }
>(
    `${SliceNamespace.IdealQr}/create-charge`,
    async ({ campaignId, formData }, { getState }) => {
        const currentState = getState();

        validateAmountIsAvailable(currentState[SliceNamespace.IdealQr]);

        const recruiterUserIdentityPlatformUid =
            currentState.user?.details?.id || null;

        const amount = currentState?.[SliceNamespace.IdealQr]?.amount;
        if (!amount) {
            throw new Error('Expecting amount to be available at this point');
        }

        const body = {
            recruiterUserIdentityPlatformUid,
            fieldAppVersion: version,
            ...formData,
        };

        captureBreadcrumb('Ideal QR charge created', 'info', 'idealqr', body);

        return postIdealQrCharge(amount, campaignId, body);
    },
);
