import { getAuthorizationHeader } from '@integrations/auth/identityPlatformApi';
import { getLocalizedErrorMessages } from '@integrations/errorMessages.trans';
import createErrorMessage from '@integrations/createErrorMessage';
import { executeRequest, RequestOptions } from '@integrations/client';
import {
    captureError,
    captureMessage,
} from '@utilities/errorCapturingUtilities';

async function fetchWrapper(
    url: string,
    options: RequestOptions,
    localizedMessages: ReturnType<typeof getLocalizedErrorMessages>,
): Promise<ReturnType<typeof executeRequest>> {
    try {
        return await executeRequest(url, options);
    } catch (error) {
        captureError(error, {
            level: 'warning',
            extra: {
                url,
                requestOptions: options,
            },
        });
        throw new Error(localizedMessages.cantReachServer);
    }
}

export default async function fetchAPIRequest<ResponseBody>(
    url: string,
    options: RequestOptions,
): Promise<ResponseBody> {
    const localizedMessages = getLocalizedErrorMessages();

    const wrappedOptions = {
        ...options,
        headers: {
            ...options?.headers,
            ...(await getAuthorizationHeader()),
        },
    };

    const response = await fetchWrapper(url, wrappedOptions, localizedMessages);

    if (!response.ok) {
        if (response.status !== 401) {
            captureMessage(
                `Received unexpected ${response.status} response from service '${url}'`,
                {
                    level: 'warning',
                    extra: {
                        url,
                        requestOptions: options,
                        responseBody: response.body,
                        responseStatus: response.status,
                    },
                },
            );
        }

        const errorMessage = await createErrorMessage(
            response,
            localizedMessages,
        );
        throw new Error(errorMessage);
    }

    try {
        return (await response.json()) as ResponseBody;
    } catch (error) {
        captureError(error, {
            level: 'warning',
            extra: { url, options },
        });

        throw new Error(localizedMessages.cantParseResponse);
    }
}
