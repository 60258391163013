import { postEntry, postSignature } from '@integrations/service/api';
import dataUrlToBlob from '@transformer/dataUrlToBlob';
import type { Entry } from '@store/slice/buffer/types';
import { captureBreadcrumb } from '@utilities/errorCapturingUtilities';
import { PostEntriesRequestBody } from '../../../../shared/src/contracts/request/postEntries';

async function safePostSignature(
    entry: Entry,
): Promise<ReturnType<typeof postSignature>> {
    if (!entry.signaturePayload) {
        throw new Error('No signature payload found in entry');
    }

    const file = dataUrlToBlob(entry.signaturePayload);
    if (file.size === 0) {
        throw new Error('Signature payload is empty');
    }

    return postSignature({
        file,
        slug: entry.campaign.slug,
        date: entry.data.startDate,
        fieldAppFormUuid: entry.data.formUuid,
    });
}

export default async function postEntryWithSignature(
    entry: Entry,
): Promise<ReturnType<typeof postEntry>> {
    let signature: string | null;
    if (entry.signaturePayload) {
        signature = await safePostSignature(entry);
    } else {
        signature = null;

        captureBreadcrumb(
            'No signature payload found before persist',
            'warning',
            'submit',
            {
                entry: entry.data,
            },
        );
    }

    const payload = {
        campaign: {
            slug: entry.campaign.slug,
            id: entry.campaign.id,
        },
        entry: {
            ...entry.data,
            signature,
        } as PostEntriesRequestBody['entry'],
    };

    captureBreadcrumb('Push entry', 'info', 'submit', {
        entry: payload.entry,
    });

    return postEntry(payload);
}
