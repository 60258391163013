import React from 'react';
import { Trans } from '@lingui/macro';

export const getAmountAfterTaxDeductionLabels = (
    formattedAmountAfterTaxDeduction: string,
    formattedMinTotalAmountThreshold: string,
) => ({
    description: (
        <Trans
            id="wizard.form.payment.amountAfterTaxDeduction.description"
            comment="description of the amount after tax deduction"
        >
            If you donate at least {formattedMinTotalAmountThreshold} a year,
            your gift only costs {formattedAmountAfterTaxDeduction}, due to tax
            deduction.
        </Trans>
    ),
});
