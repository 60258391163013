import React, { useMemo } from 'react';
import classNames from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { RadioInput } from '@primitives/form/Form';
import FormRow from '@primitives/form/components/FormRow';
import useAppConfig from '@hooks/useAppConfig';
import useIsOnline from '@hooks/useIsOnline';
import { resolveEnabledSignatureTypes } from '@resolver/appConfigValueResolver';
import SignatureVerificationSms from './components/signatureVerificationSms/SignatureVerificationSms';
import SignatureCanvas from './components/signatureCanvas/SignatureCanvas';
import SignatureRecorder from './components/signatureRecorder/SignatureRecorder';
import trans from './SignatureFormRow.trans';
import useResetSignatureFieldWhenSignatureTypeChanges from './hooks/useResetSignatureFieldWhenSignatureTypeChanges';
import { formPropTypes } from '../../../../../../propTypes';

const typeName = 'signatureType';
const payloadName = 'signaturePayload';

const signatureTypes = {
    audio: SignatureRecorder,
    canvas: SignatureCanvas,
    sms: SignatureVerificationSms,
};

function useSignatureTypeOptions() {
    const appConfig = useAppConfig();
    const enabledSignatureTypes = resolveEnabledSignatureTypes(appConfig);

    return useMemo(
        () => pick(trans.options(), enabledSignatureTypes),
        [enabledSignatureTypes],
    );
}

/**
 * @param {object} props
 * @param {boolean=} props.showOptions
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function SignatureFormRow({ showOptions = false, form }) {
    const { watch, register } = form;

    const type = watch(typeName);
    const SignatureComponent = signatureTypes[type];

    const online = useIsOnline();
    const options = useSignatureTypeOptions();
    const disabledOptions = online ? [] : ['sms'];

    useResetSignatureFieldWhenSignatureTypeChanges(type, form, payloadName);

    return (
        <FormRow>
            <RadioInput
                disabledValues={disabledOptions}
                className={classNames('col-12', { 'd-none': !showOptions })}
                name={typeName}
                label={trans.signaturePreference()}
                options={options}
                form={form}
            />
            <SignatureComponent name={payloadName} form={form} />
            <input
                aria-label={payloadName}
                name={payloadName}
                ref={register}
                className="d-none"
            />
        </FormRow>
    );
}

SignatureFormRow.propTypes = {
    form: formPropTypes.isRequired,
    showOptions: PropTypes.bool,
};

export default SignatureFormRow;
