import React from 'react';
import PropTypes from 'prop-types';
import useAppConfig from '@hooks/useAppConfig';
import useActiveCampaign from '@hooks/useActiveCampaign';
import formatTemplateData from '../../../formatter/formatTemplateData';
import useTextTemplate from '../../../hooks/useTextTemplate';
import templateKeys from '../../../constants/templateKeys';

function SignatureRecorderText({ watch }) {
    const appConfig = useAppConfig();
    const campaign = useActiveCampaign(true);

    const formData = formatTemplateData(watch(), campaign, appConfig);
    const audioTemplate = useTextTemplate(
        appConfig,
        templateKeys.audio,
        formData,
    );
    return <div className="signature-recorder-text">{audioTemplate}</div>;
}

SignatureRecorderText.propTypes = {
    watch: PropTypes.func.isRequired,
};

export default SignatureRecorderText;
