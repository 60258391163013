export default async function blobToDataUrl(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.addEventListener('load', () => {
            resolve(fileReader.result);
        });
        fileReader.addEventListener('error', () => {
            reject(fileReader.error);
        });

        fileReader.readAsDataURL(blob);
    });
}
