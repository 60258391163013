import Interval from '@constants/interval';

export function calculateTotalAmountPerYear(
    amount: number,
    interval: Interval,
): number {
    switch (interval) {
        case Interval.monthly:
            return amount * 12;
        case Interval.oneTime:
        case Interval.yearly:
            return amount;
        case Interval.halfYearly:
            return amount * 2;
        case Interval.quarterly:
            return amount * 4;
        default:
            throw new Error(`Unsupported interval: ${interval}`);
    }
}
