import useAmountPresets, {
    defaultAmountPresetKey,
} from '@hooks/useAmountPresets';
import { useAppSelector } from '@hooks/useAppSelector';

export default function usePreferredAmountPreset() {
    const amountPresets = useAmountPresets();
    const preferredKey = useAppSelector(
        (state) => state.user.preferences?.amountPreset,
    );

    return (
        amountPresets[preferredKey] ||
        amountPresets[defaultAmountPresetKey] ||
        Object.values(amountPresets)[0]
    );
}
