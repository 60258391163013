import { defineMessage } from '@lingui/macro';

export default {
    headerTitle: defineMessage({
        id: 'wizard.form.personal.headerTitle',
        comment: 'Title of the personal data section of the form',
        message: 'Personal data',
    }),
    academicTitle: defineMessage({
        id: 'wizard.form.personal.academicTitleHeader',
        comment: 'Academic title header',
        message: 'Title',
    }),
    firstName: defineMessage({
        id: 'wizard.form.personal.firstName',
        comment: 'label for the first name input field',
        message: 'First name',
    }),
    initials: defineMessage({
        id: 'wizard.form.personal.initials',
        comment: 'label for the initials input field',
        message: 'Initials',
    }),
    surnamePrefix: defineMessage({
        id: 'wizard.form.personal.surnamePrefix',
        comment: 'label for the surname prefix input field',
        message: 'Prefix',
    }),
    dateOfBirth: defineMessage({
        id: 'wizard.form.personal.dateOfBirth',
        comment: 'label for the date of birth input field',
        message: 'Date of birth',
    }),
    ssnInputLabel: defineMessage({
        id: 'wizard.form.personal.ssnTitle',
        comment: 'label for the SSN Number input field',
        message: 'Social Security Number',
    }),
    sex: defineMessage({
        id: 'wizard.form.personal.sex',
        comment: 'label for the sex input field',
        message: 'Gender identity',
    }),
    mobilePhoneNumber: defineMessage({
        id: 'wizard.form.personal.mobilePhoneNumber',
        comment: 'label for the mobile phone number input field',
        message: 'Mobile phone number',
    }),
    phoneNumber: defineMessage({
        id: 'wizard.form.personal.phoneNumber',
        comment: 'label for the phone number input field',
        message: 'Phone number',
    }),
    languagePreference: defineMessage({
        id: 'wizard.form.personal.languagePreference',
        comment: 'label for the language preference radio button group',
        message: 'Language preference',
    }),
    emailLabelText: defineMessage({
        id: 'wizard.form.personal.emailLabelText',
        comment: 'Label for the email confirmation checkbox',
        message: 'Yes, I would like to receive email.',
    }),
    callsLabelText: defineMessage({
        id: 'wizard.form.personal.callsLabelText',
        comment: 'Label for phone call consent confirmation checkbox',
        message: 'Yes, I consent to receiving phone calls.',
    }),
    smsLabelText: defineMessage({
        id: 'wizard.form.personal.smsLabelText',
        comment: 'Label for SMS consent confirmation checkbox',
        message: 'Yes, I would like to receive SMS messages.',
    }),
};
