import React from 'react';
import { TextInput } from '@primitives/form/Form';
import { formPropTypes } from '../../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function WizardFormUuid({ form }) {
    return (
        // This component is used to ensure the formUuid has a place to be registered,
        // so we can pass it along with the form data. This way we will have the uuid available in
        // the kitchen sink and the submitted forms.
        <TextInput
            name="formUuid"
            className="visually-hidden"
            label="formUuid"
            isDisabled={() => true}
            tabIndex={-1}
            form={form}
        />
    );
}

WizardFormUuid.propTypes = {
    form: formPropTypes.isRequired,
};

export default WizardFormUuid;
