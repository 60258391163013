import { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { updatePreferences } from '@store/slice/user/userSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { FormValues } from './useUserPreferencesFormState';

export default function useUpdateAmountPresetValueOnFormChange(
    watch: UseFormMethods<FormValues>['watch'],
) {
    const dispatch = useAppDispatch();

    const amountPresetValue = watch('amountPreset');

    useEffect(() => {
        dispatch(
            updatePreferences({
                amountPreset: amountPresetValue,
            }),
        );
    }, [amountPresetValue, dispatch]);
}
