import { defineMessage } from '@lingui/macro';
import Interval from '@constants/interval';

export const intervalLabels = {
    [Interval.oneTime]: defineMessage({
        id: 'wizard.form.period.options.single',
        comment: 'One-time option for the period dropdown input',
        message: 'One-time',
    }),
    [Interval.monthly]: defineMessage({
        id: 'wizard.form.period.options.monthly',
        comment: 'Monthly option for the period dropdown input',
        message: 'Monthly',
    }),
    [Interval.yearly]: defineMessage({
        id: 'wizard.form.period.options.yearly',
        comment: 'Yearly option for the period dropdown input',
        message: 'Yearly',
    }),
    [Interval.halfYearly]: defineMessage({
        id: 'wizard.form.period.options.halfyearly',
        comment: 'Half-yearly option for the period dropdown input',
        message: 'Half-yearly',
    }),
    [Interval.quarterly]: defineMessage({
        id: 'wizard.form.period.options.quarterly',
        comment: 'Quarterly option for the period dropdown input',
        message: 'Quarterly',
    }),
};
