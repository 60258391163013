import Interval from '@constants/interval';

const intervalMultiplierMap = {
    [Interval.monthly]: 1,
    [Interval.oneTime]: 1,
    [Interval.yearly]: 12,
    [Interval.halfYearly]: 6,
    [Interval.quarterly]: 3,
};

export default function multiplyAmountPresetByInterval(amountPreset, interval) {
    const multiplier = intervalMultiplierMap[interval] || 1;
    return amountPreset.map((value) => value * multiplier);
}
