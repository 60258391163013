import React from 'react';
import { Trans } from '@lingui/macro';

export const getAmountRadioInputLabels = () => ({
    otherOptions: (
        <Trans
            id="wizard.form.radioInput.otherOption"
            comment="text for the option for the Other payment option"
        >
            Other
        </Trans>
    ),
    amount: (
        <Trans
            id="wizard.form.radioInput.label.amount"
            comment="text for the amount label"
        >
            Amount
        </Trans>
    ),
});
