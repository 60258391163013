import type { StringModifier } from '@resolver/formatterResolver';
import { contextLogger } from './logger';

export const ensureFirstLetterIsUppercase: StringModifier = (value) => {
    // noinspection SuspiciousTypeOfGuard
    if (typeof value !== 'string') {
        contextLogger.warn('non string value provided: ', value);

        return value;
    }

    if (value.length === 0) {
        return value;
    }

    return value.substring(0, 1).toUpperCase() + value.substring(1);
};
