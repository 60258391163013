import React, { FC, ReactNode, useEffect } from 'react';
import './EntriesOverview.scss';
import Alert from '@primitives/alert/Alert';
import { cleanUp } from '@store/slice/buffer/bufferSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useBufferState from '@hooks/useBufferState';
import { getEntriesOverviewCardLabels } from './EntriesOverview.trans';
import EntryTableRow from './components/entryTableRow/EntryTableRow';

type Props = {
    children?: ReactNode;
};

const EntriesOverview: FC<Props> = ({ children }) => {
    const dispatch = useAppDispatch();

    const { entries, result } = useBufferState();

    const timestamps = Object.keys(entries);

    const labels = getEntriesOverviewCardLabels();

    useEffect(() => {
        dispatch(cleanUp());
    }, [dispatch]);

    return (
        <>
            {result?.errorMessage && (
                <div>
                    <Alert className="tw-mb-0">{result?.errorMessage}</Alert>
                </div>
            )}

            {children}

            {timestamps.length === 0 && (
                <div>
                    <p className="tw-mb-0 tw-text-gray-700 tw-no-form tw-text-sm tw-font-normal tw-text-[#475467]">
                        {labels.noForms}
                    </p>
                </div>
            )}

            {timestamps.length > 0 && (
                <table id="entries" className="tw-w-full tw-mb-2 md:tw-mb-4">
                    <thead className="tw-text-sm tw-text-dark-gray xsmax:tw-overflow-hidden xsmax:tw-absolute xsmax:tw-w-px xsmax:tw-p-0 xsmax:tw-border-[none]">
                        <tr>
                            <th className="tw-font-semibold tw-py-4">
                                {labels.date}
                            </th>
                            <th className="tw-font-semibold">
                                {labels.campaign}
                            </th>
                            <th className="tw-font-semibold">
                                {labels.recruiter}
                            </th>
                            <th className="tw-font-semibold">{labels.value}</th>
                            <th className="tw-font-semibold">
                                {labels.attribute}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="sm:tw-text-sm tw-align-middle tw-font-normal">
                        {timestamps.reverse().map((timestamp) => (
                            <EntryTableRow
                                key={timestamp}
                                timestamp={timestamp}
                                entry={entries[timestamp]}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default EntriesOverview;
