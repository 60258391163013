/* eslint-disable jsdoc/valid-types */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const customValue = '';

function getOptions(array) {
    return [
        ...array.map((entry) => (
            <option value={entry} key={entry}>
                {entry}
            </option>
        )),
        <option key={customValue} value={customValue}>
            &hellip;
        </option>,
    ];
}

/**
 * @param {object} props
 * @param {string=} props.name
 * @param {string=} props.id
 * @param {string=} props.value
 * @param {string[]} props.options
 * @param {string=} props.className
 * @param {Function} props.onChange
 * @param {boolean=} props.readonly
 * @param {string=} props.'aria-label'
 * @returns {React.ReactElement}
 */
function SelectOrTextInput({
    name,
    id = name,
    value,
    options,
    className,
    onChange,
    readonly = false,
    'aria-label': ariaLabel,
}) {
    return options.indexOf(value) >= 0 ? (
        <select
            className={classNames('form-select', className)}
            id={id}
            name={name}
            value={value || ''}
            onChange={onChange}
            disabled={readonly}
            aria-label={ariaLabel}
        >
            {getOptions(options)}
        </select>
    ) : (
        <input
            autoFocus
            autoComplete="chrome-off"
            className={classNames('form-control', className)}
            type="text"
            name={name}
            id={id}
            value={value}
            onFocus={(event) => event.target.select()}
            onChange={onChange}
            readOnly={readonly}
            aria-label={ariaLabel}
        />
    );
}

SelectOrTextInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    'aria-label': PropTypes.string,
};

export default SelectOrTextInput;
