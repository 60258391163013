import { defineMessage } from '@lingui/macro';

export default {
    headerTitle: defineMessage({
        id: 'wizard.form.address.headerTitle',
        comment: 'Title of the address data section of the form',
        message: 'Address information',
    }),
    country: defineMessage({
        id: 'wizard.form.address.country',
        comment: 'label for the country input field',
        message: 'Country',
    }),
    zipCode: defineMessage({
        id: 'wizard.form.address.zipCode',
        comment: 'label for the zipcode input field',
        message: 'Zip code',
    }),
    houseNumber: defineMessage({
        id: 'wizard.form.address.houseNumber',
        comment: 'label for the house number field',
        message: 'House number',
    }),
    houseNumberExtension: defineMessage({
        id: 'wizard.form.address.houseNumberExtension',
        comment: 'label for the house number extension field',
        message: 'Extension',
    }),
    street: defineMessage({
        id: 'wizard.form.address.street',
        comment: 'label for the street field',
        message: 'Street',
    }),
    city: defineMessage({
        id: 'wizard.form.address.city',
        comment: 'label for the city field',
        message: 'City',
    }),
    mailLabelText: defineMessage({
        id: 'wizard.form.address.mailLabelText',
        comment: 'Label for the mail confirmation checkbox',
        message: 'Yes, I would like to receive mail.',
    }),
};
