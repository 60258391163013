import React from 'react';
import PropTypes from 'prop-types';
import { getFilePath } from '@integrations/service/api';
import useAppConfig from '@hooks/useAppConfig';
import useActiveCampaign from '@hooks/useActiveCampaign';

/**
 * @param {object} props
 * @param {string=} props.name
 * @param {string=} props.title
 * @returns {React.ReactElement}
 */
function Image({ name, title }) {
    const appConfig = useAppConfig();
    const campaign = useActiveCampaign(true);

    return (
        <div className="m-4">
            <img
                src={getFilePath(campaign.id, appConfig[name])}
                className="w-100"
                alt={name}
                title={title}
            />
        </div>
    );
}

Image.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
};

export default Image;
