import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactCanvas from 'react-signature-canvas';
import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { FormGroup } from '@primitives/form/FormGroup';
import Button from '@primitives/button/Button';
import useAppConfig from '@hooks/useAppConfig';
import useActiveCampaign from '@hooks/useActiveCampaign';
import drawText from '../../handler/drawText';
import './SignatureCanvas.scss';
import useTextTemplate from '../../hooks/useTextTemplate';
import formatTemplateData from '../../formatter/formatTemplateData';
import { getSignatureCanvasLabels } from './SignatureCanvas.trans';
import templateKeys from '../../constants/templateKeys';
import dataUrlFromCanvas from '../../factory/dataUrlFromCanvas';
import { formPropTypes } from '../../../../../../../../propTypes';

const signatureInnerHeight = 200;

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string} props.name
 * @returns {React.ReactElement}
 */
function SignatureCanvas({ form, name }) {
    const {
        watch,
        setValue,
        formState: { errors },
    } = form;

    const campaign = useActiveCampaign(true);
    const appConfig = useAppConfig();

    const ref = useRef();
    const [empty, setEmpty] = useState(true);
    const [width, setWidth] = useState(0);
    const labels = getSignatureCanvasLabels();

    const formData = formatTemplateData(watch(), campaign, appConfig);
    const footerText = useTextTemplate(
        appConfig,
        templateKeys.footer,
        formData,
    );
    const headerText = useTextTemplate(
        appConfig,
        templateKeys.header,
        formData,
    );

    const handleResize = useCallback(() => {
        debounce(() => setWidth(ref.current?.getCanvas().offsetWidth), 500)();
    }, [setWidth]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const onUpdateCanvas = useCallback(() => {
        setEmpty(ref.current.isEmpty());
        const canvas = ref.current.getCanvas();
        dataUrlFromCanvas(canvas).then((payload) => {
            setValue(name, payload, { shouldValidate: true });
        });
    }, [setValue, name]);

    const onResetCanvas = useCallback(() => {
        const canvas = ref.current.getCanvas();
        canvas.width = canvas.offsetWidth;
        drawText(canvas, signatureInnerHeight, headerText, footerText);
        setEmpty(true);
        setValue(name, '');
    }, [headerText, footerText, setValue, name]);

    useEffect(onResetCanvas, [onResetCanvas, width]);

    return (
        <FormGroup className="signature-canvas col-12">
            <label className="form-label" htmlFor={name}>
                {labels.label}
            </label>
            <div
                id={name}
                className={classNames('form-control', {
                    'is-invalid': errors[name],
                })}
            >
                <ReactCanvas
                    canvasProps={{ 'data-testid': 'signature-canvas' }}
                    ref={ref}
                    clearOnResize={false}
                    onEnd={onUpdateCanvas}
                />
            </div>
            <InvalidFeedback error={errors[name]} />
            <Button
                type="button"
                onClick={onResetCanvas}
                className="btn-secondary mt-2"
                disabled={empty}
            >
                {labels.retry}
            </Button>
        </FormGroup>
    );
}

SignatureCanvas.propTypes = {
    name: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
};

export default SignatureCanvas;
