import React from 'react';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { FormGroup } from '@primitives/form/FormGroup';
import FormLabel from '@primitives/form/FormLabel';
import PostcodeAutocompleteInput from './components/PostcodeAutocompleteInput';
import trans from './AddressCompletionInput.trans';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
function AddressCompletionInput({ form, className }) {
    const name = 'internationalAddressCompletion';

    const { watch, setValue } = form;

    return (
        <div className="border-bottom mb-3">
            <FormGroup className={classNames(className, 'position-relative')}>
                <FormLabel htmlFor={name}>{trans.label.id}</FormLabel>
                <PostcodeAutocompleteInput
                    name={name}
                    placeholder={t({ id: trans.placeholder.id })}
                    countryCode={watch('countryCode')}
                    onAddressSelect={(address) => {
                        setValue('street', address.street);
                        setValue('houseNumber', address.buildingNumber);
                        setValue(
                            'houseNumberExtension',
                            address.buildingNumberAddition,
                        );
                        setValue('zipCode', address.postcode);
                        setValue('city', address.locality);
                    }}
                />
            </FormGroup>
        </div>
    );
}

AddressCompletionInput.propTypes = {
    form: formPropTypes.isRequired,
    className: PropTypes.string,
};

export default AddressCompletionInput;
