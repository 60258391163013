import { Logger } from '@tapraise/logger';
import { isJestTestEnvironment } from './environmentIdentificationUtilities';

/**
 * Logs context for errors, warnings or specific contextual actions or output, that we can use in Sentry or
 * locally to fix errors. It is disabled by default for the test environment to reduce the output in Jest
 * CLI.
 */
export const contextLogger = new Logger({
    namespace: 'context',
    isEnabled: !isJestTestEnvironment,
});
