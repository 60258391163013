import Interval from '@constants/interval';
import useIdealQrState from '@hooks/useIdealQrState';
import determineMandateAmount from '../../../resolver/mandateAmountResolver';

/**
 * The amount inputs need to be disabled when:
 *  - the iDeal QR code is currently being generated, as otherwise this would influence the iDeal QR amount during the
 *    request where the amount was already specified in.
 *  - the interval is one-time and the iDeal QR code was generated and the amount is determined. As the mandate
 *    confirmation is skipped then, we don't want the amount to be different from the iDeal QR amount.
 *
 * @param {Function} watch
 * @returns {boolean}
 */
export default function useDetermineAmountShouldBeDisabled(watch) {
    const { codeId, isGenerating } = useIdealQrState();

    if (isGenerating) {
        return true;
    }

    const interval = watch('interval');

    const amountToBePaid = parseFloat(
        determineMandateAmount(watch('amount'), watch('amountAlternative')),
    );

    const amountIsValid = !Number.isNaN(amountToBePaid) && amountToBePaid > 0;

    const hasIdealQrId = !!codeId;

    return interval === Interval.oneTime && hasIdealQrId && amountIsValid;
}
