import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import useAppConfig from '@hooks/useAppConfig';
import { resolveDefaultCountryCode } from '@resolver/appConfigValueResolver';

const surnamePrefixes = [
    "'t",
    "'s",
    'a',
    'à',
    'aan de',
    'aan den',
    'aan het',
    'ait el',
    'al',
    'an de',
    'auf dem',
    'auf den',
    'auf der',
    'aus dem',
    'ben',
    'ben el',
    "ben l'",
    "bij 't",
    'bij de',
    "d'",
    'da',
    'dal',
    "dall'",
    'de',
    'de la',
    'del',
    "dell'",
    'della',
    'den',
    'di',
    'do',
    'dos',
    'du',
    'el',
    'ibn',
    'im',
    "in 't",
    'in de',
    'in den',
    'in der',
    'in het',
    "l'",
    'la',
    'le',
    'lo',
    'onder de',
    "op 't",
    'op de',
    'op den',
    'op',
    'op het',
    'op ten',
    'over de',
    'mevrouw van amsberg',
    'prinses van oranje-nassau',
    'amsterdam.',
    't',
    'te',
    'ten',
    'ter',
    'tho',
    'thoe',
    'toe',
    'uijt de',
    'uijt den',
    'uijtten',
    'uit',
    'uit de',
    'uit den',
    "van 't",
    'van',
    'van de / der',
    'van de',
    'van de / den / der',
    'van den',
    'van de / den',
    'van den / der',
    'van der',
    'ver',
    'von',
    'von den',
    'von der',
    "voor 't",
    'voor de',
    'voor den',
    "voor in 't",
    'vor der',
    'zum',
    'zur',
    "aan 't",
    'agter den',
    'an',
    "auf 'm",
    'aus der',
    'bij den',
    "de l'",
    'de le',
    'de los',
    'de van der',
    'der',
    'des',
    'dèr',
    'het',
    'in',
    'op der',
    "s'",
    'then',
    'to',
    "uit 't",
    'uit het',
    'uitten',
    "van 's",
    'van / von',
    'van ter',
    'van het',
    'van la',
    'vom',
    "von 't",
    'von dem',
    'zu',
    'abu',
    'bin',
    'en',
    'at',
    'et',
    'ad',
    'ed',
    'l',
    'saint',
    'sante',
    'lu',
    'les',
    'uijt te de',
    'van van de',
    'de die le',
    'onder den',
    'onder het',
    'uit te de',
    "van de l'",
    'voor in t',
    "boven d'",
    "onder 't",
    'over den',
    'over het',
    'uijt ten',
    'van de l',
    'aan der',
    'auf ter',
    'aus den',
    'bij het',
    'boven d',
    'onder t',
    "over 't",
    "uijt 't",
    'uit ten',
    'van gen',
    "aus 'm",
    'de die',
    'de las',
    'die le',
    'op gen',
    'over t',
    'aan t',
    'am de',
    'aus m',
    'bij t',
    'dalla',
    'degli',
    'onder',
    'uit t',
    'unter',
    'van t',
    'von t',
    "dal'",
    'de l',
    'deca',
    'in t',
    'op t',
    'over',
    'thor',
    'uijt',
    'voor',
    'aan',
    'auf',
    'aus',
    'bij',
    'das',
    'dei',
    'don',
    'las',
    'los',
    'tot',
    'vor',
    'af',
    'am',
    'of',
];

export type SplitSurnameAndPrefix = {
    surname: string;
    surnamePrefix: string;
};

export default function useSplitPrefixFromSurnameIfApplicable(
    form: UseFormMethods<any>,
): SplitSurnameAndPrefix | null {
    const appConfig = useAppConfig();
    const defaultCountryCode = resolveDefaultCountryCode(appConfig);

    const [splitSurnameAndPrefix, setSplitSurnameAndPrefix] =
        useState<SplitSurnameAndPrefix | null>(null);

    const surnameValue = form.watch('surname');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkContainsPrefix = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        debounce((surnameValue: string | null | undefined) => {
            if (defaultCountryCode === 'BE') {
                setSplitSurnameAndPrefix(null);

                return;
            }

            if (typeof surnameValue !== 'string') {
                setSplitSurnameAndPrefix(null);

                return;
            }

            const normalizedSurnameValue = surnameValue.trim().toLowerCase();

            if (normalizedSurnameValue.length === 0) {
                setSplitSurnameAndPrefix(null);

                return;
            }

            const matchingPrefixes = surnamePrefixes.filter((prefix) =>
                // We only want to match prefixes that are followed by a space, as otherwise the match
                // might include part of the surname, i.e. 'van Derksen' would match 'van der'.
                normalizedSurnameValue.startsWith(`${prefix} `),
            );

            if (matchingPrefixes.length === 0) {
                setSplitSurnameAndPrefix(null);

                return;
            }

            // There might be multiple matching prefixes, but we want to apply the longest one, as that is
            // probably the most exact match.
            const longestPrefix = matchingPrefixes.reduce(
                (currentLongestPrefix, currentPrefix) =>
                    currentLongestPrefix.length > currentPrefix.length
                        ? currentLongestPrefix
                        : currentPrefix,
                '',
            );

            const split = surnameValue.split(
                new RegExp(`${longestPrefix} `, 'i'),
            );

            const surnameWithoutPrefix = split[1].trim();
            if (!surnameWithoutPrefix) {
                setSplitSurnameAndPrefix(null);

                return;
            }

            setSplitSurnameAndPrefix({
                surname: surnameWithoutPrefix,
                surnamePrefix: longestPrefix,
            });
        }, 500),
        [surnameValue],
    );

    useEffect(() => {
        checkContainsPrefix(surnameValue);

        return () => checkContainsPrefix.cancel();
    }, [surnameValue, checkContainsPrefix]);

    return splitSurnameAndPrefix;
}
