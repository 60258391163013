import options from '../../../constants/options';

const defaultCountryCode = ['nl'];

export const getLanguageOptionsFromCountryCodes = (countryCodes) =>
    (countryCodes || defaultCountryCode).reduce(
        (accumulator, current) => ({
            ...accumulator,
            [current]: options.language[current] || current.toUpperCase(),
        }),
        {},
    );
