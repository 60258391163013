import { defineMessage } from '@lingui/macro';
import { toTranslationRecord } from '@utilities/translationUtilities';

const translations = {
    headerTitle: defineMessage({
        id: 'wizard.form.payment.headerTitle',
        comment: 'Title of the donation section of the form',
        message: 'Donation',
    }),
    startDate: defineMessage({
        id: 'wizard.form.payment.startDate',
        comment: 'label for the startdate input field',
        message: 'Registration date',
    }),
};

export default toTranslationRecord<keyof typeof translations>(translations);
