import fetchAPIRequest from '@integrations/fetchAPIRequest';
import { defaultTimeoutLengthInMs, RequestOptions } from '@integrations/client';
import { PostEntriesResponseBody } from '../../../../shared/src/contracts/response/postEntries';
import { GetAuthContextResponseBody } from '../../../../shared/src/contracts/response/getAuthContext';
import { Campaign } from '../../../../shared/src/contracts/model/campaign';
import { GetUserResponseBody } from '../../../../shared/src/contracts/response/getUser';
import { PostEntriesRequestBody } from '../../../../shared/src/contracts/request/postEntries';
import { PostSignaturesRequestBody } from '../../../../shared/src/contracts/request/postSignatures';
import { PostSignaturesResponseBody } from '../../../../shared/src/contracts/response/postSignatures';
import { PostSignaturesMessagesRequestBody } from '../../../../shared/src/contracts/request/postSignaturesMessages';
import { PostSignaturesMessagesResponseBody } from '../../../../shared/src/contracts/response/postSignaturesMessages';
import { GetTenantResponseBody } from '../../../../shared/src/contracts/response/getTenant';
import { GetLandingPageConfigResponseBody } from '../../../../shared/src/contracts/response/getLandingPageConfig';
import { ValidatePhoneNumberRequestBody } from '../../../../shared/src/contracts/request/validatePhoneNumber';
import { ValidatePhoneNumberResponseBody } from '../../../../shared/src/contracts/response/validatePhoneNumber';
import { PostIdealQrChargeRequestBody } from '../../../../shared/src/contracts/request/postIdealQrCharge';
import { PostIdealQrChargeResponseBody } from '../../../../shared/src/contracts/response/postIdealQrCharge';
import { GetIdealQrChargeStatusResponseBody } from '../../../../shared/src/contracts/response/getIdealQrChargeStatus';
import { ValidateIbanSuccessResponseBody } from '../../../../shared/src/contracts/response/validateIban.d';

async function post<ResponseBody>(
    path: string,
    payload: Record<string, any>,
    options: RequestOptions = {},
): Promise<ResponseBody> {
    const mergedOptions = {
        ...options,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            ...options.headers,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    return fetchAPIRequest(`/api/${path}`, mergedOptions);
}

async function get<ResponseBody>(
    path: string,
    options: RequestOptions = {},
): Promise<ResponseBody> {
    const mergedOptions: RequestOptions = {
        ...options,
        method: 'GET',
    };

    return fetchAPIRequest(`/api/${path}`, mergedOptions);
}

export async function getValidateIban(
    iban: string,
): Promise<ValidateIbanSuccessResponseBody> {
    return get(`v1/validate/iban/${iban}`, {
        timeoutInMs: defaultTimeoutLengthInMs,
    });
}

export async function getAuthContext(): Promise<GetAuthContextResponseBody> {
    return get('v1/auth/context');
}

export async function getCampaigns(): Promise<Campaign[]> {
    return get('v1/campaigns');
}

export async function getLandingPageConfig(
    campaignId: string,
): Promise<GetLandingPageConfigResponseBody> {
    return get(`v1/campaigns/${campaignId}/landing-page`);
}

export async function getTenant(): Promise<GetTenantResponseBody> {
    return get('v1/tenant');
}

export async function getUser(): Promise<GetUserResponseBody> {
    return get('v1/user');
}

export async function postValidatePhoneNumber(
    phoneNumber: string,
): Promise<ValidatePhoneNumberResponseBody> {
    const payload: ValidatePhoneNumberRequestBody = {
        phoneNumber,
    };

    return post('v1/phone-number/validate', payload, {
        timeoutInMs: defaultTimeoutLengthInMs,
    });
}

export async function postEntry(
    payload: PostEntriesRequestBody,
): Promise<PostEntriesResponseBody> {
    return post<PostEntriesResponseBody>('v1/entries', payload);
}

export async function postSignatureMessage(
    message: PostSignaturesMessagesRequestBody['message'],
): Promise<PostSignaturesMessagesResponseBody> {
    return post('v1/signatures/messages', { message });
}

export async function postSignature({
    file,
    slug,
    date,
    fieldAppFormUuid,
}: Omit<PostSignaturesRequestBody, 'extension'> & {
    file: Blob;
}): Promise<PostSignaturesResponseBody> {
    const fileExtension = file.type.split('/').pop() as string;

    const body: PostSignaturesRequestBody = {
        extension: fileExtension,
        slug,
        date: new Date(date).toISOString(),
        fieldAppFormUuid,
    };

    const formData = new FormData();
    formData.append('file', file);

    Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return fetchAPIRequest('/api/v1/signatures', {
        method: 'POST',
        body: formData,
    });
}

export async function postIdealQrCharge(
    amount: PostIdealQrChargeRequestBody['amount'],
    campaignId: PostIdealQrChargeRequestBody['campaignId'],
    pledgeCaseData: Record<string, any>,
): Promise<PostIdealQrChargeResponseBody> {
    const payload: PostIdealQrChargeRequestBody = {
        amount,
        campaignId,
        pledgeCaseData,
    };

    return post('v1/charge/ideal-qr', payload);
}

export async function getIdealQrChargeStatus(
    campaignId: string,
    id: string,
): Promise<GetIdealQrChargeStatusResponseBody> {
    return get(`v1/charge/ideal-qr/${campaignId}/status/${id}`);
}

export function getFilePath(campaignId: string, name: string): string {
    return name && `/api/v1/campaigns/${campaignId}/static/${name}`;
}
