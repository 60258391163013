import { useMemo } from 'react';
import useAppConfig from '@hooks/useAppConfig';
import { getPreferredPaymentDayLabel } from '../components/preferredPaymentDayRadio/PreferredPaymentDayRadio.trans';

function getPreferredPaymentDaysAsOptionMap(preferredPaymentDays) {
    if (!preferredPaymentDays) {
        return null;
    }

    return preferredPaymentDays.reduce(
        (accumulated, item) => {
            accumulated[item] = item;
            return accumulated;
        },
        {
            null: getPreferredPaymentDayLabel.noPreference(),
        },
    );
}

const useGetOptions = () => {
    const appConfig = useAppConfig();
    const hasPreference =
        appConfig.preferredPaymentDay &&
        Array.isArray(appConfig.preferredPaymentDay);

    if (hasPreference) {
        return appConfig.preferredPaymentDay;
    }

    return null;
};

export default function usePreferredPaymentDayOptions() {
    const preferredPaymentDayOptions = useGetOptions();
    return useMemo(
        () => getPreferredPaymentDaysAsOptionMap(preferredPaymentDayOptions),
        [preferredPaymentDayOptions],
    );
}
