import { t } from '@lingui/macro';

export default {
    signaturePreference: () =>
        t({
            id: 'wizard.form.confirmation.signaturePreference.label',
            comment: 'Label for the signature preference',
            message: 'Confirmation method',
        }),
    options: () => ({
        canvas: t({
            id: 'wizard.form.signature.type.label.regular',
            comment: 'Label for the regular signature radio button option',
            message: 'Regular signature',
        }),
        audio: t({
            id: 'wizard.form.signature.type.label.audio',
            comment: 'Label for the audio signature radio button option',
            message: 'Audio recording (contactless)',
        }),
        sms: t({
            id: 'wizard.form.signature.type.label.code',
            comment: 'Label for the audio signature radio button option',
            message: 'Verification code (SMS)',
        }),
    }),
};
