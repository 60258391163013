import { CampaignMap } from '../types';
import { AppConfig } from '../../../../../../shared/src/contracts/model/appConfig';
import { Campaign } from '../../../../../../shared/src/contracts/model/campaign';

export const resolveDefaultCampaign = (
    appConfig: AppConfig,
    campaigns: CampaignMap,
): Campaign | null => {
    if (!appConfig?.defaultCampaignId) {
        return null;
    }

    return campaigns?.[appConfig.defaultCampaignId] || null;
};
