/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { formPropTypes, layoutChildrenPropType } from '../../propTypes';

/**
 * @param {object} props
 * @param {string} props.component
 * @returns {React.ReactElement}
 */
function MissingComponent({ component }) {
    const message = `Warning: Component "${component}" is not defined in form layout registry.`;

    return <p>{message}</p>;
}

MissingComponent.propTypes = {
    component: PropTypes.string.isRequired,
};

/**
 * This component also allows additional props to supply to its child components, defined
 * in the layout. At minimum, it should supply these:
 *
 * @param {Object<string, any>} props
 * @param {Object<string, React.Component>} props.registry  Layout registry
 * @param {module:Layout.LayoutChild[]} props.children                    Layout children
 * @returns {React.ReactElement|null}
 */
function FormLayout({ registry, children, ...props }) {
    if (!children) return null;

    return children.map((child, index) => {
        const Component = registry[child.component] || MissingComponent;

        return (
            <Component {...props} {...child} registry={registry} key={index}>
                {child.children && (
                    <FormLayout {...props} {...child} registry={registry} />
                )}
            </Component>
        );
    });
}

FormLayout.propTypes = {
    form: formPropTypes.isRequired,
    registry: PropTypes.object.isRequired,
    children: layoutChildrenPropType, // also allows empty layout, and does not render then
};

export default FormLayout;
