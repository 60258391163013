/* eslint-disable no-restricted-globals,radix,no-param-reassign */

import padStart from 'lodash/padStart';

function checkValue(str, max) {
    if (str.charAt(0) !== '0' || str === '00') {
        let num = parseInt(str, 10);
        if (isNaN(num) || num <= 0 || num > max) {
            num = 1;
        }
        str =
            num > parseInt(max.toString().charAt(0)) &&
            num.toString().length === 1
                ? `0${num}`
                : num.toString();
    }
    return str;
}

export function dateFormatOnChange(value) {
    if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
    const values = value.split('/').map((v) => v.replace(/\D/g, ''));
    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    const output = values.map((v, i) =>
        v.length === 2 && i < 2 ? `${v}/` : v,
    );
    return output.join('').substr(0, 14);
}

export default function dateFormat(date) {
    if (isNaN(date)) {
        return '';
    }
    const dates = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
    return dates
        .map((v) => {
            const stringV = v.toString();
            return stringV.length === 1 ? `0${v}` : v;
        })
        .join('/');
}

export function dateFormatOnBlur(value) {
    const values = value.split('/').map((v) => v.replace(/\D/g, ''));
    const output = '';

    if (values.length === 3) {
        const year =
            values[2].length !== 4
                ? parseInt(values[2]) + 2000
                : parseInt(values[2]);
        const day = parseInt(values[0]);
        const month = parseInt(values[1]) - 1;
        const date = new Date(year, month, day);

        return dateFormat(date);
    }
    return output;
}

export function parseFormattedDate(value) {
    if (!value) return null;
    const components = value.split('/');
    return new Date(components[2], components[1] - 1, components[0]);
}

export function toISODateString(date) {
    if (!date) return date;
    const dateComponents = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
    ];
    return dateComponents
        .map((component) => padStart(component.toString(), 2, '0'))
        .join('-');
}
