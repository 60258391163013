import React, { FC, ReactElement, useEffect } from 'react';
import Alert from '@primitives/alert/Alert';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { fetchTenant } from '@store/slice/tenant/thunkAction/fetchTenant';
import { formatAsLocaleDateTime } from '@utilities/dateTimeUtilities';
import useTenantState from '@hooks/useTenantState';
import { getSelectedCampaignLabels } from './SelectedCampaign.trans';
import {
    captureBreadcrumb,
    captureContext,
    captureTag,
} from '../../../../utilities/errorCapturingUtilities';

type Props = {
    children?: ReactElement[];
};

const SelectedCampaign: FC<Props> = ({ children }) => {
    const {
        name,
        campaigns: { active: activeCampaign },
        result,
    } = useTenantState();

    const labels = getSelectedCampaignLabels();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTenant(true));
    }, [dispatch]);

    useEffect(() => {
        captureBreadcrumb('selected campaign changed', 'info', 'campaign', {
            activeCampaign,
        });

        if (name) {
            captureContext('tenant', { name });
            captureTag('tenantName', name);
        }

        if (activeCampaign) {
            captureContext('campaign', activeCampaign || {});
            captureTag('campaignId', activeCampaign?.id);
            captureTag('campaignName', activeCampaign?.name);

            captureTag(
                'layout',
                activeCampaign?.appConfig?.formLayout || 'default',
            );
            captureTag(
                'idealqr',
                !!activeCampaign?.appConfig?.idealQr?.enabled,
            );
        }
    }, [activeCampaign, name]);

    return (
        <div>
            <h5 className="tw-font-semibold tw-m-0 tw-text-dark-gray tw-text-2xl">
                {!activeCampaign && labels.noSelection} {activeCampaign?.name}
            </h5>
            <Alert>{result?.errorMessage}</Alert>
            <p className="tw-text-dark-gray tw-my-4 tw-text-sm tw-font-normal">
                {!result?.lastSuccess ? (
                    labels.noCampaignDownloaded
                ) : (
                    <>
                        {labels.updatedOn}{' '}
                        {result.lastSuccess &&
                            formatAsLocaleDateTime(result.lastSuccess)}
                    </>
                )}
            </p>
            <div className="tw-flex tw-gap-2">{children}</div>
        </div>
    );
};

export default SelectedCampaign;
