import React, { FC, useEffect, useState, ReactNode } from 'react';
import createClassName from 'classnames';
import childrenWithProps from '@primitives/button/utilities/childrenWithProps';
import Button from './Button';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string | ReactNode;
    onChildClick: (event: React.MouseEvent) => void;
}

const DropdownButton: FC<Props> = ({
    label,
    children,
    onChildClick,
    disabled = false,
    ...props
}) => {
    const node = React.useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);

    const onDocumentClick = (event: MouseEvent) => {
        if (!node.current || !(event.target instanceof HTMLElement)) {
            return;
        }

        if (!node.current.contains(event.target)) {
            setShow(false);
        }
    };

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        onChildClick(event);
        setShow(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', onDocumentClick);
        return () => document.removeEventListener('mousedown', onDocumentClick);
    });

    return (
        <div
            className="tw-relative tw-align-middle tw-flex tw-gap-2"
            ref={node}
        >
            <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                className="tw-flex tw-gap-2 tw-items-center"
                type="button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setShow(!show)}
                disabled={disabled}
            >
                {label}
                <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 1.5L6 6.5L11 1.5"
                        stroke="#344054"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Button>
            <div
                className={createClassName(
                    'tw-absolute tw-top-full tw-z-[1000] tw-min-w-[10rem] tw-text-base tw-text-[#212529] tw-text-left tw-bg-white tw-bg-clip-padding tw-border tw-rounded-lg tw-m-0 tw-mt-1 tw-px-0 tw-py-2 tw-border-solid tw-border-[rgba(0,0,0,0.15)]',
                    { 'tw-hidden': !show },
                )}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
            >
                {childrenWithProps({ children, onClick }, 'a')}
            </div>
        </div>
    );
};

export default DropdownButton;
