import React, { FC, useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { RadioInput } from '@primitives/form/Form';
import { asCurrencyFormatMap } from '@formatter/asCurrencyFormat';
import { getAmountRadioInputLabels } from './AmountRadioInput.trans';
import usePreferredAmountPresetForInterval from '../../hooks/usePreferredAmountPresetForInterval';
import { WizardFormState } from '../../types/wizardFormState';

type AmountOptionMap = Map<number, string>;

type Props = {
    form: UseFormMethods<WizardFormState>;
    className?: string;
    disabled?: boolean;
    disabledValues?: Array<string | number>;
    applyDefaultValue?: boolean;
    showOther?: boolean;
    customAmountOptionsMap?: AmountOptionMap;
};

const AmountRadioInput: FC<Props> = ({
    form,
    className,
    disabled = false,
    disabledValues = [],
    applyDefaultValue = true,
    showOther = true,
    customAmountOptionsMap,
}) => {
    const interval = form.watch('interval');
    const amountPreset = usePreferredAmountPresetForInterval(interval);

    const labels = useMemo(() => getAmountRadioInputLabels(), []);

    const amountOptionMap: AmountOptionMap = useMemo(() => {
        const options = asCurrencyFormatMap(amountPreset);

        if (showOther) {
            options.set('alt', labels.otherOptions);
        }

        return options;
    }, [showOther, amountPreset, labels.otherOptions]);

    const optionMap: AmountOptionMap =
        customAmountOptionsMap ?? amountOptionMap;

    const allValues = Array.from(optionMap.keys());

    const defaultValue: string | number | undefined = applyDefaultValue
        ? allValues[0]
        : undefined;

    return (
        <RadioInput
            className={className}
            form={form}
            inline
            defaultValue={defaultValue}
            disabledValues={disabled ? allValues : disabledValues}
            name="amount"
            label={labels.amount}
            options={optionMap}
        />
    );
};

export default AmountRadioInput;
