import { useAppSelector } from './useAppSelector';
import { AppConfig } from '../../../shared/src/contracts/model/appConfig';

export default function useAppConfig(): AppConfig {
    return useAppSelector((state) => {
        const tenantAppConfig = state.tenant.appConfig;
        const campaignAndTenantAppConfig =
            state.tenant.campaigns?.active?.appConfig;

        return campaignAndTenantAppConfig || tenantAppConfig || {};
    });
}
