import React from 'react';
import { Trans } from '@lingui/macro';

export const getHomeLabels = () => ({
    recruiter: (
        <Trans id="home.header.recruiter" comment="Recruiter name">
            Recruiter
        </Trans>
    ),
    campaign: (
        <Trans id="home.header.campaign" comment="Campaign name">
            Campaign
        </Trans>
    ),
    forms: (
        <Trans id="home.header.forms" comment="Submitted forms">
            Forms
        </Trans>
    ),
    preferences: (
        <Trans
            id="home.header.preferences"
            comment="Configurable amount preferences"
        >
            Preferences
        </Trans>
    ),
});
