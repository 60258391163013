import useTextTemplate from '../../../hooks/useTextTemplate';
import templateKeys from '../../../constants/templateKeys';

export const customVerificationSMSTextConfig = 'verificationSMSText';

export default function useDetermineVerificationSMSMessageBody(
    appConfig,
    formData,
) {
    const headerText = useTextTemplate(
        appConfig,
        templateKeys.header,
        formData,
    );
    const customVerificationSMSText = useTextTemplate(
        appConfig,
        customVerificationSMSTextConfig,
        formData,
    );

    if (customVerificationSMSText) {
        return customVerificationSMSText;
    }

    // as the header text is also used for the text in the canvas, we can not add the `Code:` part
    // to the defaultHeaderText.js EJS template
    return headerText ? `${headerText} Code: ` : undefined;
}
