import React from 'react';
import { Route, Link } from 'react-router-dom';
import { getFilePath } from '@integrations/service/api';
import useAppConfig from '@hooks/useAppConfig';
import useActiveCampaign from '@hooks/useActiveCampaign';
import WizardSplash from './components/WizardSplash';
import WizardForm from './components/wizardForm/WizardForm';
import WizardThanks from './components/wizardThanks/WizardThanks';
import './Wizard.scss';
import ThemeStyle from './components/ThemeStyle';
import { getWizardLabels } from './Wizard.trans';
import routes from '../../routes';

export default function Wizard() {
    const campaign = useActiveCampaign(true);
    const appConfig = useAppConfig();
    const labels = getWizardLabels();

    return (
        <div className="wizard">
            <header className="m-0 py-4 wizard-header">
                <div className="container-fluid">
                    <div className="d-flex align-items-center">
                        {appConfig.brandImage ? (
                            <img
                                className="wizard-logo"
                                src={getFilePath(
                                    campaign.id,
                                    appConfig.brandImage,
                                )}
                                alt={campaign.name}
                            />
                        ) : (
                            <h1>{campaign.name}</h1>
                        )}

                        <Link
                            to="/"
                            className="flex-shrink-1 ms-auto btn btn-light"
                        >
                            {labels.close}
                        </Link>
                    </div>
                </div>
            </header>
            <ThemeStyle />
            <Route path={routes.wizard.splash} component={WizardSplash} />
            <Route path={routes.wizard.form} component={WizardForm} />
            <Route path={routes.wizard.thanks} component={WizardThanks} />
        </div>
    );
}
