import React, { FC } from 'react';
import { fetchTenant } from '@store/slice/tenant/thunkAction/fetchTenant';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useTenantState from '@hooks/useTenantState';
import useIsOnline from '@hooks/useIsOnline';
import useIsSignedIn from '@hooks/useIsSignedIn';
import Button from '@primitives/tapraise/form/Button';
import { getUpdateCampaignsLabels } from './UpdateTenantAndCampaigns.trans';

const UpdateTenantAndCampaigns: FC = () => {
    const { result } = useTenantState();

    const online = useIsOnline();

    const signedIn = useIsSignedIn();

    const dispatch = useAppDispatch();

    const labels = getUpdateCampaignsLabels();

    return (
        <Button
            onClick={() => dispatch(fetchTenant())}
            spinner={result?.pending}
            disabled={!online || !signedIn}
            className="tw-min-w-[5rem]"
        >
            {labels.update}
        </Button>
    );
};

export default UpdateTenantAndCampaigns;
