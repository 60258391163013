import React, { FC, MouseEventHandler, useState } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '@utilities/clipboardUtilities';
import useIsMounted from '@hooks/useIsMounted';
import labels from './EntryId.trans';
import CopyEntryIdButton from './components/CopyEntryIdButton';

type Props = {
    id: string | number;
};

const EntryId: FC<Props> = ({ id }) => {
    const checkIsMounted = useIsMounted();

    const [showCopied, setShowCopied] = useState(false);

    const onCopyClick: MouseEventHandler<HTMLButtonElement> = () => {
        copyToClipboard(id.toString());

        setShowCopied(true);

        const timeoutHandle = setTimeout(() => {
            if (checkIsMounted()) {
                setShowCopied(false);
            }
        }, 1000);

        return () => clearTimeout(timeoutHandle);
    };

    return (
        <div className="d-flex align-items-center gap-2">
            {showCopied ? (
                <span className="text-success">{labels.copySuccess()}!</span>
            ) : (
                <>
                    <CopyEntryIdButton id={id} onClick={onCopyClick} />
                    <span className="text-success">✓</span>
                </>
            )}
        </div>
    );
};

EntryId.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]).isRequired,
};

export default EntryId;
