/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const fetchSignOutUserPendingReducer: CaseReducer<UserState> = (
    state,
) => {
    state.result.pending = true;
    state.result.errorMessage = null;
};

export const fetchSignOutUserFulfilledReducer: CaseReducer<UserState> = (
    state,
) => {
    captureBreadcrumb('Sign out fulfilled', 'info', 'auth');

    state.result = {
        pending: false,
        lastSuccess: new Date().getTime(),
        errorMessage: null,
    };
    state.signedIn = false;
};

export const fetchSignOutUserRejectedReducer: CaseReducer<
    UserState,
    PayloadAction<void, string, void, Error>
> = (state, action) => {
    captureBreadcrumb('Sign out failed', 'warning', 'auth', {
        error: action.error,
    });

    state.result.pending = false;
    state.result.errorMessage = action.error.message;
};
