import React, { FC, MouseEventHandler } from 'react';
import Button from '@primitives/tapraise/form/Button';
import { fetchPushEntry } from '@store/slice/buffer/thunkAction/fetchPushEntry';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useIsOnline from '@hooks/useIsOnline';
import useIsSignedIn from '@hooks/useIsSignedIn';
import useBufferState from '@hooks/useBufferState';
import { getSendEntryTranslations } from './SendEntry.trans';

type Props = {
    timestamp: string;
};

const SendEntry: FC<Props> = ({ timestamp }) => {
    const dispatch = useAppDispatch();

    const isOnline = useIsOnline();
    const isSignedIn = useIsSignedIn();
    const {
        result: { pending, pendingTimestamp },
    } = useBufferState();

    const isDisabled = !isOnline || !isSignedIn || pending;
    const isPending =
        !!pendingTimestamp && pendingTimestamp.toString() === timestamp;

    const labels = getSendEntryTranslations();

    const onClick: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(fetchPushEntry(timestamp));
    };

    return (
        <Button
            className="tw-font-semibold tw-border-none tw-text-sm tw-px-2 tw-py-1 tw-my-2 tw-bg-blue tw-text-white tw-min-w-[3rem]"
            onClick={onClick}
            spinner={isPending}
            disabled={isDisabled}
        >
            {labels.send}
        </Button>
    );
};

export default SendEntry;
