import { t } from '@lingui/macro';

export default {
    copyButtonTitle: () =>
        t({
            id: 'copy-text.button.title',
            comment: 'Title of button to copy text',
            message: 'Copy to clipboard',
        }),
    copySuccess: () =>
        t({
            id: 'copy-text.notification.success',
            comment:
                'Notification displayed when text has been copied to the clipboard',
            message: 'Copied to clipboard',
        }),
};
