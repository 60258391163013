import React from 'react';

function ensureArray(children) {
    return Array.isArray(children) ? children : [children];
}

/**
 * Beware! Only use this if absolutely necessary, as this causes issues with PropTypes and needles re-rendering, not to
 *      mention that it creates code that is hard to follow.
 *
 * @param {Object<string, any>} props
 * @param {React.ReactNode} props.children
 * @param {string=} type
 * @returns {React.ReactElement[]}
 */
export default function childrenWithProps(
    { children, ...props },
    type = 'function',
) {
    return ensureArray(children).map((child, key) => {
        if (child?.type !== type) {
            return child;
        }

        return React.createElement(child.type, {
            ...{
                ...child.props,
                ...props,
                key,
            },
        });
    });
}
