import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Interval from '@constants/interval';
import RouteLeavingGuard from './components/RouteLeavingGuard';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods}  props.form
 * @param {string[]} props.allowedPaths
 * @param {import('react-hook-form').SubmitHandler} props.onSubmit
 * @returns {React.ReactElement}
 */
function DirtyFormGuard({ form, allowedPaths = [], onSubmit }) {
    const {
        setValue,
        handleSubmit,
        formState: { isDirty },
    } = form;

    const history = useHistory();

    return (
        <RouteLeavingGuard
            // When should shouldBlockNavigation be invoked, simply passing a boolean
            // (same as "when" prop of <Prompt /> of React-Router)
            when={isDirty}
            // Navigate function
            navigate={(path) => history.push(path)}
            // This case it blocks the navigation when:
            // 1. the form is dirty, and
            // 2. the user is navigating away.
            shouldBlockNavigation={(location) => {
                if (isDirty && !allowedPaths.includes(location.pathname)) {
                    return true;
                }

                return false;
            }}
            // Callback function to be executed when the user confirms the one-time payment
            onIdealQrPaymentSuccess={async () => {
                // Important: set interval to a one-time payment
                setValue('interval', Interval.oneTime);

                // Submit form as usual
                await handleSubmit(onSubmit)();
            }}
        />
    );
}

DirtyFormGuard.propTypes = {
    form: formPropTypes.isRequired,
    allowedPaths: PropTypes.arrayOf(PropTypes.string.isRequired),
    onSubmit: PropTypes.func.isRequired,
};

export default DirtyFormGuard;
