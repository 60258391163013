import { contextLogger } from '@utilities/logger';
import { storageKey } from '@store/storage/stateStorage';
import useBufferState from '@hooks/useBufferState';
import { BufferState } from '@store/slice/buffer/types';

/*
 * Tests have shown 5101k on Chrome Android:
 * http://dev-test.nemikor.com/web-storage/support-test/
 */

const million = 1e6;
const maximum = 5.1 * million;
const safeArea = 1.1 * million;
export const quota = maximum - safeArea;
const percentageWarning = 60;
const percentageDanger = 80;

export type StorageStats = {
    percentage: number;
    usage: number;
    quota: number;
    isFull: boolean;
    isWarning: boolean;
    isDanger: boolean;
};

const cache: Record<string, StorageStats> = {};

function inMillions(number: number): number {
    const decimals = 1e2;

    return Math.round((number / million) * decimals) / decimals;
}

function calculateStats(): StorageStats {
    const usage = localStorage.getItem(storageKey)?.length || 0;

    const percentage = Math.round(Math.min(usage / quota, 1) * 1000) / 10;

    const stats = {
        percentage,
        usage: inMillions(usage),
        quota: inMillions(quota),
        isFull: usage >= quota,
        isWarning: percentage >= percentageWarning,
        isDanger: percentage >= percentageDanger,
    };

    contextLogger.info('Storage stats:', stats);

    return stats;
}

function determineCacheKey(bufferState: BufferState): string {
    return `${Object.keys(bufferState.entries).length}@${
        bufferState.result.lastSuccess
    }`;
}

export default function useStorageStats(): StorageStats {
    const bufferState = useBufferState();
    const cacheKey = determineCacheKey(bufferState);

    if (!cache[cacheKey]) {
        cache[cacheKey] = calculateStats();
    }

    return cache[cacheKey];
}
