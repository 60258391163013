import React from 'react';
import { ReactComponent as InfoCircleIcon } from 'bootstrap-icons/icons/info-circle.svg';
import useIsOnline from '@hooks/useIsOnline';
import useIsSignedIn from '@hooks/useIsSignedIn';
import { getConnectionStatus } from './ConnectionStatus.trans';

/**
 * @returns {React.ReactElement}
 */
export default function ConnectionStatus() {
    const isOnline = useIsOnline();
    const signedIn = useIsSignedIn();

    return (
        <div
            className="alert alert-primary d-flex align-items-center"
            role="alert"
        >
            <InfoCircleIcon />
            <div className="ms-2">
                <span>{isOnline ? 'online' : 'offline'}</span>
                <span>{' - '}</span>
                <span>
                    {signedIn
                        ? getConnectionStatus.loggedIn()
                        : getConnectionStatus.loggedOut()}
                </span>
            </div>
        </div>
    );
}
