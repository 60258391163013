import React from 'react';
import { Trans } from '@lingui/macro';

export const getAuthLabels = () => ({
    login: (
        <Trans id="auth.header.login" comment="Title for the login form">
            Log in on Field App
        </Trans>
    ),
    text: (
        <Trans
            id="auth.card.text"
            comment="description text that describes the steps needed to log in to the app"
        >
            Use your email address and password to sign in and use the Field
            App.
        </Trans>
    ),
});
