import { AppConfig } from '../../../../../../shared/src/contracts/model/appConfig';

/**
 * Takes the tenant app config, and merges campaign specific app config
 * over it to create a composed app config.
 *
 * @param {AppConfig} tenantAppConfig
 * @param {AppConfig} campaignAppConfig
 * @returns {AppConfig}
 */
export const createCombinedAppConfig = (
    tenantAppConfig: AppConfig,
    campaignAppConfig: AppConfig,
) => ({
    ...tenantAppConfig,
    ...campaignAppConfig,
});
