import React, { FC, ReactElement, ReactNode, useState } from 'react';
import createClassName from 'classnames';
import FormSection from '@primitives/form/components/FormSection';
import FormRow from '@primitives/form/components/FormRow';
import useAppConfig from '@hooks/useAppConfig';
import { UseFormMethods } from 'react-hook-form';
import labels from '../wizardFormAddress/WizardFormAddress.trans';
import AddressOptIns from './components/AddressOptIns';

type Props = {
    hideOptIns: boolean;
    children: ReactNode;
    form: UseFormMethods<any>;
};

const WizardFormAddressOptInsFormSection: FC<Props> = ({
    hideOptIns = false,
    children,
    form,
}): ReactElement => {
    const appConfig = useAppConfig();
    const [isExtended, setExtended] = useState(
        appConfig.extendOptInsSection ?? true,
    );

    const onToggleOptions = () => {
        setExtended(!isExtended);
    };

    const noOptIns = hideOptIns || appConfig.receiveMailLabel === false;

    const hiddenSectionClassName = createClassName(
        { 'd-none': noOptIns },
        { 'd-none': !isExtended },
    );

    return (
        <FormSection
            title={labels.headerTitle.id as string}
            onToggleOptions={noOptIns ? undefined : onToggleOptions}
        >
            <FormRow>
                {children}

                <div className={hiddenSectionClassName}>
                    <AddressOptIns form={form} />
                </div>
            </FormRow>
        </FormSection>
    );
};

export default WizardFormAddressOptInsFormSection;
