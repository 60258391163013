/* eslint-disable no-param-reassign */

import { CaseReducer } from '@reduxjs/toolkit';
import { createCombinedAppConfig } from '@store/slice/tenant/utilities/stateMutationUtilities';
import { resolveDefaultCampaign } from '@store/slice/tenant/utilities/stateResolver';
import { TenantState } from '../types';

export const resetActiveCampaignReducer: CaseReducer<TenantState> = (state) => {
    if (!state.appConfig || !state.campaigns.list) {
        return;
    }

    let defaultCampaign = resolveDefaultCampaign(
        state.appConfig,
        state.campaigns.list,
    );

    if (defaultCampaign) {
        defaultCampaign = {
            ...defaultCampaign,
            appConfig: createCombinedAppConfig(
                state.appConfig,
                defaultCampaign.appConfig || {},
            ),
        };
    }

    state.campaigns.active = defaultCampaign;
};
