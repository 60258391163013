import React from 'react';
import createClassName from 'classnames';
import { ReactComponent as LockIcon } from 'bootstrap-icons/icons/lock-fill.svg';
import PropTypes from 'prop-types';
import Button from '@primitives/button/Button';
import T from '../IdealQr.trans';

/**
 * @param {object} props
 * @param {boolean} props.disabled
 * @param {Function} props.onClick
 * @param {boolean} props.isGenerating
 * @param {boolean} props.clickable
 * @returns {React.ReactElement}
 */
function RequestCodeButton({ disabled, onClick, isGenerating, clickable }) {
    const className = createClassName(
        'btn-lg',
        disabled ? 'btn-secondary' : 'btn-primary',
    );

    return (
        <Button
            name="request code"
            type="button"
            disabled={disabled && !clickable}
            className={className}
            onClick={onClick}
            spinner={isGenerating}
        >
            {disabled && (
                <>
                    <LockIcon />{' '}
                </>
            )}
            {T.requestCode()}
        </Button>
    );
}

RequestCodeButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    clickable: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    isGenerating: PropTypes.bool.isRequired,
};

export default RequestCodeButton;
