import React, {
    ChangeEventHandler,
    ComponentProps,
    FC,
    FocusEventHandler,
} from 'react';
import { dateFormatOnBlur, dateFormatOnChange } from '@formatter/dateFormatter';
import TextInput from './textInput/TextInput';

type Props = Pick<
    ComponentProps<typeof TextInput>,
    | 'name'
    | 'form'
    | 'label'
    | 'className'
    | 'spinner'
    | 'isDisabled'
    | 'sup'
    | 'autoFocus'
    | 'children'
    | 'required'
>;

const DateTextInput: FC<Props> = ({
    form,
    name,
    label,
    className,
    spinner = false,
    isDisabled,
    sup,
    autoFocus = false,
    children,
    required = false,
}) => {
    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = dateFormatOnChange(event.target.value);
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = dateFormatOnBlur(event.target.value);
    };

    return (
        <TextInput
            form={form}
            name={name}
            label={label}
            className={className}
            type="text"
            spinner={spinner}
            isDisabled={isDisabled}
            sup={sup}
            autoFocus={autoFocus}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
        >
            {children}
        </TextInput>
    );
};

export default DateTextInput;
