/* eslint-disable jsdoc/require-returns, jsdoc/require-param */
import { MessageDescriptor } from '@lingui/core';

export interface RequiredMessageDescriptor extends MessageDescriptor {
    id: string;
    message: string;
}

export const isRequiredMessageDescriptor = (
    descriptor: MessageDescriptor,
): descriptor is RequiredMessageDescriptor =>
    !!descriptor.id && !!descriptor.message;

/**
 * Takes an object with Lingui message descriptors, and returns an object with the
 * descriptor id's for easy reference using Typescript.
 */
export const toTranslationRecord = <KeyType extends string>(
    messageDescriptorObject: Record<KeyType, MessageDescriptor>,
): Record<KeyType, string> => {
    const output: Record<string, any> = {};

    const keys = Object.keys(messageDescriptorObject) as KeyType[];

    keys.forEach((key: KeyType) => {
        const descriptor = messageDescriptorObject[key];

        if (!isRequiredMessageDescriptor(descriptor)) {
            throw new Error(
                `Message descriptor with key '${key}' should contain an 'id' and 'message' at least`,
            );
        }

        output[key] = descriptor.id;
    });

    return output;
};
