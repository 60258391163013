import React, { FC } from 'react';
import { composeTailwindClassNames } from '@utilities/classNamesUtilities';
import Button from './Button';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    spinner: boolean;
}

const SubmitButton: FC<Props> = ({
    className: additionalClassName,
    children,
    type = 'submit',
    ...props
}) => {
    const className = composeTailwindClassNames(
        'tw-bg-blue tw-text-white tw-text-base',
        additionalClassName,
    );

    return (
        <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            type={type}
            className={className}
        >
            {children}
        </Button>
    );
};

export default SubmitButton;
