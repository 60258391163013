import React, { FC } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';

interface Props {
    children?: React.ReactNode;
}

const Header: FC<Props> = ({ children }) => {
    const offline = useAppSelector((state) => !state.online);

    return (
        <header className="tw-flex tw-items-center tw-self-stretch tw-gap-3">
            <div className="tw-flex tw-items-start tw-content-start tw-self-stretch tw-flex-wrap tw-gap-y-9 tw-gap-x-8">
                <h1 className="tw-text-dark-gray tw-font-semibold tw-text-3xl tw-text-[1.875rem] tw-leading-[2.375rem]">
                    Field App
                </h1>
                {offline && (
                    <span className="tw-inline-block tw-p-1 tw-text-center tw-font-semibold tw-text-sm tw-align-baseline tw-leading-none tw-rounded tw-bg-gray-900 tw-ms-2">
                        Offline
                    </span>
                )}
                {children}
            </div>
        </header>
    );
};

export default Header;
