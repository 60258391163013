import { useMemo } from 'react';
import useAppConfig from '@hooks/useAppConfig';
import Interval from '@constants/interval';
import usePreferredAmountPreset from './usePreferredAmountPreset';
import multiplyAmountPresetByInterval from '../handler/multiplyAmountPresetByInterval';

export default function usePreferredAmountPresetForInterval(
    interval: string | undefined,
): number[] {
    const appConfig = useAppConfig();
    const preset = usePreferredAmountPreset();

    const selectedPreset =
        interval === Interval.oneTime && appConfig?.oneTimeAmountPreset
            ? appConfig.oneTimeAmountPreset
            : preset;

    return useMemo(
        () => multiplyAmountPresetByInterval(selectedPreset, interval),
        [selectedPreset, interval],
    );
}
