import React, { FC, MouseEventHandler } from 'react';
import Button from '@primitives/tapraise/form/Button';
import { getLoggedInUserDetailsLabels } from '../LoggedInUserDetails.trans';

type Props = {
    pending: boolean;
    signedIn: boolean;
    online: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const LogoutButton: FC<Props> = ({ pending, signedIn, online, onClick }) => {
    const labels = getLoggedInUserDetailsLabels();

    return (
        <Button
            spinner={pending}
            disabled={!online || !signedIn}
            onClick={onClick}
        >
            {labels.logout}
        </Button>
    );
};

export default LogoutButton;
