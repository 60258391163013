import { useEffect, useRef } from 'react';

export default function useInterval(
    callback,
    delay,
    startCondition = true,
    clearCondition = false,
) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id;
        if (startCondition) {
            id = setInterval(tick, delay);
        }
        if (clearCondition) {
            clearInterval(id);
        }
        return () => {
            if (id) {
                clearInterval(id);
            }
        };
    }, [delay, clearCondition, startCondition]);
}
