import React from 'react';
import { Trans } from '@lingui/macro';

export const getStartNewFormLabels = () => ({
    new: (
        <Trans
            id="home.button.new"
            comment="Button to open a new form with the selected campaign and preferences"
        >
            New
        </Trans>
    ),
});
