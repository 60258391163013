/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    captureBreadcrumb,
    captureLoggedInUser,
    clearCapturedLoggedInUser,
} from '@utilities/errorCapturingUtilities';
import { UserState } from '../types';

export const updateSignedInReducer: CaseReducer<
    UserState,
    PayloadAction<boolean>
> = (state, action) => {
    const signedIn = action.payload;

    state.signedIn = signedIn;

    const userId = state.details?.id || 'unknown';
    const username = state.details?.name || 'unknown';

    captureBreadcrumb('auth state changed', 'info', 'auth', {
        signedIn,
        userId,
        username,
    });

    if (signedIn) {
        captureLoggedInUser(userId, username);
    } else {
        clearCapturedLoggedInUser();
    }
};
