import React, { FC } from 'react';
import { IsDisabledCallback } from '@primitives/form/components/textInput/TextInput';
import { UseFormMethods } from 'react-hook-form';
import { TextInput } from '@primitives/form/Form';
import useIsCommercialCampaign from '@hooks/useIsCommercialCampaign';
import { getAlternativeAmountInputLabels } from './AlternativeAmountInput.trans';
import { WizardFormState } from '../../types/wizardFormState';

type Props = {
    form: UseFormMethods<WizardFormState>;
    className?: string;
    disabled?: boolean;
};

const AlternativeAmountInput: FC<Props> = ({
    form,
    className,
    disabled = false,
}) => {
    const labels = getAlternativeAmountInputLabels();
    const isCommercialCampaign = useIsCommercialCampaign();

    const resolveDisableInput: IsDisabledCallback = (watch) => {
        if (isCommercialCampaign || watch('amount') !== 'alt') {
            return true;
        }

        return disabled;
    };

    return (
        <TextInput
            form={form}
            className={className}
            type="number"
            name="amountAlternative"
            label={labels.alternativeAmount}
            step=".1"
            isDisabled={resolveDisableInput}
        />
    );
};

export default AlternativeAmountInput;
