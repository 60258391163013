/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { setAmountIfAllowedReducer } from './reducer/setAmountIfAllowed';
import { createIdealQrCharge } from './thunkAction/createIdealQrCharge';
import { fetchIdealQrPaymentStatus } from './thunkAction/fetchIdealQrPaymentStatus';
import { resetReducer } from './reducer/resetReducer';
import {
    createIdealQrChargeErrorReducer,
    createIdealQrChargeFulfilledReducer,
    createIdealQrChargePendingReducer,
} from './reducer/createIdealQrChargeReducer';
import {
    fetchIdealQrPaymentStatusErrorReducer,
    fetchIdealQrPaymentStatusFulfilledReducer,
    fetchIdealQrPaymentStatusPendingReducer,
} from './reducer/fetchIdealQrPaymentStatusReducer';
import { SliceNamespace } from '../../constants/namespace';
import { IdealQrState } from './types';

export const initialState: IdealQrState = {
    isGenerating: false,
    hasGenerationError: false,
    codeId: null,
    codeUrl: null,
    isPolling: false,
    hasStatusError: false,
    status: null,
    amount: null,
};

export const createIdealQrSlice = () =>
    createSlice({
        name: SliceNamespace.IdealQr,
        initialState,
        reducers: {
            setAmountIfAllowed: setAmountIfAllowedReducer,
            reset: resetReducer,
        },
        extraReducers: {
            [createIdealQrCharge.pending.toString()]:
                createIdealQrChargePendingReducer,
            [createIdealQrCharge.fulfilled.toString()]:
                createIdealQrChargeFulfilledReducer,
            [createIdealQrCharge.rejected.toString()]:
                createIdealQrChargeErrorReducer,
            [fetchIdealQrPaymentStatus.pending.toString()]:
                fetchIdealQrPaymentStatusPendingReducer,
            [fetchIdealQrPaymentStatus.fulfilled.toString()]:
                fetchIdealQrPaymentStatusFulfilledReducer,
            [fetchIdealQrPaymentStatus.rejected.toString()]:
                fetchIdealQrPaymentStatusErrorReducer,
        },
    });

const idealQrSlice = createIdealQrSlice();

export default idealQrSlice;

export const { setAmountIfAllowed, reset } = idealQrSlice.actions;
