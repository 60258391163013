import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import { InvalidFeedback } from './InvalidFeedback';
import FormLabel from './FormLabel';

interface Props
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> {
    form: UseFormMethods<any>;
    name: string;
    label?: string | ReactNode;
    spinner?: boolean;
    sup?: string;
}

const TextInput: FC<Props> = ({
    form,
    spinner = false,
    label,
    name,
    sup,
    children,
    required,
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = form;

    const className = classNames(
        'tw-block tw-w-full tw-text-base tw-font-normal tw-leading-normal tw-text-[#212529] tw-bg-white tw-bg-clip-padding tw-border tw-shadow-xs tw-appearance-none tw-px-3 tw-py-1.5 tw-rounded-[0.4rem] tw-border-solid tw-border-[#D4DBDF] focus:tw-outline-blue placeholder:tw-font-normal placeholder:tw-text-sm placeholder:tw-text-[#667085]',
        {
            'is-invalid': errors[name],
        },
    );

    return (
        <div className="tw-mb-4 tw-relative">
            <FormLabel
                htmlFor={name}
                sup={sup}
                className="tw-font-medium tw-text-sm"
            >
                {label}
            </FormLabel>
            <input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                id={name}
                name={name}
                readOnly={spinner}
                ref={register({ required })}
                className={className}
                required={required}
            />
            {spinner && (
                <span
                    className="spinner-grow text-muted spinner-grow-sm"
                    role="alert"
                    aria-busy="true"
                />
            )}
            <InvalidFeedback error={errors[name]} />
            {children}
        </div>
    );
};

export default TextInput;
