import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import useIsOnline from '@hooks/useIsOnline';
import { fetchSignOutUser } from '@store/slice/user/thunkAction/fetchSignOutUser';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useUserState from '@hooks/useUserState';
import LogoutButton from './components/LogoutButton';
import SignedInUserInfo from './components/SignedInUserInfo';
import { getLoggedInUserDetailsLabels } from './LoggedInUserDetails.trans';

const LoggedInUserDetails: FC = () => {
    const labels = getLoggedInUserDetailsLabels();
    const { details, result, signedIn } = useUserState();
    const online = useIsOnline();
    const dispatch = useAppDispatch();

    return (
        <>
            {details && (
                <h5 className="tw-font-semibold tw-m-0 tw-text-dark-gray tw-text-2xl">
                    {details.name}
                </h5>
            )}
            <p className="tw-text-sm tw-font-normal tw-m-0 tw-text-dark-gray">
                {signedIn ? (
                    <SignedInUserInfo lastSuccess={result.lastSuccess} />
                ) : (
                    labels.notLoggedIn
                )}
            </p>
            <div className="tw-flex tw-gap-2">
                <Link
                    to="/auth"
                    className="tw-rounded-lg tw-py-2.5 tw-px-3.5 tw-no-underline tw-bg-white tw-font-semibold tw-text-sm tw-shadow-['0px_1px_2px_0px_rgba(16,24,40,0.05)'] tw-text-secondary-blue tw-font-normal tw-border-[1px] tw-border-solid tw-border-[#D4DBDF]"
                >
                    {signedIn ? labels.switchAccount : labels.login}
                </Link>
                <LogoutButton
                    pending={result?.pending}
                    signedIn={signedIn}
                    online={online}
                    onClick={() => {
                        dispatch(fetchSignOutUser());
                    }}
                />
            </div>
        </>
    );
};

export default LoggedInUserDetails;
