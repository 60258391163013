import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import useQuery from '../../hooks/useQuery';

import T from './LandingPageFailed.trans';

export function LandingPageFailed() {
    const { status } = useParams();
    const query = useQuery();
    const paymentId = query.get('payment_id') || 'unknown';

    return (
        <div className="landing-page-backdrop theme">
            <div className="landing-page-backdrop-effect" />
            <div className="landing-page-backdrop-content py-5">
                <div className="container-fluid">
                    <h1>{T.failedHeader()}</h1>
                    <p className="text-large mb-4">{T.instruction()}</p>
                    <p className="mb-4">
                        <small>
                            Status: {status} PaymentID: {paymentId}
                        </small>
                    </p>
                </div>
            </div>
        </div>
    );
}
