import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isValidIBAN } from 'ibantools';
import padStart from 'lodash/padStart';
import PropTypes from 'prop-types';
import SelectOrTextInput from '@primitives/form/components/SelectOrTextInput';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { FormGroup } from '@primitives/form/FormGroup';
import FormLabel from '@primitives/form/FormLabel';
import { parseIban } from '@utilities/ibanParser';
import options from '../../constants/options';
import useDetermineDefaultValues from '../../hooks/useDetermineDefaultValues';
import { formPropTypes } from '../../../../../../propTypes';

const dutchBankCodes = [
    'ABNA',
    'RABO',
    'INGB',
    'ARBN',
    'AEGO',
    'AKBK',
    'ASNB',
    'STOL',
    'BNGH',
    'BNPA',
    'CEBU',
    'CITI',
    'DEUT',
    'DLBK',
    'DSSB',
    'FVLB',
    'FTSB',
    'FRBK',
    'HSBC',
    'KASA',
    'KNAB',
    'KRED',
    'OVBN',
    'RBRB',
    'SNSB',
    'TRIO',
    'BUNQ',
];
const checksumPlaceholder = '';
const dutchCountryCode = 'NL';

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string=} props.label
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string=} props.className
 * @param {boolean=} props.readOnly
 * @returns {React.ReactElement}
 */
function IBANInput({ name, label, form, className, readOnly = false }) {
    const {
        register,
        clearErrors,
        watch,
        setValue,
        formState: { errors },
    } = form;

    const alphaNumericRegex = /[^a-zA-Z0-9]/g;

    const { countryCode: defaultCountryCode } = useDetermineDefaultValues();

    const names = useMemo(
        () => ({
            country: `${name}_country`,
            checksum: `${name}_checksum`,
            bank: `${name}_bank`,
            number: `${name}_number`,
        }),
        [name],
    );

    const [state, setState] = useState({
        [names.country]: defaultCountryCode || dutchCountryCode,
        [names.checksum]: checksumPlaceholder,
        [names.bank]: dutchBankCodes[0],
        [names.number]: '',
    });

    // Watch for IBAN changes so we can update the IBAN field when receiving an IBAN from a successful QR transaction
    const formValue = watch('iban');
    useEffect(() => {
        if (!formValue) {
            return;
        }

        const parsed = parseIban(formValue, false);
        if (!parsed) {
            return;
        }

        const { country, checksum, bank, number } = parsed;

        setState({
            [names.country]: country,
            [names.checksum]: checksum,
            [names.bank]: bank,
            [names.number]: number,
        });
    }, [formValue, names]);

    const isDutchMode = state[names.country] === dutchCountryCode;

    function makeIBAN(newState) {
        const country = newState[names.country];
        const prefix = country === dutchCountryCode ? newState[names.bank] : '';

        const bban = prefix + newState[names.number];

        const iban =
            country === dutchCountryCode
                ? country + newState[names.checksum] + bban
                : country + bban;

        const valid = isValidIBAN(iban);

        if (valid) {
            clearErrors(name);
            return iban;
        }

        return country + checksumPlaceholder + bban;
    }

    function sanitizeState(newState) {
        const sanitizedState = {
            ...newState,
            [names.number]: newState[names.number].replace(
                alphaNumericRegex,
                '',
            ),
        };

        if (isDutchMode) {
            sanitizedState[names.checksum] = sanitizedState[
                names.checksum
            ].replace(alphaNumericRegex, '');
        }

        return sanitizedState;
    }

    function onChange(event) {
        const { name: eventName, value: eventValue } = event.target;

        const newState = sanitizeState({ ...state, [eventName]: eventValue });

        const iban = makeIBAN(newState);

        setState(newState);
        setValue(name, iban, errors[name] && {});
    }

    function onBlurNumber(event) {
        const { value } = event.target;
        if (isDutchMode && value.trim().length >= 2) {
            event.target.value = padStart(value, 10, '0');
            if (event.target.value !== value) {
                onChange(event);
            }
        }
    }

    return (
        <FormGroup className={classNames(className)}>
            <FormLabel htmlFor={names.number}>{label}</FormLabel>
            <input type="hidden" name={name} ref={register} />

            <div
                className={classNames('input-group', {
                    'is-invalid': errors[name],
                })}
            >
                <SelectOrTextInput
                    name={names.country}
                    value={state[names.country]}
                    onChange={onChange}
                    className="input-group-w75"
                    options={options.countryCodes}
                    readonly={readOnly}
                />

                {isDutchMode && (
                    <input
                        type="text"
                        autoComplete="chrome-off"
                        className="form-control input-group-w50 text-center"
                        aria-label="Checksum"
                        onChange={onChange}
                        value={state[names.checksum]}
                        name={names.checksum}
                        maxLength={2}
                        readOnly={readOnly}
                    />
                )}

                {isDutchMode && (
                    <SelectOrTextInput
                        onChange={onChange}
                        value={state[names.bank]}
                        name={names.bank}
                        className="input-group-w100"
                        options={dutchBankCodes}
                        readonly={readOnly}
                        aria-label="Bank code"
                    />
                )}

                <input
                    type="text"
                    autoComplete="chrome-off"
                    aria-label="Account number"
                    name={names.number}
                    value={state[names.number]}
                    id={names.number}
                    onChange={onChange}
                    onBlur={onBlurNumber}
                    onFocus={(event) => event.target.select()}
                    className={classNames('form-control', {
                        'is-invalid': errors[name],
                    })}
                    readOnly={readOnly}
                />
            </div>

            <InvalidFeedback error={errors[name]} />
        </FormGroup>
    );
}

IBANInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default IBANInput;
