/* eslint-disable react/button-has-type */

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import T from './RetryTimeoutButton.trans';

/**
 * @param {object} props
 * @param {boolean} props.disabled
 * @param {Function} props.onClick
 * @param {React.ReactNode} props.children
 * @param {'submit'|'reset'|'button'} props.type
 * @param {string=} props.className
 * @param {number=} props.enableDelay
 * @returns {React.ReactElement}
 */
function RetryTimeoutButton({
    disabled,
    onClick,
    children,
    type,
    className,
    enableDelay = 15,
}) {
    const [isDisabled, setDisabled] = useState(true);
    const [isTimerRunning, setTimerRunning] = useState(false);
    const [timer, setTimer] = useState(0);

    const startTimer = useCallback(() => {
        setTimer(enableDelay);
        setTimerRunning(true);
    }, [enableDelay]);

    const reset = () => {
        setTimer(0);
        setDisabled(true);
    };

    const tickTimer = useCallback(() => {
        const newRemainingDuration = timer - 1;
        setTimer(newRemainingDuration);
        if (newRemainingDuration <= 0) {
            setDisabled(false);
            setTimerRunning(false);
        }
    }, [timer, setTimer]);

    useEffect(() => {
        if (!disabled) {
            startTimer();
        } else {
            reset();
        }
    }, [disabled, startTimer]);

    useEffect(() => {
        if (!isTimerRunning) return undefined;
        const interval = setInterval(() => tickTimer(), 1000);
        return () => clearInterval(interval);
    }, [isTimerRunning, tickTimer]);

    return (
        <div className="mt-2">
            <button
                name="retry-button"
                type={type}
                disabled={isDisabled}
                className={classNames('btn', className)}
                onClick={onClick}
            >
                {children}
            </button>
            {isTimerRunning && (
                <label className="ms-2 text-muted" htmlFor="retry-button">
                    <small>{T.secondsRemaining(timer)}</small>
                </label>
            )}
        </div>
    );
}

RetryTimeoutButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['submit', 'reset', 'button']).isRequired,
    className: PropTypes.string,
    enableDelay: PropTypes.number,
};

export default RetryTimeoutButton;
