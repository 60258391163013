// @todo share this with service? (PD-5078)
enum Interval {
    monthly = 'monthly',
    oneTime = 'one_time',
    yearly = 'yearly',
    halfYearly = 'half_yearly',
    quarterly = 'quarterly',
}

export default Interval;
