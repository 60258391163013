import React from 'react';
import createClassName from 'classnames';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean=} props.hidden
 * @returns {React.ReactElement}
 */
function FormRow({ children, hidden = false }) {
    const className = createClassName('form-row row', {
        'd-none': hidden,
    });

    return <div className={className}>{children}</div>;
}

FormRow.propTypes = {
    children: PropTypes.node.isRequired,
    hidden: PropTypes.bool,
};

export default FormRow;
