/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { BufferState } from '../types';

export const clearResultFromEntryReducer: CaseReducer<
    BufferState,
    PayloadAction<string>
> = (state, action) => {
    const timestamp = action.payload;

    state.result.pending = false;
    state.result.pendingTimestamp = null;

    delete state.entries[timestamp].result;
};
