import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAppConfig from '@hooks/useAppConfig';
import { ThemeText } from '../ThemeStyle';
import { getBackdropLabels } from './WizardBackdrop.trans';

/**
 * @param {object} props
 * @param {string} props.prefix
 * @param {string=} props.to
 * @param {string|object=} props.defaultTitle
 * @returns {React.ReactElement}
 */
function WizardBackdrop({ prefix, to, defaultTitle }) {
    const labels = getBackdropLabels();
    const appConfig = useAppConfig();

    const subtitle = appConfig[`${prefix}Content`];
    const title = appConfig[`${prefix}Title`] || defaultTitle;
    const button = appConfig[`${prefix}ButtonLabel`] || labels.fillIn;

    return (
        <div className={`wizard-backdrop theme-${prefix}`}>
            <div className="wizard-backdrop-effect" />
            <div className="wizard-backdrop-content py-5">
                <div className="container-fluid">
                    {title && (
                        <h1 className="mb-4">
                            <ThemeText text={title} />
                        </h1>
                    )}
                    {subtitle && (
                        <p className="text-large mb-4">
                            <ThemeText text={subtitle} />
                        </p>
                    )}
                    {to && (
                        <Link
                            to={to}
                            className="btn btn-light theme-btn btn-lg"
                        >
                            {button}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

WizardBackdrop.propTypes = {
    prefix: PropTypes.string.isRequired,
    to: PropTypes.string,
    defaultTitle: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired, // = Translation definition
    ]),
};

export default WizardBackdrop;
