/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { setUpdateAvailableReducer } from './reducer/setUpdateAvailableReducer';
import { SliceNamespace } from '../../constants/namespace';

export type NotificationState = {
    updateAvailable: boolean;
};

const initialState: NotificationState = {
    updateAvailable: false,
};

const notificationSlice = createSlice({
    name: SliceNamespace.Notification,
    initialState,
    reducers: {
        setUpdateAvailable: setUpdateAvailableReducer,
    },
});

export default notificationSlice;
export const { setUpdateAvailable } = notificationSlice.actions;
