import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {string} props.htmlFor
 * @param {React.ReactNode|string} props.children
 * @param {string=} props.sup
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
function FormLabel({ htmlFor, children, sup, className }) {
    return (
        <label className={className || 'form-label'} htmlFor={htmlFor}>
            {typeof children === 'string' ? <Trans id={children} /> : children}
            {sup && <sup className="ms-1">{sup}</sup>}
        </label>
    );
}

FormLabel.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    sup: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
    ]),
    className: PropTypes.string,
};

export default FormLabel;
