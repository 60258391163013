/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { SignInResponse, UserState } from '../types';
import {
    captureBreadcrumb,
    captureReduxError,
} from '../../../../utilities/errorCapturingUtilities';

export const fetchSignInUserPendingReducer: CaseReducer<UserState> = (
    state,
) => {
    state.result.pending = true;
    state.result.errorMessage = null;
};

export const fetchSignInUserFulfilledReducer: CaseReducer<
    UserState,
    PayloadAction<SignInResponse>
> = (state, action) => {
    captureBreadcrumb('Sign in fulfilled', 'info', 'auth', action.payload);

    state.result = {
        pending: false,
        lastSuccess: new Date().getTime(),
        errorMessage: null,
    };
    state.signedIn = true;
    state.details = action.payload;
};

export const fetchSignInUserRejectedReducer: CaseReducer<
    UserState,
    PayloadAction<void, string, void, Error>
> = (state, action) => {
    captureBreadcrumb('Sign in failed', 'warning', 'auth', action.error);
    captureReduxError(action.error, 'warning');

    state.result.pending = false;
    state.result.errorMessage = action.error.message;
};
