import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import { UseFormMethods } from 'react-hook-form';
import FormSection from '@primitives/form/components/FormSection';
import FormText from '@primitives/form/FormText';
import FormRow from '@primitives/form/components/FormRow';
import paymentLabels from '../wizardFormPayment/WizardFormPayment.trans';
import IdealQr from './components/idealQr/IdealQr';
import labels from './WizardFormIdealPayment.trans';
import ConnectionStatus from './components/connectionStatus/ConnectionStatus';
import useDetermineIfIbanFieldShouldBeReadOnly from './hooks/useDetermineIfIbanFieldShouldBeReadOnly';
import './WizardFormIdealPayment.scss';
import IBANInput from '../ibanInput/IBANInput';
import { WizardFormState } from '../../types/wizardFormState';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

const WizardFormIdealPayment: FC<Props> = ({ form }) => {
    const ibanFieldIsReadOnly = useDetermineIfIbanFieldShouldBeReadOnly(form);

    return (
        <FormSection title={paymentLabels.headerTitleBankAccount}>
            <ConnectionStatus />
            <FormRow>
                <IdealQr form={form} />

                <div className="mb-3">
                    <hr data-content={labels.divider()} className="hr-text" />
                </div>

                <IBANInput
                    form={form}
                    readOnly={ibanFieldIsReadOnly}
                    className="col-sm-12"
                    name="iban"
                    label={paymentLabels.iban}
                />
                <FormText>
                    <Trans id={labels.ibanFieldExplanatoryText.id as string} />
                </FormText>
            </FormRow>
        </FormSection>
    );
};

export default WizardFormIdealPayment;
