import { IdealQrStatus } from '@constants/idealQrStatus';
import T from '../IdealQr.trans';

/**
 * @param {string|null|undefined} codeId
 * @param {boolean} idealQrAmountIsValid
 * @param {boolean} hasPersonalAndContactData
 * @returns {string|null}
 */
export function determinePreparationMessage(
    codeId,
    idealQrAmountIsValid,
    hasPersonalAndContactData,
) {
    if (codeId) {
        // User already generated an iDeal QR code, no need to show preparation message anymore

        return null;
    }

    if (!hasPersonalAndContactData) {
        return T.notEnoughDataForMandateMessage();
    }

    if (!idealQrAmountIsValid) {
        return T.noAmountMessage();
    }

    return null;
}

/**
 * @param {boolean} isGenerating
 * @param {string | null} codeId
 * @param {string | null}status
 * @param {boolean} hasGenerationError
 * @param {boolean} hasStatusError
 * @param {boolean} isPolling
 * @param {boolean} isOfflineFLow
 * @returns {string|null}
 */
export function determineIntroductionMessage(
    isGenerating,
    codeId,
    status,
    hasGenerationError,
    hasStatusError,
    isPolling,
    isOfflineFLow,
) {
    if (isOfflineFLow) {
        return T.connectionError();
    }

    if (!codeId && !hasGenerationError && !isGenerating) {
        return T.startMessage();
    }

    if (isGenerating) {
        return null;
    }

    if (hasGenerationError) {
        return T.qrGenerationError();
    }

    // at this point we know we have an iDeal QR code, and we start fetching for the payment status

    if (hasStatusError) {
        return T.failureError();
    }

    if (status === IdealQrStatus.SUCCESS) {
        return null;
    }

    switch (status) {
        case null:
        case IdealQrStatus.OPEN:
        case IdealQrStatus.UNINITIALIZED:
            return T.scanCode();

        case IdealQrStatus.PENDING:
            return T.pendingMessage();

        case IdealQrStatus.CANCELLED:
            return T.cancelledMessage();

        case IdealQrStatus.EXPIRED:
        case IdealQrStatus.FAILURE:
            return T.failureError();

        default:
            return null;
    }
}
