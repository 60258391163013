import type { RootState } from '@store/index';
import { SliceNamespace } from '@store/constants/namespace';

export function uniqueUserIdSelector(state: RootState): string | null {
    const userSlice = state[SliceNamespace.User];

    if (!userSlice.details) {
        return null;
    }

    const { id, tenant } = userSlice.details;

    return `${tenant}/${id}`;
}
