import * as Sentry from '@sentry/react';
import type { SeverityLevel } from '@sentry/react';
import { isObject } from 'lodash';
import { Logger } from '@tapraise/logger';
import type {
    Primitive,
    EventHint,
    ErrorEvent,
    CaptureContext,
} from '@sentry/types';
import version from '../version.json';
import {
    isDevEnvironment,
    getEnvironmentIdentifier,
    getBuildName,
} from './environmentIdentificationUtilities';

const isDebugSession = false; // set to true, when debugging Sentry

export const debugLogger = new Logger({
    isEnabled: isDebugSession,
    namespace: 'sentry',
});

function extractErrorMessageFromUnknownErrorFormat(error: unknown): string {
    if (error instanceof Error) {
        return error.message;
    }

    if (
        isObject(error) &&
        (error as Record<string, unknown>).message !== undefined
    ) {
        return (
            ((error as Record<string, unknown>).message as string) ||
            JSON.stringify(error)
        );
    }

    if (typeof error === 'string') {
        return error;
    }

    return JSON.stringify(error);
}

function checkShouldCaptureError(message: string): boolean {
    debugLogger.debug('check should capture:', message);

    const recruiterIsOffline =
        /Kan de server niet bereiken/i.test(message) ||
        /Cannot reach server/i.test(message);
    if (recruiterIsOffline) {
        return false;
    }

    const requestedWasAbortedOrTimedOut = /AbortError/i.test(message);
    if (requestedWasAbortedOrTimedOut) {
        return false;
    }

    const userIsNotAuthenticatedAndTryingToUseService =
        /Authenticatie vereist/i.test(message);
    if (userIsNotAuthenticatedAndTryingToUseService) {
        return false;
    }

    return true;
}

export function initializeErrorCapturing() {
    Sentry.init({
        dsn: 'https://843d1bdd838f45d4bcd37e67ba243932@o279754.ingest.sentry.io/1502908',
        release: version,
        environment: getEnvironmentIdentifier(),
        attachStacktrace: true, // When logging messages, also show stack trace in Sentry
        debug: isDebugSession,
        ignoreErrors: [
            // Also see setup in `beforeSend` config below, that allows for a more fine-grained and easier to debug, control
        ],
        maxBreadcrumbs: 50, // Maximum number of breadcrumbs to keep in Sentry
        normalizeDepth: 5, // Level of depth of objects displayed in Sentry
        enabled: !isDevEnvironment || isDebugSession,
        beforeSendTransaction(transaction) {
            debugLogger.debug('add transaction', transaction);

            return transaction;
        },
        beforeBreadcrumb(breadcrumb) {
            debugLogger.debug('add breadcrumb', breadcrumb);

            return breadcrumb;
        },
        beforeSend(event: ErrorEvent, hint: EventHint) {
            debugLogger.debug('send event', event, hint);

            const shouldCapture = checkShouldCaptureError(
                extractErrorMessageFromUnknownErrorFormat(
                    hint.originalException,
                ),
            );
            debugLogger.debug('should capture verdict', false);
            if (!shouldCapture) {
                return null;
            }

            return event;
        },
        initialScope: {
            tags: {
                version,
                hostname: window.location.hostname,
                build: getBuildName(),
            },
        },
    });
}

export function captureError(
    error: unknown,
    context?: Parameters<typeof Sentry.captureException>[1],
) {
    debugLogger.debug('capture error', error, context || {});

    Sentry.captureException(error, context);

    // eslint-disable-next-line no-console
    console.error(error);
}

export function captureMessage(message: string, context: CaptureContext) {
    Sentry.captureMessage(message, context);
}

function isErrorLikeObject(
    error: unknown,
): error is { message: string; name?: string; stack?: string } {
    return (
        isObject(error) &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
}

export function captureReduxError(
    error: unknown,
    level: SeverityLevel = 'error',
) {
    if (error instanceof Error || !isObject(error)) {
        captureError(error, { level });

        return;
    }

    // error is object, but not instance of Error. This is sometimes the case
    // with Redux somehow.
    if (isErrorLikeObject(error)) {
        const { message, name, stack } = error;

        captureError(message, {
            extra: { name, stack },
            level,
        });

        return;
    }

    captureError(JSON.stringify(error), { level });
}

export type BreadcrumbType =
    | 'auth'
    | 'campaign'
    | 'idealqr'
    | 'preferences'
    | 'connection'
    | 'validation'
    | 'submit'
    | 'update';
export function captureBreadcrumb(
    message: string,
    level: SeverityLevel,
    type: BreadcrumbType,
    data?: Record<string, any>,
) {
    debugLogger.debug('add breadcrumb', { message, level, type, data });

    Sentry.addBreadcrumb({ message, level, type, data });
}

export type TagName =
    | 'build'
    | 'tenantName'
    | 'campaignId'
    | 'campaignName'
    | 'layout'
    | 'idealqr'
    | 'formUuid';
export function captureTag(key: TagName, value: Primitive) {
    debugLogger.debug('capture tag', { key, value });

    Sentry.setTag(key, value);
}

export type ContextKey = 'tenant' | 'campaign';
export function captureContext(key: ContextKey, value: Record<string, any>) {
    debugLogger.debug('capture context', { key, value });

    Sentry.setContext(key, value);
}

export function captureLoggedInUser(id: string, username: string) {
    debugLogger.debug('capture logged-in user', { id, username });

    Sentry.setUser({ id, username });
}

export function clearCapturedLoggedInUser() {
    debugLogger.debug('clear captured logged-in user');

    Sentry.setUser(null);
}
