type ReturnType = [enabled: boolean, labelOrTranslationId: string];

const resolveForOptInEnabledAndLabel = (
    value: boolean | string | undefined,
    defaultTranslationIdOrLabel: string,
): ReturnType => {
    // By default, it is enabled, with the default translation id as label
    if (value === undefined) {
        return [true, defaultTranslationIdOrLabel];
    }

    // As enabled by default, and no specific label is requested, fall back on default label
    if (value === true) {
        return [true, defaultTranslationIdOrLabel];
    }

    if (value === false) {
        return [false, defaultTranslationIdOrLabel];
    }

    // At this point we know that value is a string

    // When the label is an empty string, fall back on default label
    return [true, value || defaultTranslationIdOrLabel];
};

export default resolveForOptInEnabledAndLabel;
