import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SelectOrTextInput from '@primitives/form/components/SelectOrTextInput';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { FormGroup } from '@primitives/form/FormGroup';
import FormLabel from '@primitives/form/FormLabel';
import { resolveFormatterOrThrow } from '@resolver/formatterResolver';
import options from '../../constants/options';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {string=} props.label
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string=} props.className
 * @param {string=} props.name
 * @returns {React.ReactElement}
 */
function CountryInput({ label, form, className, name = 'countryCode' }) {
    const {
        setValue,
        watch,
        register,
        formState: { errors },
    } = form;

    function onChange(event) {
        const format = resolveFormatterOrThrow('identifier').onChange;

        setValue(name, format(event.target.value));
    }

    return (
        <FormGroup className={classNames(className)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <input type="hidden" name={name} ref={register} />

            <SelectOrTextInput
                options={options.countryCodes}
                id={name}
                value={watch(name)}
                onChange={onChange}
                className={classNames('form-control', {
                    'is-invalid': errors[name],
                })}
            />

            <InvalidFeedback error={errors[name]} />
        </FormGroup>
    );
}

CountryInput.propTypes = {
    label: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
};

export default CountryInput;
