import React from 'react';
import { Trans } from '@lingui/macro';

export const getSignatureCanvasLabels = () => ({
    label: (
        <Trans
            id="wizard.form.confirmation.signature.label"
            comment="label for the signature canvas"
        >
            Signature
        </Trans>
    ),
    retry: <Trans id="button.retry">Retry</Trans>,
});
