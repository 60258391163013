import React from 'react';
import { Trans } from '@lingui/macro';

export const getChangeUserPreferencesLabels = () => ({
    currencyOptions: (
        <Trans
            id="preferences.currencyOptions.label"
            comment="label for radio buttons where the user can choose between various currency preset options"
        >
            Payment options
        </Trans>
    ),
});
