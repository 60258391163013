import React, { useCallback } from 'react';
import * as notificationSlice from '@store/slice/notification/notificationSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import * as serviceWorker from '../../../../../../registerServiceWorker';
import Notification from './components/notification/Notification';
import T from './UpdateNotification.trans';

export default function UpdateNotification() {
    const isUpdateAvailable = useAppSelector(
        (state) => state.notification.updateAvailable,
    );
    const dispatch = useAppDispatch();

    const onConfirm = useCallback(
        (ok) => {
            dispatch(notificationSlice.setUpdateAvailable(false));
            if (ok) serviceWorker.skipWaitingAndRefresh();
        },
        [dispatch],
    );

    return isUpdateAvailable ? (
        <Notification
            onConfirm={onConfirm}
            title={T.title()}
            label={T.label()}
        />
    ) : null;
}
