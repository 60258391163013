import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import classNames from 'classnames';

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
    sup?: string;
}

const FormLabel: FC<Props> = ({
    sup,
    children,
    className: additionalClassName,
    ...props
}) => {
    const className = classNames('tw-mb-2', additionalClassName);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading, jsx-a11y/label-has-associated-control
        <label {...props} className={className}>
            {typeof children === 'string' ? <Trans id={children} /> : children}
            {sup && <sup className="tw-ms-1">{sup}</sup>}
        </label>
    );
};

export default FormLabel;
