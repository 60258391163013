import { t } from '@lingui/macro';

export default {
    secondsRemaining: (remainingDuration) =>
        t({
            id: 'wizard.form.confirmation.audioSignature.secondsRemaining',
            comment:
                'text indication the amount of time is left to record the signature',
            message: `${remainingDuration} seconds remaining.`,
        }),
};
