import useActiveCampaign from '@hooks/useActiveCampaign';
import React from 'react';
import Interval from '@constants/interval';
import Skeleton from './Skeleton';
import complianceTextPerIntervalResolver from '../resolver/complianceTextPerIntervalResolver';
import { ComplianceListComponent } from '../types/ComplianceList';

const TMGNoQrComplianceList: ComplianceListComponent = ({ interval }) => {
    const campaign = useActiveCampaign(true);

    const intervalText = complianceTextPerIntervalResolver(interval);

    return (
        <Skeleton.Container>
            <Skeleton.Heading>Compliance</Skeleton.Heading>
            <Skeleton.List>
                <Skeleton.ListItem>
                    De werver heeft zichzelf voorgesteld.
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft zichzelf gelegitimeerd met de werverspas van{' '}
                    {campaign.name || 'deze campagne'}.
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft verteld dat het gaat om {intervalText}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft ter controle het ingevulde formulier samen
                    met u doorlopen om de gemaakte afspraken te confirmeren.
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft verteld dat het om een automatische incasso
                    gaat.
                </Skeleton.ListItem>
                {interval !== Interval.oneTime && (
                    <>
                        <Skeleton.ListItem>
                            De werver heeft verteld dat opzeggen door de
                            donateur zelf gedaan dient te worden, per mail of
                            via de telefoon.
                        </Skeleton.ListItem>
                        <Skeleton.ListItem>
                            De werver heeft verteld dat u wordt gebeld om de
                            gemaakte afspraken te controleren en om bovenstaande
                            punten met u door te nemen.
                        </Skeleton.ListItem>
                    </>
                )}
            </Skeleton.List>
        </Skeleton.Container>
    );
};

export default TMGNoQrComplianceList;
