import React, { FC } from 'react';
import classNames from 'classnames';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import FormLabel from './FormLabel';

function ensureSortedMap(options: Map<string, any> | Record<string, any>) {
    return options instanceof Map ? options : new Map(Object.entries(options));
}

type Props = {
    name: string;
    label: string | React.ReactNode;
    options: Record<string, any>;
    form: any;
    inline?: boolean;
    className?: string;
    defaultValue?: string | number;
    disabledValues?: Array<string | number>;
    required?: boolean;
};

const RadioInput: FC<Props> = ({
    name,
    label,
    options,
    form,
    inline = false,
    className,
    defaultValue,
    disabledValues = [],
    required = false,
}) => {
    const {
        register,
        formState: { errors },
    } = form;

    const optionMap = ensureSortedMap(options);
    const optionKeys = Array.from(optionMap.keys());
    const controlClassName = classNames('tw-relative tw-block tw-mb-2', {
        'tw-inline-block tw-mr-2': inline,
    });

    return (
        <div className={classNames(className)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <div className="tw-mt-2">
                {optionKeys.map((key) => (
                    <div className={controlClassName} key={`${name}_${key}`}>
                        <input
                            type="radio"
                            id={`${name}_${key}`}
                            name={name}
                            defaultChecked={defaultValue === key}
                            className="checked:tw-bg-blue tw-border-blue"
                            value={key}
                            disabled={disabledValues.indexOf(key) >= 0}
                            ref={register({ required })}
                        />
                        <FormLabel
                            className="tw-text-darker-gray tw-pl-2"
                            htmlFor={`${name}_${key}`}
                        >
                            {optionMap.get(key)}
                        </FormLabel>
                    </div>
                ))}
            </div>
            <InvalidFeedback error={errors[name]} />
        </div>
    );
};

export default RadioInput;
