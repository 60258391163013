import React from 'react';
import { Trans } from '@lingui/macro';

export const getBackdropLabels = () => ({
    fillIn: (
        <Trans
            id="wizard.backdrop.fillIn"
            comment="default button text that is shown prompting the user to fill in the form"
        >
            Fill in
        </Trans>
    ),
});
