import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useAppSelector } from '@hooks/useAppSelector';
import useResetIdealQrStateWhenNavigatingAwayFromForm from '@hooks/useResetIdealQrStateWhenNavigatingAwayFromForm';
import { i18n } from '@lingui/core';
import Home from './features/home/Home';
import Auth from './features/auth/Auth';
import Wizard from './features/wizard/Wizard';
import store, { attachGlobalListeners } from './store';
import routes from './routes';
import LandingPage from './features/landingPage/LandingPage';
import { captureTag } from './utilities/errorCapturingUtilities';

attachGlobalListeners();

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </Provider>
    );
}

function AppRoutes() {
    const hasUserDetails = useAppSelector((state) => state.user.details);

    useResetIdealQrStateWhenNavigatingAwayFromForm();

    useEffect(() => {
        captureTag('locale', i18n.locale);
    }, []);

    return (
        <Switch>
            <Route
                path="/campaigns/:campaignId/payment/:status"
                component={LandingPage}
            />
            <Route path={routes.auth} component={Auth} />
            {!hasUserDetails && <Redirect to={routes.auth} />}
            <Route path={routes.wizard.root} component={Wizard} />
            <Route default component={Home} />
        </Switch>
    );
}
