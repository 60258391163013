/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createCombinedAppConfig } from '@store/slice/tenant/utilities/stateMutationUtilities';
import { TenantState } from '../types';

export const setActiveCampaignReducer: CaseReducer<
    TenantState,
    PayloadAction<string>
> = (state, action) => {
    if (!state.campaigns.list) {
        throw new Error(
            'Expecting active campaign list to be set at this point',
        );
    }

    const activeCampaign = state.campaigns.list[action.payload];

    if (!activeCampaign) {
        throw new Error(
            `Requested campaign '${action.payload}' not in campaign list`,
        );
    }

    if (state.appConfig) {
        // Pre-merge tenant app config and active campaign app config, because if we do this
        // on the fly in the useAppConfig, as we did previously, its output changes at every
        // render, causing needless and too much re-renders.
        activeCampaign.appConfig = createCombinedAppConfig(
            state.appConfig,
            activeCampaign.appConfig || {},
        );
    }

    state.campaigns.active = activeCampaign;
};
