import React from 'react';
import { ReactComponent as ThreeDotsIcon } from 'bootstrap-icons/icons/three-dots.svg';
import { Trans } from '@lingui/macro';
import createClassName from 'classnames';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {Function} props.onClick
 * @returns {React.ReactElement}
 */
function FormSectionToggleButton({ onClick }) {
    return (
        <button
            className="btn border-0 btn-sm"
            type="button"
            onClick={onClick}
            aria-label="toggle section"
        >
            <ThreeDotsIcon />
        </button>
    );
}

FormSectionToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 * @param {Function=} props.onToggleOptions
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
function FormSection({
    title,
    children,
    onToggleOptions,
    className: additionalClassName,
}) {
    const className = createClassName('pt-5', additionalClassName);

    return (
        <section className={className}>
            <div className="d-flex mb-3 align-items-center">
                <h5 className="m-0 me-auto">
                    <Trans id={title} />
                </h5>
                {onToggleOptions && (
                    <FormSectionToggleButton onClick={onToggleOptions} />
                )}
            </div>
            {children}
        </section>
    );
}

FormSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onToggleOptions: PropTypes.func,
    className: PropTypes.string,
};

export default FormSection;
