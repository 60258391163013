/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchTenant } from './thunkAction/fetchTenant';
import { setActiveCampaignReducer } from './reducer/setActiveCampaignReducer';
import { resetActiveCampaignReducer } from './reducer/resetActiveCampaignReducer';
import {
    fetchTenantFulfilledReducer,
    fetchTenantPendingReducer,
    fetchTenantRejectedReducer,
} from './reducer/fetchTenantReducer';
import { SliceNamespace } from '../../constants/namespace';
import { TenantState } from './types';

const initialState: TenantState = {
    appConfig: null,
    name: null,
    campaigns: {
        list: null,
        active: null,
    },
    result: {
        lastSuccess: null,
        pending: false,
        errorMessage: null,
    },
};

export const createTenantSlice = () =>
    createSlice({
        name: SliceNamespace.Tenant,
        initialState,
        reducers: {
            setActiveCampaign: setActiveCampaignReducer,
            resetActiveCampaign: resetActiveCampaignReducer,
        },
        extraReducers: {
            [fetchTenant.pending.toString()]: fetchTenantPendingReducer,
            [fetchTenant.fulfilled.toString()]: fetchTenantFulfilledReducer,
            [fetchTenant.rejected.toString()]: fetchTenantRejectedReducer,
        },
    });

const tenantSlice = createTenantSlice();
export default tenantSlice;

export const { setActiveCampaign, resetActiveCampaign } = tenantSlice.actions;
