import * as Yup from 'yup';
import '@validator/yupMethods';
import { getRequiredTranslation } from '@validator/yupMethods.trans';
import Interval from '@constants/interval';
import {
    resolveAmountAlternativeRange,
    resolveIdealQrIsEnabled,
} from '@resolver/appConfigValueResolver';
import { IdealQrState } from '@store/slice/idealQr/types';
import { IdealQrStatus } from '@constants/idealQrStatus';
import { AppConfig } from '../../../../../../../shared/src/contracts/model/appConfig';

export default function validationSchemaFactory(
    appConfig: AppConfig,
    idealQrState: IdealQrState,
) {
    const minAge = appConfig?.minAge || 18;
    const maxAge = appConfig?.maxAge || 99;
    const { minAmountAlternative, maxAmountAlternative } =
        resolveAmountAlternativeRange(appConfig);

    return Yup.object().shape({
        firstname: Yup.string()
            .trim()
            .name()
            .min(2)
            .max(255)
            .nullable()
            .transform((value) => value || null),
        initials: Yup.string().required().trim().max(64),
        surnamePrefix: Yup.string().trim().name().max(10),
        surname: Yup.string().required().trim().name().max(64),

        sex: Yup.string().required().trim(),
        academicTitle: Yup.string().trim(),
        dateOfBirth: Yup.string()
            .required()
            .trim()
            .matchesDateFormat()
            .minAge(minAge)
            .maxAge(maxAge),

        email: Yup.string().required().trim().email().max(255),
        language: Yup.string().required().trim(),
        mobilePhoneNumber: Yup.string()
            .trim()
            .phoneNumber()
            .when('phoneNumber', {
                is: (value?: string) => value === undefined || value === '',
                then: Yup.string().required(),
            }),
        phoneNumber: Yup.string().trim().phoneNumber(),

        countryCode: Yup.string().required().trim().countryCode(),
        zipCode: Yup.string()
            .required()
            .trim()
            .min(3)
            .max(10)
            .matches(/^([0-9a-zA-Z]+)$/),
        houseNumber: Yup.string()
            .required()
            .trim()
            .matches(/^[0-9]+$/)
            .max(10),
        houseNumberExtension: Yup.string()
            .trim()
            .max(10)
            .matches(/^[a-zA-Z\d -]+$/, { excludeEmptyString: true }),
        street: Yup.string().required().trim(),
        city: Yup.string().required().trim().name().max(150),

        interval: Yup.string().required().trim(),
        startDate: Yup.string().required().trim().matchesDateFormat(),
        amount: Yup.string()
            .required()
            .trim()
            .typeError(getRequiredTranslation()),
        amountAlternative: Yup.string()
            .when('amount', {
                is: 'alt',
                then: Yup.string()
                    .validateAlternativeAmountInRange(
                        minAmountAlternative,
                        maxAmountAlternative,
                    )
                    .required(),
            })
            .trim(),
        iban: Yup.string().required().trim().iban(),

        subscribeToNewsletter: Yup.boolean(),
        subscribeToPost: Yup.boolean(),
        subscribeToSms: Yup.boolean(),
        subscribeToCalls: Yup.boolean(),

        recurringConfirmation: Yup.boolean().when(
            ['interval', '$appConfig.requireRecurringConfirmation'],
            {
                is: (interval: Interval, required: boolean) =>
                    required && interval !== Interval.oneTime,
                then: Yup.boolean().required().true(),
            },
        ),

        preferredPaymentDay: Yup.string().when(
            ['$appConfig.preferredPaymentDay'],
            {
                is: (required: boolean) => required,
                then: Yup.string().required(),
                otherwise: Yup.string().nullable(),
            },
        ),

        signaturePayload: Yup.string().when(['interval', '$appConfig'], {
            is: (interval: Interval, appConfigProp: AppConfig) =>
                interval === Interval.oneTime &&
                resolveIdealQrIsEnabled(appConfigProp) &&
                idealQrState.status === IdealQrStatus.SUCCESS,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required().dataUrl(),
        }),
        socialSecurityNumber: Yup.string().nullable().trim(),
    });
}
