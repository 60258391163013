import useInterval from '@hooks/useInterval';
import useIdealQrState from '@hooks/useIdealQrState';
import useIsOnline from '@hooks/useIsOnline';
import { isFinalStatus } from '@constants/idealQrStatus';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { fetchIdealQrPaymentStatus } from '@store/slice/idealQr/thunkAction/fetchIdealQrPaymentStatus';

/**
 * @param {Function} setValue
 * @param {string} campaignId
 * @returns {Function}
 */
export default function useFetchIdealQrStatus(setValue, campaignId) {
    const isOnline = useIsOnline();

    const dispatch = useAppDispatch();

    const { codeId, status } = useIdealQrState();

    const fetchPaymentStatus = async () => {
        const action = await dispatch(fetchIdealQrPaymentStatus(campaignId));

        const iban = action?.payload?.iban;

        if (iban) {
            setValue('iban', iban);
        }
    };

    const hasGeneratedIdealQrCharge = !!codeId;

    useInterval(
        fetchPaymentStatus,
        10000,
        hasGeneratedIdealQrCharge && isOnline,
        isFinalStatus(status) || !isOnline,
    );

    return fetchPaymentStatus;
}
