import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { CheckInput } from '@primitives/form/Form';
import FormSection from '@primitives/form/components/FormSection';
import useAppConfig from '@hooks/useAppConfig';
import Interval from '@constants/interval';
import labels from './WizardFormConfirmation.trans';
import SignatureFormRow from '../signatureFormRow/SignatureFormRow';
import useDetermineIfSignatureIsNeeded from './hooks/useDetermineIfSignatureIsNeeded';
import useResetSignatureFieldsWhenNotNeeded from './hooks/useResetSignatureFieldsWhenNotNeeded';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string=} props.title
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {boolean=} props.disabled
 * @returns {React.ReactElement}
 */
function WizardFormConfirmation({
    children,
    title = labels.headerTitle.id,
    form,
    disabled = false,
}) {
    const appConfig = useAppConfig();

    const { watch, setValue } = form;

    const isRecurring = watch('interval') !== Interval.oneTime;
    const [isExtended, setExtended] = useState(false);

    const onToggleOptions = () => {
        setExtended(!isExtended);
    };

    const signatureIsNeeded = useDetermineIfSignatureIsNeeded(watch);

    useResetSignatureFieldsWhenNotNeeded(signatureIsNeeded, setValue);

    const label =
        typeof appConfig.requireRecurringConfirmation === 'string'
            ? appConfig.requireRecurringConfirmation
            : labels.recurringConfirmationLabelText.id;

    return (
        <FormSection title={title} onToggleOptions={onToggleOptions}>
            {children}

            {appConfig.requireRecurringConfirmation && isRecurring && (
                <CheckInput
                    form={form}
                    className="border-top border-bottom py-3"
                    name="recurringConfirmation"
                    label={label}
                    disabled={disabled}
                />
            )}

            {signatureIsNeeded ? (
                <SignatureFormRow form={form} showOptions={isExtended} />
            ) : (
                <div className="alert alert-info" role="alert">
                    <Trans id={labels.noMandateRequiredNotice.id} />
                </div>
            )}
        </FormSection>
    );
}

WizardFormConfirmation.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    form: formPropTypes.isRequired,
    disabled: PropTypes.bool,
};

export default WizardFormConfirmation;
