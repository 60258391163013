import React, { FC, ReactElement } from 'react';
import { CheckInput } from '@primitives/form/components/CheckInput';
import useAppConfig from '@hooks/useAppConfig';
import { UseFormMethods } from 'react-hook-form';
import {
    resolveIsCallsOptInLocked,
    resolveIsEmailOptInLocked,
    resolveIsSmsOptInLocked,
} from '@resolver/appConfigValueResolver';
import labels from '../../../wizardFormPersonal/WizardFormPersonal.trans';
import resolveForOptInEnabledAndLabel from './resolver/resolveForOptInEnabledAndLabel';

type Props = {
    form: UseFormMethods;
};

const PersonalOptIns: FC<Props> = ({ form }): ReactElement => {
    const appConfig = useAppConfig();

    // When there are no label(s), we hide the opt-in(s) from view. This keeps the component mounted and able to pass on its state

    const [emailOptInEnabled, emailOptInLabel] = resolveForOptInEnabledAndLabel(
        appConfig.receiveEmailLabel,
        labels.emailLabelText.id as string,
    );

    const [callOptInEnabled, callOptInLabel] = resolveForOptInEnabledAndLabel(
        appConfig.receiveCallsLabel,
        labels.callsLabelText.id as string,
    );

    let smsLabel = '';
    if (typeof appConfig.receiveSmsLabel === 'string') {
        smsLabel = appConfig.receiveSmsLabel;
    } else if (
        typeof appConfig.receiveSmsLabel === 'boolean' &&
        appConfig.receiveSmsLabel
    ) {
        smsLabel = labels.smsLabelText.id as string;
    }

    const emailOptInLocked = resolveIsEmailOptInLocked(appConfig);
    const callsOptInLocked = resolveIsCallsOptInLocked(appConfig);
    const smsOptInLocked = resolveIsSmsOptInLocked(appConfig);

    return (
        <>
            {/* We want to always submit a subscribe to newsletter value. The default value is set to true, so this will be submitted, even when not enabled */}
            <CheckInput
                form={form}
                name="subscribeToNewsletter"
                label={emailOptInLabel}
                className={emailOptInEnabled ? undefined : 'd-none'}
                disabled={emailOptInLocked}
            />

            {callOptInEnabled && (
                <CheckInput
                    form={form}
                    name="subscribeToCalls"
                    label={callOptInLabel}
                    disabled={callsOptInLocked}
                />
            )}

            {smsLabel && (
                <CheckInput
                    form={form}
                    name="subscribeToSms"
                    label={smsLabel}
                    disabled={smsOptInLocked}
                />
            )}
        </>
    );
};

export default PersonalOptIns;
