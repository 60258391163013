// Beware! Keep in line with IdealQrCodeStatus in service
export enum IdealQrStatus {
    UNINITIALIZED = 'Uninitialized',
    OPEN = 'Open',
    SUCCESS = 'Success',
    PENDING = 'Pending',
    FAILURE = 'Failure',
    EXPIRED = 'Expired',
    CANCELLED = 'Cancelled',
}

export function validateStatusIsSupported(
    status: string,
): status is IdealQrStatus {
    if (!Object.values(IdealQrStatus).includes(status as any)) {
        throw new Error('Unexpected status retrieved from iDeal QR API');
    }

    return true;
}

export function isFinalStatus(status: IdealQrStatus | null): boolean {
    switch (status) {
        case IdealQrStatus.SUCCESS:
            return true;

        case null:
        case IdealQrStatus.UNINITIALIZED:
        case IdealQrStatus.OPEN:
        case IdealQrStatus.CANCELLED:
        case IdealQrStatus.EXPIRED:
        case IdealQrStatus.FAILURE:
        case IdealQrStatus.PENDING:
            return false;

        default:
            throw new Error(`Status '${status}' not supported`);
    }
}

export function isFailureStatus(status: IdealQrStatus): boolean {
    return [(IdealQrStatus.FAILURE, IdealQrStatus.EXPIRED)].includes(status);
}
