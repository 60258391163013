import LOHComplianceList from '../components/LOHComplianceList';
import TMGNoQrComplianceList from '../components/TMGNoQrComplianceList';
import TMGQrComplianceList from '../components/TMGQrComplianceList';
import { ComplianceListComponent } from '../types/ComplianceList';

export enum ComplianceListIdentifier {
    LOH = 'loh',
    TMGNoQR = 'tmgNoQr',
    TMGQr = 'tmgQr',
}

const complianceListRegistry: Record<
    ComplianceListIdentifier,
    ComplianceListComponent
> = {
    [ComplianceListIdentifier.LOH]: LOHComplianceList,
    [ComplianceListIdentifier.TMGNoQR]: TMGNoQrComplianceList,
    [ComplianceListIdentifier.TMGQr]: TMGQrComplianceList,
};

export function resolveComplianceListComponentOrThrow(
    identifier: ComplianceListIdentifier,
): ComplianceListComponent {
    const Component = complianceListRegistry[identifier];

    if (!Component) {
        throw new Error(
            `No compliance list component found for identifier ${identifier}`,
        );
    }

    return Component;
}
