import { UseFormMethods } from 'react-hook-form';
import useAppConfig from '@hooks/useAppConfig';
import { resolveIdealQrOptionalConfigValue } from '@resolver/appConfigValueResolver';
import { WizardFormState } from '../../../types/wizardFormState';
import useDetermineDonorAge from './useDetermineDonorAge';

export default function useDetermineIdealQrPaymentIsRequired(
    form: UseFormMethods<WizardFormState>,
) {
    const appConfig = useAppConfig();

    const donorAge = useDetermineDonorAge(form);

    const optionalConfigValue = resolveIdealQrOptionalConfigValue(appConfig);
    if (optionalConfigValue === null) {
        return true;
    }

    if (typeof optionalConfigValue === 'boolean') {
        return !optionalConfigValue;
    }

    // At this point we know a min age value (number) for iDeal QR to be optional, is configured. If a donor age is
    // also provided we match those. If not, default to required as iDeal QR is also required when the `optional`
    // app config setting is not defined at all

    if (donorAge === null) {
        return true;
    }

    return donorAge < optionalConfigValue;
}
