import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';

type Props = {
    error: FieldError | undefined | null;
};

export const InvalidFeedback: FC<Props> = ({ error }) => {
    if (!error || error.type === 'required') {
        return null;
    }

    return (
        <div className="tw-mt-1 tw-text-sm tw-text-red tw-block" role="alert">
            {error.message}
        </div>
    );
};
