import React, { FC } from 'react';
import { FormContainer } from '@primitives/form/Form';
import DirtyFormGuard from './components/dirtyFormGuard/DirtyFormGuard';
import WizardFormFooter from './components/wizardFormFooter/WizardFormFooter';
import FormValidationErrorsOverview from './components/formValidationErrorsOverview/FormValidationErrorsOverview';
import routes from '../../../../routes';
import WizardFormLayout from './components/wizardFormLayout/WizardFormLayout';
import useHandleWizardFormState from './hooks/useHandleWizardFormState';
import ComplianceList from './components/complianceList/ComplianceList';

const WizardForm: FC = () => {
    const { form, onSubmit } = useHandleWizardFormState();

    const successPath = routes.wizard.thanks;

    return (
        <div className="container pb-5">
            <FormContainer onSubmit={onSubmit} form={form}>
                <DirtyFormGuard
                    form={form}
                    allowedPaths={[successPath]}
                    onSubmit={onSubmit}
                />

                <WizardFormLayout form={form} />
                <ComplianceList form={form} />
                <FormValidationErrorsOverview form={form} />
                <WizardFormFooter form={form} />
            </FormContainer>
        </div>
    );
};

export default WizardForm;
