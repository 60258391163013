import classNames from 'classnames';
import React from 'react';
import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import { InvalidFeedback } from '../InvalidFeedback';
import { FormGroup } from '../FormGroup';
import FormLabel from '../FormLabel';
import { formPropTypes } from '../../../propTypes';

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.label
 * @param {Object<string, string>} props.options
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string=} props.className
 * @param {boolean=} props.renderEmptyOption
 * @param {string|null=} props.defaultOption
 * @returns {React.ReactElement}
 */
export function SelectInput({
    name,
    label,
    options,
    form,
    className,
    renderEmptyOption = false,
    defaultOption = null,
}) {
    const {
        register,
        formState: { errors },
    } = form;

    return (
        <FormGroup className={classNames(className)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <select
                className={classNames('form-select', {
                    'is-invalid': errors[name],
                })}
                id={name}
                name={name}
                ref={register}
                defaultValue={defaultOption}
            >
                {renderEmptyOption && <option value="" />}
                {Object.keys(options).map((key) => (
                    <option key={key} value={key}>
                        {i18n._(options[key])}
                    </option>
                ))}
            </select>
            <InvalidFeedback error={errors[name]} />
        </FormGroup>
    );
}

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    form: formPropTypes.isRequired,
    className: PropTypes.string,
    renderEmptyOption: PropTypes.bool,
    defaultOption: PropTypes.string,
};
