import { defineMessage } from '@lingui/macro';

export default {
    email: defineMessage({
        id: 'wizard.form.personal.email',
        comment: 'label for the email input field',
        message: 'Email address',
    }),
    suggestion: defineMessage({
        id: 'wizard-form.personal.email.suggestion',
        comment:
            'Suggestion displayed when an email address is added with a possible invalid domain',
        message: 'Did you mean',
    }),
    applySuggestion: defineMessage({
        id: 'wizard-form.personal.email.apply-suggestion',
        comment:
            'Label of button to apply the suggestion displayed when an email address is added with a possible invalid domain',
        message: 'apply',
    }),
};
