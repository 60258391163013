import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { fetchSignInUser } from '@store/slice/user/thunkAction/fetchSignInUser';
import { isRejected } from '@reduxjs/toolkit';
import store from '../../../../../store';

type AuthFormValues = {
    email: string;
    password: string;
};

export default function useAuthFormState(onSuccess: () => void) {
    const form = useForm<AuthFormValues>({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: yupResolver(
            Yup.object()
                .shape({
                    email: Yup.string().required().email(),
                    password: Yup.string().required().min(1),
                })
                .required(),
        ),
    });

    const onSubmit = form.handleSubmit(async (data) => {
        const action = await store.dispatch(fetchSignInUser(data));

        if (!isRejected(action)) {
            onSuccess();
        }
    });

    return { onSubmit, ...form };
}
