export const defaultTimeoutLengthInMs = 10000;

export type RequestOptions = Parameters<typeof fetch>[1] & {
    timeoutInMs?: number;
};

export async function executeRequest(
    url: string,
    { timeoutInMs, ...options }: RequestOptions = {},
): ReturnType<typeof fetch> {
    let timeoutHandle = null;

    try {
        if (timeoutInMs) {
            const abortController = new AbortController();

            timeoutHandle = setTimeout(
                () => abortController.abort(),
                timeoutInMs,
            );

            // eslint-disable-next-line
            options.signal = abortController.signal;
        }

        return await fetch(url, options);
    } finally {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
    }
}
