/* eslint-disable jsx-a11y/label-has-associated-control */

import classNames from 'classnames';
import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import { UseFormMethods } from 'react-hook-form';
import { InvalidFeedback } from '../InvalidFeedback';
import { FormGroup } from '../FormGroup';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    label: string;
    className?: string;
    disabled?: boolean;
};

export const CheckInput: FC<Props> = ({
    form,
    name,
    label,
    className,
    disabled = false,
}) => {
    const {
        register,
        formState: { errors },
    } = form;

    return (
        <FormGroup className={classNames(className)}>
            <div className="form-check" key={name}>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    className="form-check-input"
                    disabled={disabled}
                    ref={register}
                />
                <label className="form-check-label" htmlFor={name}>
                    <Trans id={label} />
                </label>
            </div>
            <InvalidFeedback error={errors[name]} />
        </FormGroup>
    );
};
