import {
    subDays,
    addDays as dfsAddDays,
    subMinutes,
    differenceInYears as fnsDifferenceInYears,
} from 'date-fns';

/**
 * Returns 4/13/23, 12:33 PM
 *
 * @param {Date|number} timestamp
 * @returns {Date}
 */
export function formatAsLocaleDateTime(timestamp: number | Date): string {
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

    return date.toLocaleString([], { timeStyle: 'short', dateStyle: 'short' });
}

export function subtractDays(date: Date, numberOfDays: number): Date {
    return subDays(date, numberOfDays);
}

export function addDays(date: Date, numberOfDays: number): Date {
    return dfsAddDays(date, numberOfDays);
}

export function subtractMinutes(date: Date, numberOfMinutes: number): Date {
    return subMinutes(date, numberOfMinutes);
}

export function differenceInYears(first: Date, second: Date): number {
    return fnsDifferenceInYears(first, second);
}
