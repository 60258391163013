import useAppConfig from './useAppConfig';
import { AppConfigAmountPresets } from '../../../shared/src/contracts/model/appConfig';

export const defaultAmountPresetKey = '_default';

const defaultAmountPresets = {
    [defaultAmountPresetKey]: [7.5, 10, 12.5],
    bracket: [10, 15],
    highYield: [15],
};

export default function useAmountPresets(): AppConfigAmountPresets {
    const appConfig = useAppConfig();

    const isValid =
        !!appConfig.amountPresets &&
        typeof appConfig.amountPresets === 'object' &&
        Object.values(appConfig.amountPresets).length > 0;

    if (isValid) {
        return appConfig.amountPresets as AppConfigAmountPresets;
    }

    return defaultAmountPresets;
}
