import { defineMessage, t } from '@lingui/macro';

export default {
    toggleIdealQrCheckboxLabel: defineMessage({
        id: 'wizard.form.ideal.toggleIdealQrField.label',
        comment:
            'Label of form input that toggles the iDEAL QR field of the form',
        message: 'Direct iDEAL QR payment',
    }),
    ibanFieldExplanatoryText: defineMessage({
        id: 'wizard.form.ideal.ibanFormFieldExplanatoryText',
        comment: 'Explanatory text for IBAN form field input in iDeal QR form',
        message:
            'The IBAN will be automatically set when the iDeal QR payment is successful',
    }),
    divider: () =>
        t({
            id: 'wizard.form.payment.divider',
            comment:
                'text in the divider to make clear to the user they can also fill in an iban by hand',
            message: 'or fill in IBAN manually',
        }),
};
