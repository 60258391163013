import { useCallback, useEffect, useRef } from 'react';
import { i18n } from '@lingui/core';
import AutocompleteAddress from '@lib/postcode/AutocompleteAddress-1.2.3';

const options = {
    autocompleteUrl: '/api/v1/postcode/autocomplete',
    addressDetailsUrl: '/api/v1/postcode/address',
    language: 'nl-NL',
    context: 'bel',
};

const countryCodeContextMap = {
    NL: 'nld',
    BE: 'bel',
    DE: 'deu',
};

export default function usePostcodeAutocompleteRef(
    countryCode,
    onAddressSelect,
) {
    const inputRef = useRef();
    const libraryRef = useRef();

    const onSelect = useCallback(
        (event) => {
            if (event.detail.precision !== 'Address') return;

            libraryRef.current.getDetails(event.detail.context, (result) => {
                onAddressSelect(result.address);
                libraryRef.current.reset();
            });
        },
        [onAddressSelect],
    );

    useEffect(() => {
        const library = new AutocompleteAddress(inputRef.current, {
            ...options,
            language: i18n.locale,
            context: countryCodeContextMap[countryCode],
        });

        inputRef.current.addEventListener('autocomplete-select', onSelect);
        libraryRef.current = library;

        return () => library.destroy();
    }, [countryCode, onSelect]);

    return inputRef;
}
