import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './tailwind.css';
import * as gtag from '@integrations/analytics/gtag';
import { initializeErrorCapturing } from '@utilities/errorCapturingUtilities';
import version from './version.json';
import I18nProvider from './I18nProvider';
import registerServiceWorker from './registerServiceWorker';
import '@integrations/auth/getFirebaseAuth';
import ErrorBoundary from './features/errorBoundary/ErrorBoundary';
import App from './App';

initializeErrorCapturing();

ReactDOM.render(
    <React.StrictMode>
        <I18nProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </I18nProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'development') {
    registerServiceWorker();
    gtag.configure('G-35QBNDY0J8', version);
}
