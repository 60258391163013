import React, { FC, ReactElement, useMemo } from 'react';
import { TextInput, RadioInput } from '@primitives/form/Form';
import DateTextInput from '@primitives/form/components/DateTextInput';
import useAppConfig from '@hooks/useAppConfig';
import { UseFormMethods } from 'react-hook-form';
import { resolveIsSocialSecurityNumberFieldEnabled } from '@resolver/appConfigValueResolver';
import { useSexOptions } from '../../constants/options';
import labels from './WizardFormPersonal.trans';
import InitialsTextInput from '../initialsTextInput/InitialsTextInput';
import EmailFormInputWithDomainSuggestion from '../emailFormInputWithDomainSuggestion/EmailFormInputWithDomainSuggestion';
import PhoneTextInput from '../phoneTextInput/PhoneTextInput';
import { getLanguageOptionsFromCountryCodes } from './resolver/languageOptionsResolver';
import WizardFormPersonalOptInsFormSection from '../wizardFormPersonalOptInsFormSection/WizardFormPersonalOptInsFormSection';
import SurnameInputWithSplitToPrefixSuggestion from '../surnameInputWithSplitToPrefixSuggestion/SurnameInputWithSplitToPrefixSuggestion';

type Props = {
    hideOptIns?: boolean;
    form: UseFormMethods<any>;
};

const WizardFormPersonal: FC<Props> = ({
    hideOptIns = false,
    form,
}): ReactElement => {
    const appConfig = useAppConfig();

    const showSocialSecurityNumberField =
        resolveIsSocialSecurityNumberFieldEnabled(appConfig);

    const sexOptions = useSexOptions(
        appConfig.otherSexEnabled,
        appConfig.optionalGenderEnabled,
    );
    const languageOptions = useMemo(
        () =>
            getLanguageOptionsFromCountryCodes(
                appConfig?.languagePreferenceOptions,
            ),
        [appConfig?.languagePreferenceOptions],
    );

    return (
        <WizardFormPersonalOptInsFormSection
            form={form}
            hideOptIns={hideOptIns}
        >
            <TextInput
                className="col-8 col-md-3"
                name="firstname"
                formatter="firstcase"
                label={labels.firstName.id}
                form={form}
            />
            <InitialsTextInput
                className="col-4 col-md-2"
                label={labels.initials.id as string}
                form={form}
            />
            <TextInput
                className="col-4 col-md-2"
                name="surnamePrefix"
                formatter="lowercase"
                label={labels.surnamePrefix.id}
                form={form}
            />
            <SurnameInputWithSplitToPrefixSuggestion
                className="col-8 col-md-5"
                form={form}
            />
            <DateTextInput
                className="col-sm-6"
                name="dateOfBirth"
                label={labels.dateOfBirth.id}
                form={form}
            />
            {showSocialSecurityNumberField && (
                <TextInput
                    className="col-sm-6"
                    name="socialSecurityNumber"
                    label={labels.ssnInputLabel.id}
                    form={form}
                />
            )}
            <RadioInput
                className="col-sm-6"
                name="sex"
                options={sexOptions}
                inline
                label={labels.sex.id}
                form={form}
            />
            <PhoneTextInput
                className="col-sm-6"
                name="mobilePhoneNumber"
                label={labels.mobilePhoneNumber.id}
                form={form}
            />
            <PhoneTextInput
                className="col-sm-6"
                name="phoneNumber"
                label={labels.phoneNumber.id}
                form={form}
            />
            <EmailFormInputWithDomainSuggestion
                className="col-sm-6"
                form={form}
            />
            <RadioInput
                className="col-sm-6"
                name="language"
                options={languageOptions}
                inline
                label={labels.languagePreference.id}
                form={form}
            />
        </WizardFormPersonalOptInsFormSection>
    );
};

export default WizardFormPersonal;
