/* eslint-disable react/no-children-prop */

import React from 'react';
import PropTypes from 'prop-types';
import { getFilePath } from '@integrations/service/api';
import useAppConfig from '@hooks/useAppConfig';
import useActiveCampaign from '@hooks/useActiveCampaign';

export default function ThemeStyle() {
    const appConfig = useAppConfig();
    const campaign = useActiveCampaign(true);

    return (
        <style
            children={`
      .theme-splash {
        background-image: url(${getFilePath(
            campaign.id,
            appConfig.splashImage,
        )});
      }
      .theme-thanks {
        background-image: url(${getFilePath(
            campaign.id,
            appConfig.thanksImage || appConfig.splashImage,
        )});
      }
      .theme-primary {
        color: ${appConfig.primaryColor};
      }
      .theme-btn {
        background: ${appConfig.primaryColor};
        border-color: transparent;
        color: white;
      }
    `}
        />
    );
}

/**
 * @param {object} props
 * @param {string|React.ReactNode=} props.text
 * @returns {React.ReactNode}
 */
export function ThemeText({ text }) {
    if (typeof text === 'string') {
        const stripped = text.replace(/<[^>]*>?/gm, '');
        const formatted = stripped.replace(
            /\*(.*?)\*/g,
            '<span class="theme-primary">$1</span>',
        );

        // eslint-disable-next-line react/no-danger
        return <span dangerouslySetInnerHTML={{ __html: formatted }} />;
    }

    return text;
}

ThemeText.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired, // = <Trans />
    ]),
};
