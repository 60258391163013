import React from 'react';
import { t, Trans } from '@lingui/macro';

export const getConfirmationModalLabels = () => ({
    modalTitle: (
        <Trans
            id="modal.confirmation.modalTitle"
            comment="Title for a modal that is shown when the user wants to close a page that has unsubmitted data on it"
        >
            Warning
        </Trans>
    ),
    cancel: (
        <Trans
            id="button.cancel"
            comment="button that cancels the modal's action and keeps the user on the same page"
        >
            Cancel
        </Trans>
    ),
    sendAsOneTime: (
        <Trans
            id="button.sendAsOneTime"
            comment="button that submits the current form as a one-time donation if idealQR has a successful payment"
        >
            Send as one-time
        </Trans>
    ),
});

export const getConfirmationMessage = () =>
    t({
        id: 'modal.confirmation.modalMessage',
        comment:
            'Message that is shown in the modal, warning the user that data will be lost if they close the page',
        message:
            'There is data in the form that has not been submitted yet. Are you sure you want to close the page?',
    });

export const getIdealConfirmationMessage = () =>
    t({
        id: 'modal.confirmation.modalIdealMessage',
        comment:
            'Message that is shown in the modal, letting the user know their form will be submitted as ideal has already been paid',
        message:
            'An iDEAL payment has already been completed. This form will be submitted as a one-time donation if you close this form. Are you sure you want to close the page?',
    });
