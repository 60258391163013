import { t } from '@lingui/macro';

export const getLocalizedErrorMessages = () => ({
    unknown: t({
        id: 'store.api.error.unknown',
        comment: 'Error that is shown for an unknown error code',
        message: 'Unknown error.',
    }),
    unauthorized: t({
        id: 'store.api.error.unauthorized',
        comment:
            'Error that is shown when a user has to log in to perform a certain action',
        message: 'Authentication required, please log in.',
    }),
    invalidCredentials: t({
        id: 'store.api.error.invalidCredentials',
        comment:
            'Error that is shown when the user provides the wrong credentials whilst logging in',
        message: 'Email address and/or password do not match.',
    }),
    forbidden: t({
        id: 'store.api.error.forbiddenAccess',
        comment:
            'Error that is shown when the user does not meet the required criteria to recieve access',
        message:
            'Access denied, check if your contract has been signed and/or the appropriate role has been set in backstage.',
    }),
    serverError: t({
        id: 'store.api.error.serverError',
        comment:
            'Error that is shown when an internal server error has occurred',
        message:
            'An internal server error has occurred, please try again later.',
    }),
    clientError: t({
        id: 'store.api.error.clientError',
        comment:
            'Error that is shown when an internal server error has occurred',
        message:
            'An internal client error has occurred, please try again later.',
    }),
    cantValidate: t({
        id: 'store.api.error.cantValidate',
        comment: 'Error that is shown when a remote validation request failed.',
        message: 'Cannot validate',
    }),
    cantReachServer: t({
        id: 'store.api.error.cantReachServer',
        comment: "Error that is shown when the server can't be reached",
        message: 'Cannot reach server, please try again later.',
    }),
    cantParseResponse: t({
        id: 'store.api.error.cantParseResponse',
        comment:
            'Error that is shown when the response from the server is unusable',
        message:
            'Cannot interpret response from server, please try again later.',
    }),
});
