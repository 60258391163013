import React from 'react';
import PropTypes from 'prop-types';
import useIsOnline from '@hooks/useIsOnline';
import usePostcodeAutocompleteRef from '../hooks/usePostcodeAutocompleteRef';
import './PostcodeAutocompleteInput.scss';

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string=} props.placeholder
 * @param {string} props.countryCode
 * @param {Function} props.onAddressSelect
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
function PostcodeAutocompleteInput({
    name,
    placeholder,
    countryCode,
    onAddressSelect,
    className = 'form-control',
}) {
    const online = useIsOnline();
    const inputRef = usePostcodeAutocompleteRef(countryCode, onAddressSelect);
    return (
        <>
            <input
                placeholder={placeholder}
                className={className}
                type="text"
                id={name}
                name={name}
                ref={inputRef}
                disabled={!online}
            />
            <span className="spinner-grow text-muted spinner-grow-sm d-none" />
        </>
    );
}

PostcodeAutocompleteInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    onAddressSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default PostcodeAutocompleteInput;
