import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import ConfirmationModal from './confirmationModal/ConfirmationModal';

/**
 * This component is used to block navigation when the user has not confirmed the navigation.
 *
 * @todo Rewrite using function instead of class based to match rest of application
 * @param {boolean} when - When should shouldBlockNavigation be invoked, simply passing a boolean (same as "when" prop of Prompt of React-Router)
 * @param {Function} navigate - Navigate function
 * @param {Function} shouldBlockNavigation - Use as "message" prop of Prompt of React-Router
 * @param {Function} onIdealQrPaymentSuccess - Callback function to be executed when the user confirms the navigation
 */
class RouteLeavingGuard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
        };
    }

    showModal = (location) => {
        this.setState({
            modalVisible: true,
            lastLocation: location,
        });
    };

    closeModal = (callback) => {
        this.setState(
            {
                modalVisible: false,
            },
            callback,
        );
    };

    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;

        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);

            return false;
        }

        return true;
    };

    handleConfirmNavigationClick = (hasSuccessfulTransaction = false) => {
        this.closeModal(() => {
            const { navigate, onIdealQrPaymentSuccess } = this.props;
            const { lastLocation } = this.state;

            if (lastLocation) {
                this.setState(
                    {
                        confirmedNavigation: true,
                    },
                    async () => {
                        let confirmed = true;

                        this.setState({ confirmedNavigation: false });

                        if (hasSuccessfulTransaction) {
                            // When the iDEAL QR transaction succeeded, turn it into a one-time payment
                            confirmed = await onIdealQrPaymentSuccess();
                        }

                        if (confirmed) {
                            // Navigate to the previous blocked location and continue as usual
                            navigate(lastLocation.pathname);
                        }
                    },
                );
            }
        });
    };

    render() {
        const { when } = this.props;
        const { modalVisible } = this.state;

        return (
            <>
                <Prompt when={when} message={this.handleBlockedNavigation} />
                {modalVisible && (
                    <ConfirmationModal
                        onCancel={this.closeModal}
                        onConfirm={this.handleConfirmNavigationClick}
                    />
                )}
            </>
        );
    }
}

RouteLeavingGuard.propTypes = {
    when: PropTypes.bool.isRequired,
    shouldBlockNavigation: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    onIdealQrPaymentSuccess: PropTypes.func.isRequired,
};

export default RouteLeavingGuard;
