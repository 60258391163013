import { useEffect, useState } from 'react';
import { getLandingPageConfig } from '@integrations/service/api';

type State = {
    brandImage: string | null;
    splashImage: string | null;
    websiteUrl: string | null;
};

export default function useFetchLandingPageConfig(campaignId: string): State {
    const [state, setState] = useState<State>({
        brandImage: null,
        splashImage: null,
        websiteUrl: null,
    });

    useEffect(() => {
        async function fetchLandingPageConfig() {
            const response = await getLandingPageConfig(campaignId);

            setState(response);
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchLandingPageConfig();
    }, [campaignId]);

    return state;
}
