export function asCurrencyFormat(value) {
    const number = parseFloat(value);
    return number && `€ ${number.toFixed(2).replace('.', ',')}`;
}

export function asCurrencyFormatMap(options) {
    return options.reduce(
        (map, option) => map.set(option, asCurrencyFormat(option)),
        new Map(),
    );
}

export function asCurrencyFormatMapWithLabelAddition(options, labels) {
    return options.reduce(
        (map, option, currentIndex) =>
            map.set(option, labels(asCurrencyFormat(option), currentIndex + 1)),
        new Map(),
    );
}
