/* eslint-disable react/button-has-type */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import createClassName from 'classnames';
import childrenWithProps from './utilities/childrenWithProps';
import './Button.scss';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string=} props.className
 * @param {boolean=} props.spinner
 * @param {boolean=} props.disabled
 * @param {boolean=} props.deflated
 * @param {'submit'|'reset'|'button'} props.type
 * @param {Function=} props.onClick
 * @param {string=} props.title
 * @returns {React.ReactElement}
 */
function Button({
    children,
    className: additionalClassName = 'btn-light',
    spinner = false,
    disabled = false,
    deflated = false,
    type,
    onClick,
    title,
}) {
    const className = createClassName('btn', additionalClassName, {
        'button-deflated': deflated,
    });

    return (
        <button
            className={className}
            disabled={spinner || disabled}
            type={type}
            onClick={onClick}
            title={title}
        >
            {spinner && (
                <span
                    className="spinner-grow spinner-grow-sm"
                    aria-busy={spinner}
                    role="alert"
                />
            )}
            <span className="d-flex align-items-center">{children}</span>
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    spinner: PropTypes.bool,
    disabled: PropTypes.bool,
    deflated: PropTypes.bool,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    onClick: PropTypes.func, // is not supplied in case of a submit button
    title: PropTypes.string,
};

export default Button;

/**
 * @param {object} props
 * @param {string|React.ReactNode=} props.label
 * @param {React.ReactNode[]} props.children
 * @param {Function} props.onChildClick
 * @param {boolean=} props.disabled
 * @returns {React.ReactElement}
 */
export function DropdownButton({
    label,
    children,
    onChildClick,
    disabled = false,
}) {
    const node = useRef();
    const [show, setShow] = useState(false);

    const onDocumentClick = (event) => {
        if (!node.current?.contains(event.target)) {
            setShow(false);
        }
    };

    const onClick = (event) => {
        event.preventDefault();
        onChildClick(event);
        setShow(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', onDocumentClick);
        return () => document.removeEventListener('mousedown', onDocumentClick);
    });

    return (
        <div className="btn-group" ref={node}>
            <Button
                type="button"
                onClick={() => setShow(!show)}
                disabled={disabled}
            >
                {label}
                <span
                    className="chevron"
                    style={{ marginLeft: '6px', marginTop: '6px' }}
                >
                    <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1.5L6 6.5L11 1.5"
                            stroke="#344054"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
            </Button>
            <div className={createClassName('dropdown-menu', { show })}>
                {childrenWithProps({ children, onClick }, 'string')}
            </div>
        </div>
    );
}

DropdownButton.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired, // = <Trans />
    ]).isRequired,
    children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
    onChildClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
