import Interval from '@constants/interval';

const complianceTextPerIntervalResolver = (interval: Interval): string => {
    const intervalTextMap: Record<Interval, string> = {
        [Interval.yearly]: 'een bedrag per jaar, tot wederopzegging.',
        [Interval.halfYearly]: 'een bedrag per half jaar, tot wederopzegging.',
        [Interval.quarterly]: 'een bedrag per kwartaal, tot wederopzegging.',
        [Interval.monthly]: 'een bedrag per maand, tot wederopzegging.',
        [Interval.oneTime]: 'een eenmalige donatie.',
    };

    return intervalTextMap[interval];
};

export default complianceTextPerIntervalResolver;
