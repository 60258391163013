/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const updatePreferencesReducer: CaseReducer<
    UserState,
    PayloadAction<Partial<UserState['preferences']>>
> = (state, action) => {
    const newState = {
        ...state.preferences,
        ...action.payload,
    };

    captureBreadcrumb(
        'User preferences updated',
        'info',
        'preferences',
        newState,
    );

    state.preferences = newState;
};
