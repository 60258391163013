export default function initialsFormatter(
    value: string,
    addTrailingDot: boolean,
): string {
    if (value.length <= 0) {
        return '';
    }

    return (
        value
            .toUpperCase()
            .split('')
            .filter((c) => c !== '.')
            .join('.') + (addTrailingDot ? '.' : '')
    );
}
