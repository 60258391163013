import React from 'react';
import { Trans } from '@lingui/macro';

export const getAuthFormLabels = () => ({
    emailaddress: (
        <Trans
            id="auth.label.emailaddress"
            comment="label for the emailaddress input field"
        >
            Email address
        </Trans>
    ),
    password: (
        <Trans
            id="auth.label.password"
            comment="label for the password input field"
        >
            Password
        </Trans>
    ),
    login: (
        <Trans id="button.login" comment="Button text to log into account">
            Log in
        </Trans>
    ),
    cancel: (
        <Trans
            id="button.cancel"
            comment="Button to return to the homescreen and exit the login screen without logging in"
        >
            Cancel
        </Trans>
    ),
});
