import React from 'react';
import { Trans } from '@lingui/macro';
import { RadioInput } from '@primitives/form/Form';
import useActiveCampaign from '@hooks/useActiveCampaign';
import labels from './WizardFormCustom.trans';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement[]}
 */
function WizardFormCustom({ form }) {
    const campaign = useActiveCampaign(true);

    const customFields = [];

    if (campaign.categories) {
        const options = Object.keys(campaign.categories).reduce((acc, key) => {
            const value = campaign.categories[key];

            if (value instanceof Object) {
                acc[key] = value.name;
            } else {
                acc[key] = value;
            }

            return acc;
        }, {});

        customFields.push({
            label: <Trans id={labels.categories.id} />,
            name: 'categories',
            options,
        });
    }

    return customFields.map((field) => (
        <RadioInput
            form={form}
            inline
            name={field.name}
            key={field.name}
            label={field.label}
            options={field.options}
            defaultValue={Object.keys(field.options)[0]}
        />
    ));
}

WizardFormCustom.propTypes = {
    form: formPropTypes.isRequired,
};

export default WizardFormCustom;
