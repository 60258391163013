import { t } from '@lingui/macro';

// This needs to be a method and called after page load, otherwise the current language context
// is not clear yet and always the default message will be returned.
export const getRequiredTranslation = () =>
    t({
        id: 'wizard.form.error.requiredField',
        comment: 'Error indicating that the input is required',
        message: 'Required field.',
    });

export const yupLocale = {
    mixed: {
        required: getRequiredTranslation,
        notType: () =>
            t({
                id: 'wizard.form.error.invalidValue',
                comment: 'Error indicating that the value is invalid',
                message: 'Invalid Value.',
            }),
        default: () =>
            t({
                id: 'wizard.form.error.invalidValue',
                comment: 'Error indicating that the value is invalid',
                message: 'Invalid Value.',
            }),
    },
    string: {
        email: () =>
            t({
                id: 'wizard.form.error.notValidEmail',
                comment: 'Error indicating a non valid email address',
                message: 'Not a valid email address.',
            }),
        matches: () =>
            t({
                id: 'wizard.form.error.invalidCharacters',
                comment: 'Error indicating field contains invalid characters',
                message: 'These characters are not allowed.',
            }),
        min: ({ min }) =>
            t({
                id: 'wizard.form.error.minCharacters',
                comment:
                    'Error indicating the minimum amount of characters was not reached',
                message: `Minimum of ${min} characters.`,
            }),
        max: ({ max }) =>
            t({
                id: 'wizard.form.error.maxCharacters',
                comment:
                    'Error indicating the maximum amount of characters was reached',
                message: `Maximum of ${max} characters.`,
            }),
    },
};

export const yupMethods = {
    minAge: ({ minAge }) =>
        t({
            id: 'wizard.form.error.minimumAge',
            comment:
                'Error indicating minimum age has not been reached by the user',
            message: `Minimum required age is ${minAge} years old.`,
        }),
    maxAge: ({ maxAge }) =>
        t({
            id: 'wizard.form.error.maximumAge',
            comment:
                'Error indicating maximum age has been reached by the user',
            message: `Maximum age is ${maxAge} years old.`,
        }),
    phoneNumber: () =>
        t({
            id: 'wizard.form.error.invalidPhoneNumber',
            comment: 'Error indicating phone number is not valid',
            message: 'Not a valid phone number.',
        }),
    currencyInRange: ({ minAmountAlternative, maxAmountAlternative }) =>
        t({
            id: 'wizard.form.error.amountNotInRange',
            comment: 'Error indicating the entered amount is not in range',
            message: `Amount should be between ${minAmountAlternative} and ${maxAmountAlternative}.`,
        }),
    iban: () =>
        t({
            id: 'wizard.form.error.invalidIBAN',
            comment: 'Error indicating IBAN is not valid',
            message: 'Not a valid IBAN.',
        }),
    matchesDateFormat: () =>
        t({
            id: 'wizard.form.error.dateFormatError',
            comment:
                'Error indicating that the value is not a valid date format',
            message: 'Not a valid date.',
        }),
    invalidDataUrl: () =>
        t({
            id: 'wizard.form.error.invalidDataUrl',
            comment:
                'Error message shown when data of signature cannot be saved',
            message:
                'This data is corrupted, please try again or use another method.',
        }),
    invalidCountryCode: () =>
        t({
            id: 'wizard.form.error.countryCode',
            comment: 'An invalid country code was entered.',
            message: 'This is not a valid 2-letter country code.',
        }),
};
