/* eslint-disable no-param-reassign */

import { CaseReducer } from '@reduxjs/toolkit';
import { IdealQrState } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const resetReducer: CaseReducer<IdealQrState> = (state) => {
    captureBreadcrumb('Ideal QR state reset', 'info', 'idealqr', {
        codeId: state.codeId,
        status: state.status,
    });

    state.isGenerating = false;
    state.hasGenerationError = false;
    state.codeId = null;
    state.codeUrl = null;
    state.isPolling = false;
    state.hasStatusError = false;
    state.status = null;
    state.amount = null;
};
