import useAppConfig from '@hooks/useAppConfig';
import useIdealQrState from '@hooks/useIdealQrState';
import { resolveIdealQrIsEnabled } from '@resolver/appConfigValueResolver';
import { IdealQrStatus } from '@constants/idealQrStatus';

export default function useHasSuccessfulTransaction() {
    const appConfig = useAppConfig();
    const { status } = useIdealQrState();
    const idealQrEnabled = resolveIdealQrIsEnabled(appConfig);

    return idealQrEnabled && status === IdealQrStatus.SUCCESS;
}
