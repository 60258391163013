/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { resolveDefaultCampaign } from '@store/slice/tenant/utilities/stateResolver';
import { createCombinedAppConfig } from '@store/slice/tenant/utilities/stateMutationUtilities';
import { FetchTenantPayload, TenantState } from '../types';
import {
    captureBreadcrumb,
    captureReduxError,
} from '../../../../utilities/errorCapturingUtilities';

export const fetchTenantPendingReducer: CaseReducer<TenantState> = (state) => {
    state.result.pending = true;
    state.result.errorMessage = null;
};

export const fetchTenantFulfilledReducer: CaseReducer<
    TenantState,
    PayloadAction<FetchTenantPayload>
> = (state, action) => {
    const { appConfig, name, campaigns } = action.payload;

    captureBreadcrumb(
        'fetch tenant and campaigns fulfilled',
        'info',
        'campaign',
    );

    // Reset fetching state
    state.result.pending = false;
    state.result.lastSuccess = new Date().getTime();
    state.result.errorMessage = null;

    // Set tenant info
    state.appConfig = appConfig;
    state.name = name;

    // Determine campaigns + the active campaign and prepare app config for it
    let activeCampaign =
        (state.campaigns?.active?.id && campaigns[state.campaigns.active.id]) ||
        resolveDefaultCampaign(appConfig, campaigns);
    if (activeCampaign) {
        activeCampaign = {
            ...activeCampaign,
            appConfig: createCombinedAppConfig(
                appConfig,
                activeCampaign.appConfig || {},
            ),
        };
    }
    state.campaigns = {
        list: campaigns,
        active: activeCampaign,
    };
};

export const fetchTenantRejectedReducer: CaseReducer<
    TenantState,
    PayloadAction<
        void,
        string,
        {
            updateOnly?: boolean;
            [key: string]: any;
        },
        Error
    >
> = (state, action) => {
    state.result.pending = false;

    const updateOnly = !!action.meta.arg;

    captureBreadcrumb(
        'fetch tenant and campaigns failed',
        'warning',
        'campaign',
        {
            updateOnly: action.meta.arg,
            error: action.error,
        },
    );
    captureReduxError(action.error, 'warning');

    state.result.pending = false;
    state.result.errorMessage = !updateOnly ? action.error.message : null;
};
