import { useEffect } from 'react';
import { setAmountIfAllowed } from '@store/slice/idealQr/idealQrSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import determineMandateAmount from '../../../../../resolver/mandateAmountResolver';

/**
 * @param {Function} watch
 */
export default function useManageIdealQrAmount(watch) {
    const mandateAmount = determineMandateAmount(
        watch('amount'),
        watch('amountAlternative'),
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setAmountIfAllowed(mandateAmount));
    }, [dispatch, mandateAmount]);
}
