import { useEffect } from 'react';
import useDetermineDefaultValues from '../../../hooks/useDetermineDefaultValues';

/**
 * @param {string} type
 * @param {import('react-hook-form').UseFormMethods} form
 * @param {string} payloadName
 */
export default function useResetSignatureFieldWhenSignatureTypeChanges(
    type,
    form,
    payloadName,
) {
    const { setValue, clearErrors } = form;

    const defaultValues = useDetermineDefaultValues();

    useEffect(() => {
        clearErrors(payloadName);
        setValue(payloadName, defaultValues.signaturePayload);
    }, [
        clearErrors,
        defaultValues.signaturePayload,
        payloadName,
        setValue,
        type,
    ]);
}
