import React, { FC } from 'react';
import classNames from 'classnames';
import '../../../signatureFormRow/components/signatureCanvas/SignatureCanvas.scss';
import { UseFormMethods } from 'react-hook-form';
import { createIdealQrCharge } from '@store/slice/idealQr/thunkAction/createIdealQrCharge';
import { isFinalStatus } from '@constants/idealQrStatus';
import { asCurrencyFormat } from '@formatter/asCurrencyFormat';
import { FormGroup } from '@primitives/form/FormGroup';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { useAppDispatch } from '@hooks/useAppDispatch';
import Interval from '@constants/interval';
import useIsOnline from '@hooks/useIsOnline';
import useIdealQrState from '@hooks/useIdealQrState';
import useActiveCampaign from '@hooks/useActiveCampaign';
import useIsCommercialCampaign from '@hooks/useIsCommercialCampaign';
import T, { toHumanReadableStatus } from './IdealQr.trans';
import './IdealQr.scss';
import SuccessView from './components/SuccessView';
import RetrieveStatusButton from './components/RetrieveStatusButton';
import useCheckIdealQrAmountIsValid from './hooks/useCheckIdealQrAmountIsValid';
import useHasValidPersonalAndContactData from './hooks/useHasValidPersonalAndContactData';
import RequestCodeButton from './components/RequestCodeButton';
import useManageIdealQrAmount from './hooks/useManageIdealQrAmount';
import useFetchIdealQrStatus from './hooks/useFetchIdealQrStatus';
import {
    determineIntroductionMessage,
    determinePreparationMessage,
} from './resolver/idealQrMessageResolver';
import useScrollToFirstFormError from './hooks/useScrollToFirstFormError';
import { WizardFormState } from '../../../../types/wizardFormState';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

const IdealQr: FC<Props> = ({ form }) => {
    const {
        watch,
        setValue,
        setError,
        clearErrors,
        getValues,
        register,
        formState: { errors },
    } = form;

    const campaign = useActiveCampaign(true);

    // To make sure that we can, if needed, manually create an application form / pledge case, when
    // an iDeal QR payment was successful but the form was not submitted, we need specific data
    // to be available already before allowing to start the iDeal QR payment process.
    const hasPersonalAndContactData = useHasValidPersonalAndContactData(form);

    const isOnline = useIsOnline();
    const isCommercialCampaign = useIsCommercialCampaign();

    useManageIdealQrAmount(watch);

    const dispatch = useAppDispatch();

    const {
        isGenerating,
        hasGenerationError,
        hasStatusError,
        codeId,
        codeUrl,
        isPolling,
        status,
        amount: idealQrAmount,
    } = useIdealQrState();

    const interval = watch('interval');

    const idealQrAmountIsValid = useCheckIdealQrAmountIsValid(idealQrAmount);

    useScrollToFirstFormError(hasPersonalAndContactData.errors, errors);

    const onRequestCodeButtonClick = () => {
        if (hasPersonalAndContactData.valid) {
            clearErrors();
            dispatch(
                createIdealQrCharge({
                    campaignId: campaign.id,
                    formData: getValues(),
                }),
            );
        } else {
            hasPersonalAndContactData.errors.forEach((error) => {
                setError(error.path, {
                    type: 'manual',
                    message: error.message,
                });
            });
        }
    };

    const fetchPaymentStatus = useFetchIdealQrStatus(setValue, campaign.id);

    const offlineFlow = !isOnline && !isFinalStatus(status);

    const preparationMessage = determinePreparationMessage(
        codeId,
        idealQrAmountIsValid,
        hasPersonalAndContactData.valid,
    );

    const introductionMessage = determineIntroductionMessage(
        isGenerating,
        codeId,
        status,
        hasGenerationError,
        hasStatusError,
        isPolling,
        offlineFlow,
    );

    const headerTextParameters = {
        amount: idealQrAmount ? asCurrencyFormat(idealQrAmount) : '?',
        to: campaign.appConfig?.idealQr?.beneficiary ?? campaign.name,
    };

    return (
        <FormGroup className="col-12">
            <input type="hidden" name="iban" ref={register} />
            <div className="mb-2">{`${T.title()}`}</div>
            <div
                className={classNames('ideal-qr mb-2', {
                    'border-danger': errors.iban,
                })}
            >
                {isCommercialCampaign ? (
                    <div className="ideal-qr-header">
                        {interval !== Interval.oneTime
                            ? T.recurringPurchaseHeader(headerTextParameters)
                            : T.oneTimePurchaseHeader(headerTextParameters)}
                    </div>
                ) : (
                    <div className="ideal-qr-header">
                        {interval !== Interval.oneTime
                            ? T.recurringDonationHeader(headerTextParameters)
                            : T.oneTimeDonationHeader(headerTextParameters)}
                    </div>
                )}
                <div className="ideal-qr-container">
                    {codeUrl ? (
                        <>
                            {isFinalStatus(status) ? (
                                <SuccessView iban={watch('iban') as string} />
                            ) : (
                                <img
                                    src={codeUrl}
                                    alt="iDEAL QR Code"
                                    draggable="false"
                                />
                            )}
                        </>
                    ) : (
                        <RequestCodeButton
                            disabled={
                                offlineFlow ||
                                !idealQrAmount ||
                                !idealQrAmountIsValid ||
                                !hasPersonalAndContactData.valid
                            }
                            clickable={
                                // Even though we display the request code button as disabled, we still want it to be clickable sometimes, as we need a trigger to start the validation process.
                                !offlineFlow &&
                                !!idealQrAmount &&
                                idealQrAmountIsValid
                            }
                            onClick={onRequestCodeButtonClick}
                            isGenerating={isGenerating}
                        />
                    )}
                </div>

                {preparationMessage && (
                    <div className="text-danger ideal-qr-footer">
                        {preparationMessage}
                    </div>
                )}

                <div
                    className={classNames('ideal-qr-footer', {
                        'text-danger':
                            hasGenerationError || hasStatusError || offlineFlow,
                    })}
                >
                    {introductionMessage}
                </div>

                {status && (
                    <p className="ideal-qr-status">
                        <strong>Status:</strong>{' '}
                        {status ? toHumanReadableStatus(status) : ''}
                    </p>
                )}
            </div>
            {codeId && status && !isFinalStatus(status) && (
                <RetrieveStatusButton
                    onClick={fetchPaymentStatus}
                    disabled={isFinalStatus(status) || offlineFlow}
                    loading={isPolling}
                />
            )}
            <InvalidFeedback error={errors.iban} />
        </FormGroup>
    );
};

export default IdealQr;
