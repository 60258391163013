import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCampaigns, getTenant } from '@integrations/service/api';
import precacheCampaignFiles from '@store/slice/tenant/utilities/precacheCampaignFiles';
import transformToMap from '@store/slice/tenant/utilities/transformToMap';
import { SliceNamespace } from '@store/constants/namespace';
import type { RootState } from '@store/index';
import { FetchTenantPayload } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

const minUpdateInterval = 30 * 60 * 1000; // 30 minutes

export const fetchTenant = createAsyncThunk<
    FetchTenantPayload,
    boolean | undefined
>(
    `${SliceNamespace.Tenant}/fetch`,
    async () => {
        captureBreadcrumb('fetch tenant', 'info', 'campaign');
        const tenant = await getTenant();

        captureBreadcrumb('fetch campaigns', 'info', 'campaign');
        const campaigns = await getCampaigns();

        precacheCampaignFiles(campaigns);

        return {
            ...tenant,
            campaigns: transformToMap(campaigns, 'id'),
        };
    },
    {
        condition: (updateOnly = false, { getState }) => {
            const state = getState() as RootState;

            const { result } = state[SliceNamespace.Tenant];

            if (result?.pending === true) {
                return false;
            }

            const now = new Date().getTime();

            // Only update once every 30 minutes, to reduce API load
            // noinspection RedundantIfStatementJS
            if (
                updateOnly &&
                now - (result?.lastSuccess || 0) < minUpdateInterval
            ) {
                return false;
            }

            return true;
        },
    },
);
