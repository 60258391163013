import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import postEntryWithSignature from '@integrations/service/postEntryWithSignature';
import type { RootState } from '../../../index';
import { SliceNamespace } from '../../../constants/namespace';
import { PushEntryResponse } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const fetchPushEntry: AsyncThunk<
    PushEntryResponse,
    string,
    { state: RootState }
> = createAsyncThunk<PushEntryResponse, string, { state: RootState }>(
    `${SliceNamespace.Buffer}/fetch/push`,
    async (timestamp, { getState }) => {
        const currentState = getState();
        let response;

        const entry = currentState.buffer.entries[timestamp];
        try {
            response = await postEntryWithSignature(entry);
        } catch (error) {
            console.log(error);
            throw error;
        }

        return {
            timestamp,
            id: response.id,
        };
    },
    {
        condition: (timestamp, { getState }) => {
            const state = getState() as RootState;

            const { result, entries } = state[SliceNamespace.Buffer];

            if (result?.pending === true) {
                return false;
            }

            const entry = entries[timestamp];
            if (!entry) {
                captureBreadcrumb(
                    'pushing entry that does not exist',
                    'warning',
                    'submit',
                    { timestamp },
                );

                return false;
            }

            return true;
        },
    },
);
