import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { getIdealQrChargeStatus } from '@integrations/service/api';
import type { AppDispatch, RootState } from '../../../index';
import {
    validateAmountIsAvailable,
    validateStateContainsCodeReference,
} from '../utilities/idealQrStateValidators';
import { SliceNamespace } from '../../../constants/namespace';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

export const fetchIdealQrPaymentStatus: AsyncThunk<
    Awaited<ReturnType<typeof getIdealQrChargeStatus>>,
    string,
    {
        state: RootState;
    }
> = createAsyncThunk<
    Awaited<ReturnType<typeof getIdealQrChargeStatus>>,
    string,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    `${SliceNamespace.IdealQr}/fetch-payment-status`,

    async (campaignId, { getState }) => {
        const currentState = getState();

        validateAmountIsAvailable(currentState[SliceNamespace.IdealQr]);
        validateStateContainsCodeReference(
            currentState[SliceNamespace.IdealQr],
        );

        const codeId = getState()?.[SliceNamespace.IdealQr]?.codeId;
        if (!codeId) {
            throw new Error('Expecting codeId to be available at this point');
        }

        captureBreadcrumb(
            'Ideal QR payment status fetched',
            'info',
            'idealqr',
            { campaignId, codeId },
        );

        return getIdealQrChargeStatus(campaignId, codeId);
    },
);
