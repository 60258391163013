import React from 'react';
import { Trans } from '@lingui/macro';

export const getThanksLabels = () => ({
    defaultTitle: (
        <Trans
            id="wizard.thanks.defaultTitle"
            comment="default message that is shown to thank the user for filling in a form when no custom message has been set"
        >
            Thank you for your donation
        </Trans>
    ),
});
