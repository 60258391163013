import { ensureFirstLetterIsUppercase } from '@utilities/textUtilities';
import initialsFormatter from '@formatter/initialsFormatter';

export type StringModifier = (value: string) => string;

const toLowerCase: StringModifier = (value) => value.toLowerCase();

const toIdentifier: StringModifier = (value) =>
    value.toUpperCase().replace(/ /g, '');

type FormatterRegistryEntry =
    | {
          onChange: StringModifier;
          onBlur?: StringModifier;
      }
    | {
          onChange?: StringModifier;
          onBlur: StringModifier;
      }
    | {
          onChange: StringModifier;
          onBlur: StringModifier;
      };

type FormatterRegistry = Record<string, FormatterRegistryEntry>;

const formatterRegistry: FormatterRegistry = {
    lowercase: {
        onChange: toLowerCase,
    },
    firstcase: {
        onChange: ensureFirstLetterIsUppercase,
    },
    initials: {
        onChange: (value) => initialsFormatter(value, false),
        onBlur: (value) => initialsFormatter(value, true),
    },
    identifier: {
        onChange: toIdentifier,
    },
};

export function resolveFormatterOrThrow(
    identifier: string,
): FormatterRegistryEntry {
    const entry = formatterRegistry[identifier];

    if (!entry) {
        throw new Error(`No formatter found for identifier '${identifier}'`);
    }

    return entry;
}
