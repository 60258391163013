import { useMemo } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import useUserState from '@hooks/useUserState';

export type FormValues = {
    amountPreset: string | null;
};

export default function useUserPreferencesFormState(): UseFormMethods<FormValues> {
    const { preferences } = useUserState();

    const defaultValues = useMemo(
        () => ({
            amountPreset: preferences.amountPreset || '',
        }),
        [preferences],
    );

    return useForm<FormValues>({
        defaultValues,
    });
}
