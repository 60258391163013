import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useAppConfig from '@hooks/useAppConfig';
import { useAppSelector } from '@hooks/useAppSelector';
import { captureError } from '@utilities/errorCapturingUtilities';
import { addEntry } from '@store/slice/buffer/bufferSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { contextLogger } from '@utilities/logger';
import { fetchPushEntry } from '@store/slice/buffer/thunkAction/fetchPushEntry';
import useIsOnline from '@hooks/useIsOnline';
import useActiveCampaign from '@hooks/useActiveCampaign';
import { reset as resetIdealQrState } from '@store/slice/idealQr/idealQrSlice';
import routes from '../../../../../routes';
import prepareFormData from '../handler/prepareFormData';
import useDetermineDefaultValues from './useDetermineDefaultValues';
import createValidationSchema from '../factory/validationSchemaFactory';
import { WizardFormState } from '../types/wizardFormState';
import useIdealQrState from '../../../../../hooks/useIdealQrState';

export default function useHandleWizardFormState() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const appConfig = useAppConfig();
    const fieldAppOnline = useIsOnline();
    const recruiter = useAppSelector((state) => state.user.details);
    const campaign = useActiveCampaign(true);
    const successPath = routes.wizard.thanks;
    const { codeId: idealQrId } = useIdealQrState();

    const onSubmit: SubmitHandler<WizardFormState> = async (
        data: WizardFormState,
    ) => {
        if (Object.keys(data).length === 0) {
            captureError('Form submitted with empty object', {
                level: 'warning',
            });

            return;
        }

        contextLogger.info('Submitted form data:', {
            ...data,
            fieldAppOnline,
        });

        if (!recruiter) {
            throw new Error(
                'Expecting recruiter information to be there at this point',
            );
        }

        const timestamp = Date.now().toString();

        dispatch(
            addEntry({
                data: prepareFormData(
                    data,
                    fieldAppOnline,
                    recruiter,
                    idealQrId,
                ),
                recruiter,
                campaign,
                timestamp,
            }),
        );
        await dispatch(fetchPushEntry(timestamp));
        dispatch(resetIdealQrState());

        history.push(successPath);
    };

    const defaultValues = useDetermineDefaultValues();
    const idealQrState = useIdealQrState();

    const resolver = yupResolver(
        createValidationSchema(appConfig, idealQrState),
    ) as Resolver<WizardFormState>;

    const form = useForm<WizardFormState>({
        defaultValues,
        resolver,
        context: { appConfig },
    });

    return { form, onSubmit };
}
