import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '@primitives/form/SubmitButton';
import useHasSuccessfulTransaction from '@hooks/useHasSuccessfulTransaction';
import Modal from '@primitives/modal/Modal';
import {
    getConfirmationModalLabels,
    getConfirmationMessage,
    getIdealConfirmationMessage,
} from './ConfirmationModal.trans';
import { getWizardLabels } from '../../../../../../Wizard.trans';

/**
 * @param {object} props
 * @param {Function} props.onCancel
 * @param {Function} props.onConfirm
 * @returns {React.ReactElement}
 */
function ConfirmationModal({ onCancel, onConfirm }) {
    const labels = getConfirmationModalLabels();
    const wizardLabels = getWizardLabels();

    const hasSuccessfulTransaction = useHasSuccessfulTransaction();

    const message = () => {
        if (hasSuccessfulTransaction) {
            return getIdealConfirmationMessage();
        }

        return getConfirmationMessage();
    };

    return (
        <Modal>
            <div className="modal-header">
                <h5 className="modal-title">{labels.modalTitle}</h5>
                <button
                    type="button"
                    className="btn-close"
                    aria-label={wizardLabels.close}
                    onClick={() => onCancel()}
                />
            </div>
            <div className="modal-body">
                <p>{message()}</p>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onCancel()}
                >
                    {labels.cancel}
                </button>
                {hasSuccessfulTransaction ? (
                    <SubmitButton
                        onClick={() => onConfirm(hasSuccessfulTransaction)}
                        className="btn btn-dark theme-btn mr-auto"
                    >
                        {labels.sendAsOneTime}
                    </SubmitButton>
                ) : (
                    <SubmitButton
                        type="button"
                        className="btn btn-danger"
                        onClick={() => onConfirm()}
                    >
                        {wizardLabels.close}
                    </SubmitButton>
                )}
            </div>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
