import { t } from '@lingui/macro';

export default {
    failedHeader: () =>
        t({
            id: 'landing-page.payment.failed.header',
            comment: 'Header of the landing page for a failed payment',
            message: 'Something went wrong',
        }),
    instruction: () =>
        t({
            id: 'landing-page.payment.failed.instructionsMessage',
            comment:
                'Message containing instructions to follow after unsuccessfull payment',
            message:
                'Your payment was unsuccessful. Please follow the instructions of our ambassador.',
        }),
};
