import { UseFormMethods } from 'react-hook-form';
import useIdealQrState from '@hooks/useIdealQrState';
import { IdealQrStatus } from '@constants/idealQrStatus';
import { WizardFormState } from '../../../types/wizardFormState';
import useDetermineIdealQrPaymentIsRequired from './useDetermineIdealQrPaymentIsRequired';

/**
 * As soon as an iDeal QR payment was successful, we no longer allow
 * changes to the IBAN field, as otherwise, recruiters would be able to
 * pay iDeal QR themselves with a small amount, and fill in the donors
 * IBAN, so they get the bonus.
 *
 * For projects where iDeal QR is required (not optional), we also don't allow
 * the IBAN field to be manually changed.
 *
 * @param {UseFormMethods<WizardFormState>} form
 * @returns {boolean}
 */
export default function useDetermineIfIbanFieldShouldBeReadOnly(
    form: UseFormMethods<WizardFormState>,
) {
    const idealQrPaymentIsRequired = useDetermineIdealQrPaymentIsRequired(form);

    const { status } = useIdealQrState();

    return idealQrPaymentIsRequired || status === IdealQrStatus.SUCCESS;
}
