/* eslint-disable import/prefer-default-export */

export const nodeEnv = process.env.NODE_ENV || null;

export const isDevBuild = nodeEnv === 'development';
export const isProdBuild = nodeEnv === 'production';

export enum BuildName {
    Production = 'production',
    Development = 'development',
}
export function getBuildName(): BuildName | 'unknown' {
    if (isProdBuild) {
        return BuildName.Production;
    }

    if (isDevBuild) {
        return BuildName.Development;
    }

    return 'unknown';
}

export const isDevEnvironment = window.location.hostname.includes('localhost');
export const isJestTestEnvironment = nodeEnv === 'test';
const isTestEnvironment = window.location.hostname.endsWith('.dev');
const isProductionEnvironment =
    window.location.hostname.endsWith('.app') ||
    window.location.hostname.endsWith('.nl');

export enum EnvironmentIdentifier {
    Dev = 'dev',
    Prod = 'prod',
    Test = 'test',
}

export function getEnvironmentIdentifier(): EnvironmentIdentifier | 'unknown' {
    if (isProductionEnvironment) {
        return EnvironmentIdentifier.Prod;
    }

    if (isTestEnvironment) {
        return EnvironmentIdentifier.Test;
    }

    if (isDevEnvironment) {
        return EnvironmentIdentifier.Dev;
    }

    return 'unknown';
}
