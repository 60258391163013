import { UseFormMethods } from 'react-hook-form';
import { WizardFormState } from '../types/wizardFormState';

export default function useMandateAmount(
    form: UseFormMethods<WizardFormState>,
): number | null {
    const amount = form.watch('amount');
    const amountAlternative = form.watch('amountAlternative');

    if (amount === 'alt') {
        return amountAlternative ? parseFloat(amountAlternative) : null;
    }

    return amount ? parseFloat(amount) : null;
}
