/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchPushEntry } from './thunkAction/fetchPushEntry';
import { addEntryReducer } from './reducer/addEntryReducer';
import { clearResultFromEntryReducer } from './reducer/clearResultFromEntryReducer';
import { cleanupReducer } from './reducer/cleanupReducer';
import {
    fetchPushEntryFulfilledReducer,
    fetchPushEntryPendingReducer,
    fetchPushEntryPendingRejectedReducer,
} from './reducer/fetchPushEntryReducer';
import { SliceNamespace } from '../../constants/namespace';
import { BufferState } from './types';

const initialState: BufferState = {
    entries: {},
    result: {
        pending: false,
        pendingTimestamp: null,
        lastSuccess: null,
        errorMessage: null,
    },
};

const bufferSlice = createSlice({
    name: SliceNamespace.Buffer,
    initialState,
    reducers: {
        addEntry: addEntryReducer,
        clearResultFromEntry: clearResultFromEntryReducer,
        cleanUp: cleanupReducer,
    },
    extraReducers: {
        [fetchPushEntry.pending.toString()]: fetchPushEntryPendingReducer,
        [fetchPushEntry.fulfilled.toString()]: fetchPushEntryFulfilledReducer,
        [fetchPushEntry.rejected.toString()]:
            fetchPushEntryPendingRejectedReducer,
    },
});

export default bufferSlice;
export const { addEntry, clearResultFromEntry, cleanUp } = bufferSlice.actions;
