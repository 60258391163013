import { t } from '@lingui/macro';
import pick from 'lodash/pick';
import personalTranslations from '../wizardFormPersonal/WizardFormPersonal.trans';
import customTranslations from '../wizardFormCustom/WizardFormCustom.trans';
import addressTranslations from '../wizardFormAddress/WizardFormAddress.trans';
import confirmationTranslations from '../wizardFormConfirmation/WizardFormConfirmation.trans';
import { getAmountRadioInputLabels } from '../amountRadioInput/AmountRadioInput.trans';
import emailTranslations from '../emailFormInputWithDomainSuggestion/EmailFormInputWithDomainSuggestion.trans';

const translations = {
    title: () =>
        t({
            id: 'form.errorsNotification.title',
            comment: 'Title for form errors notification',
            message:
                'The form contains invalid/missing values and can therefor not be submitted.',
        }),
};

export const fieldTranslations = {
    ...pick(personalTranslations, [
        'academicTitle',
        'firstName',
        'initials',
        'surnamePrefix',
        'surname',
        'dateOfBirth',
        'sex',
        'mobilePhoneNumber',
        'phoneNumber',
    ]),
    email: emailTranslations.email,
    ...pick(customTranslations, ['categories']),
    ...pick(addressTranslations, [
        'country',
        'zipCode',
        'houseNumber',
        'houseNumberExtension',
        'street',
        'city',
    ]),
    amount: () => getAmountRadioInputLabels().amount,
    amountAlternative: () => getAmountRadioInputLabels().amount,
    signaturePayload: confirmationTranslations.headerTitle,
};

export default translations;
