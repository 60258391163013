import React, { FC } from 'react';
import Alert from '@primitives/alert/Alert';
import useStorageStats from '../../hooks/useStorageStats';
import { getStorageUsageLabels } from './StorageUsage.trans';
import StorageProgressBar from './components/StorageProgressBar';

const StorageUsage: FC = () => {
    const stats = useStorageStats();

    const labels = getStorageUsageLabels(stats);

    const ratioDescription = `${stats.usage}/${stats.quota}M`;

    return (
        <div className="tw-w-full">
            {stats.isDanger && (
                <Alert className="tw-mb-4">
                    {stats.isFull ? labels.isFull : labels.almostFull}
                </Alert>
            )}
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-x-4 tw-gap-y-1">
                <div className="xsmax:tw-grow">
                    <span className="tw-text-sm tw-font-semibold tw-text-darker-gray">
                        {labels.title}
                    </span>
                </div>
                <div className="tw-grow xsmax:tw-w-full xsmax:tw-order-3">
                    <StorageProgressBar stats={stats} />
                </div>
                <div className="xsmax:tw-order-2">
                    <span className="tw-text-sm tw-font-medium tw-text-darker-gray">
                        {labels.percentageInUse}
                    </span>
                </div>
                <div className="xsmax:tw-w-full xsmax:tw-order-3">
                    <span className="tw-text-muted tw-text-sm tw-font-medium">
                        {ratioDescription}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default StorageUsage;
