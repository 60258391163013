import type { Middleware } from '@reduxjs/toolkit';
import * as analytics from '@integrations/analytics/gtag';
import type { RootState } from '../index';
import { fetchSignInUser } from '../slice/user/thunkAction/fetchSignInUser';
import { uniqueUserIdSelector } from '../slice/user/utilities/userStateSelectors';

function updateUserId(state: RootState) {
    const userId = uniqueUserIdSelector(state);

    analytics.setUserId(userId);
}

const createAnalyticsMiddleware: Middleware = (store) => {
    updateUserId(store.getState());

    return (next) => (action) => {
        analytics.logEvent(action.type);

        if (action.type === fetchSignInUser.fulfilled) {
            updateUserId(store.getState());
        }

        return next(action);
    };
};

export default createAnalyticsMiddleware;
