import React from 'react';
import { Trans } from '@lingui/macro';

export const getLoggedInUserDetailsLabels = () => ({
    switchAccount: (
        <Trans
            id="user.button.switchAccount"
            comment="Button textSwitch to a different account"
        >
            Switch
        </Trans>
    ),
    login: (
        <Trans id="button.login" comment="Button text to log into account">
            Log in
        </Trans>
    ),
    loggedIn: (
        <Trans
            id="user.login.loggedIn"
            comment="Text that tells the user they have been logged in since a certain time"
        >
            Logged in since
        </Trans>
    ),
    notLoggedIn: (
        <Trans
            id="user.login.notLoggedIn"
            comment="Text that tells the user that they need to login to send their forms"
        >
            Log in to send forms
        </Trans>
    ),
    logout: (
        <Trans id="user.button.logout" comment="log out of the app">
            Log out
        </Trans>
    ),
});
