import { isValidIBAN, friendlyFormatIBAN } from 'ibantools';

const partSeparator = '_#_';

/**
 * @param {string} iban
 * @param {boolean} separateChecksum Weather or not the checksum should be returned as a separate field in non-Dutch mode
 * @returns {{ country: string, checksum: string | null, bank: string | null, number: string }|null}
 */
export function parseIban(iban, separateChecksum) {
    // When the form value is not a valid iban, we cannot parse it correctly, causing
    // all sorts of strange behaviour.
    const valid = isValidIBAN(iban);

    if (!valid) {
        return null;
    }

    // Using iban library to split the separate IBAN parts
    const [countryAndChecksum, ...restOfIBANParts] = friendlyFormatIBAN(
        iban,
        partSeparator,
    ).split(partSeparator);

    const country = countryAndChecksum.replace(/[^a-z]+/gi, '');
    const checksum = countryAndChecksum.replace(/[^0-9]/g, '');
    if (iban.substring(0, 2) === 'NL') {
        const [bank, ...numberParts] = restOfIBANParts;
        const number = numberParts.join('');
        return { country, checksum, bank, number };
    }

    const number = restOfIBANParts.join('');

    // For non-NL IBAN's we don't split the bank, as this is too cumbersome due to there being different banks
    // per country.

    if (separateChecksum) {
        return {
            country,
            checksum,
            bank: null,
            number,
        };
    }

    return {
        country,
        checksum: null,
        bank: null,
        number: [checksum, number].join(''),
    };
}
