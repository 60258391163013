import { createAsyncThunk } from '@reduxjs/toolkit';
import * as identityPlatformApi from '@integrations/auth/identityPlatformApi';
import * as api from '@integrations/service/api';
import { getLocalizedErrorMessages } from '@integrations/errorMessages.trans';
import type { RootState } from '@store/index';
import { SliceNamespace } from '@store/constants/namespace';
import type { SignInResponse } from '../types';
import {
    captureBreadcrumb,
    captureError,
    captureLoggedInUser,
} from '../../../../utilities/errorCapturingUtilities';
import { GetUserResponseBody } from '../../../../../../shared/src/contracts/response/getUser';

export const fetchSignInUser = createAsyncThunk<
    SignInResponse,
    { email: string; password: string }
>(
    `${SliceNamespace.User}/fetch/signIn`,
    async (payload) => {
        const { email, password } = payload;

        captureBreadcrumb('logging-in user', 'info', 'auth', {
            email,
        });

        const context = await api.getAuthContext();
        const userInfo = await identityPlatformApi.signInWithEmailAndPassword(
            email,
            password,
            context,
        );

        let additionalUserInfo: GetUserResponseBody | null = null;
        try {
            additionalUserInfo = await api.getUser();
        } catch (error) {
            // Ignore error, as recruiter can do without user info

            captureError(error, {
                level: 'warning',
                extra: {
                    email,
                    tenant: userInfo.tenant,
                },
            });
        }

        if (
            additionalUserInfo &&
            additionalUserInfo?.hasRecruitingPermission === false
        ) {
            captureBreadcrumb(
                'user has no recruiting permission',
                'warning',
                'auth',
                {
                    email,
                },
            );

            throw new Error(getLocalizedErrorMessages().forbidden);
        }

        const completeUserData: SignInResponse = {
            ...userInfo,
            ...additionalUserInfo,
        };

        captureBreadcrumb('logged-in user', 'info', 'auth', completeUserData);
        captureLoggedInUser(userInfo?.id, userInfo?.name);

        return completeUserData;
    },
    {
        condition: (_payload, { getState }) => {
            const state = getState() as RootState;

            const { result } = state[SliceNamespace.User];

            // When a sign in or out is pending, don't try again
            // noinspection RedundantIfStatementJS
            if (result?.pending === true) {
                return false;
            }

            return true;
        },
    },
);
