/* eslint-disable consistent-return */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import MicRecorder from 'mic-recorder-to-mp3';
import './SignatureRecorder.scss';
import { ReactComponent as MicIcon } from 'bootstrap-icons/icons/mic.svg';
import { ReactComponent as MicFillIcon } from 'bootstrap-icons/icons/mic-fill.svg';
import PropTypes from 'prop-types';
import { InvalidFeedback } from '@primitives/form/InvalidFeedback';
import { FormGroup } from '@primitives/form/FormGroup';
import { captureError } from '@utilities/errorCapturingUtilities';
import Button from '@primitives/button/Button';
import blobToDataUrl from '../../transformer/blobToDataUrl';
import SignatureRecorderText from './components/SignatureRecorderText';
import { getSignatureRecorderLabels } from './SignatureRecorder.trans';
import { formPropTypes } from '../../../../../../../../propTypes';

const maxDuration = 50;
const config = {
    bitRate: 96,
};

/**
 * @param {object} props
 * @param {string} props.name
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function SignatureRecorder({ name, form }) {
    const {
        setValue,
        watch,
        setError,
        formState: { errors },
    } = form;

    const recorder = useMemo(() => new MicRecorder(config), []);
    const value = watch(name);

    const [isRecording, setRecording] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [remainingDuration, setRemainingDuration] = useState(0);
    const isEmpty = !value || value.length === 0;
    const labels = getSignatureRecorderLabels(remainingDuration);

    const onReset = useCallback(() => {
        setValue(name, '');
    }, [setValue, name]);

    const startRecording = useCallback(async () => {
        try {
            await recorder.start();
            setRemainingDuration(maxDuration);
            setRecording(true);
        } catch (error) {
            captureError(error, { level: 'warning' });
            setError(name, { type: 'manual', message: labels.microphoneError });
            setRecording(false);
        }
    }, [
        labels.microphoneError,
        recorder,
        setRecording,
        setRemainingDuration,
        setError,
        name,
    ]);

    const stopRecording = useCallback(async () => {
        setRecording(false);
        setLoading(true);
        try {
            const [, blob] = await recorder.stop().getMp3();
            const url = await blobToDataUrl(blob);
            setValue(name, url, { shouldValidate: true });
        } catch (error) {
            captureError(error, { level: 'warning' });
            setError(name, {
                type: 'manual',
                message: labels.savingRecordingError,
            });
        }
        setLoading(false);
        setRecording(false);
    }, [
        labels.savingRecordingError,
        setLoading,
        recorder,
        setValue,
        name,
        setRecording,
        setError,
    ]);

    const onToggleRecorder = useCallback(() => {
        if (isRecording) {
            stopRecording();
        } else {
            onReset();
            startRecording();
        }
    }, [isRecording, stopRecording, onReset, startRecording]);

    const tickTimer = useCallback(() => {
        const newRemainingDuration = remainingDuration - 1;
        setRemainingDuration(newRemainingDuration);
        if (newRemainingDuration === 0) {
            stopRecording();
        }
    }, [stopRecording, remainingDuration, setRemainingDuration]);

    useEffect(() => {
        if (isRecording) {
            const interval = setInterval(() => tickTimer(), 1000);
            return () => clearInterval(interval);
        }
    }, [isRecording, tickTimer]);

    useEffect(() => () => recorder.stop(), [recorder]);

    return (
        <FormGroup className="signature-recorder col-12">
            <label className="form-label" htmlFor={name}>
                {labels.title}
            </label>

            <div
                id={name}
                className={classNames('form-control', {
                    'is-invalid': errors[name],
                })}
            >
                <SignatureRecorderText watch={watch} />
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio
                    src={value}
                    controls
                    controlsList="nodownload"
                    className={classNames({ 'is-invalid': errors[name] })}
                />
            </div>
            <InvalidFeedback error={errors[name]} />

            <div className="mt-2">
                <Button
                    spinner={isLoading}
                    type="button"
                    onClick={onToggleRecorder}
                    className={isRecording ? 'btn-danger' : 'btn-primary'}
                >
                    {isRecording ? (
                        <>
                            <MicFillIcon className="me-1" /> {labels.stop}
                        </>
                    ) : (
                        <>
                            <MicIcon className="me-1" /> {labels.record}
                        </>
                    )}
                </Button>
                <Button
                    type="button"
                    onClick={onReset}
                    className="ms-2 btn-secondary"
                    disabled={isLoading || isEmpty}
                >
                    {labels.retry}
                </Button>
                {isRecording && (
                    <small className="text-muted ms-3">
                        {labels.secondsRemaining}
                    </small>
                )}
            </div>
        </FormGroup>
    );
}

SignatureRecorder.propTypes = {
    name: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
};

export default SignatureRecorder;
