import PropTypes from 'prop-types';

export const formRegisterPropType = PropTypes.func;
export const formWatchPropType = PropTypes.func;
export const formSetValuePropType = PropTypes.func;
export const formGetValuesPropType = PropTypes.func;
export const formTriggerPropType = PropTypes.func;
export const formSetErrorPropType = PropTypes.func;
export const formClearErrorsPropType = PropTypes.func;

export const formFormStateError = PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
});

const formFormStatePropTypeShape = {
    errors: PropTypes.objectOf(formFormStateError.isRequired).isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
};
const formFormStatePropType = PropTypes.shape(formFormStatePropTypeShape);

export const formPropTypeShape = {
    register: formRegisterPropType.isRequired,
    watch: formWatchPropType.isRequired,
    setValue: formSetValuePropType.isRequired,
    formState: formFormStatePropType.isRequired,
    getValues: formGetValuesPropType.isRequired,
    trigger: formTriggerPropType.isRequired,
    setError: formSetErrorPropType.isRequired,
    clearErrors: formClearErrorsPropType.isRequired,
};
export const formPropTypes = PropTypes.shape(formPropTypeShape);

export const layoutChildrenPropType = PropTypes.arrayOf(
    PropTypes.shape({
        component: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                component: PropTypes.string.isRequired,
            }).isRequired,
        ),
    }).isRequired,
);
