import React, { FC, MouseEventHandler } from 'react';
import DropdownButton from '@primitives/tapraise/form/DropdownButton';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { setActiveCampaign } from '@store/slice/tenant/tenantSlice';
import useTenantState from '@hooks/useTenantState';
import { getSelectActiveCampaignLabels } from './SelectActiveCampaign.trans';

const SelectActiveCampaign: FC = () => {
    const {
        campaigns: { list },
    } = useTenantState();

    const dispatch = useAppDispatch();

    if (!list) {
        return null;
    }

    const onChildClick: MouseEventHandler = (event) => {
        const link = event.target;

        if (!(link instanceof HTMLAnchorElement)) {
            throw new Error('Expecting target to be an HTMLAnchorElement');
        }

        const id = link.getAttribute('href');
        if (!id) {
            throw new Error('Expecting href to contain the campaign id');
        }

        dispatch(setActiveCampaign(id));
    };

    const labels = getSelectActiveCampaignLabels();

    return (
        <DropdownButton
            label={labels.campaignSelection}
            onChildClick={onChildClick}
            disabled={!list}
        >
            {Object.keys(list || {}).map((id) => (
                <a
                    key={id}
                    href={id}
                    className="tw-w-full tw-px-4 tw-py-1 tw-flex tw-no-underline tw-text-base tw-min-w-[22rem] tw-text-dark-gray tw-text-left hover:tw-bg-light-gray hover:tw-text-dark-gray"
                >
                    {list[id].name}
                </a>
            ))}
        </DropdownButton>
    );
};

export default SelectActiveCampaign;
