import { t } from '@lingui/macro';
import raw from 'raw.macro';
import templateKeys from '../constants/templateKeys';

export default {
    [templateKeys.header]: (values) =>
        t({
            id: 'wizard.form.signatureTemplate.Header',
            comment:
                'The top legal text that is shown in the signature which details that a donation can be withdrawn',
            message: raw('./../templates/defaultHeaderText.ejs'),
            values,
        }),
    [templateKeys.footer]: (values) =>
        t({
            id: 'wizard.form.signatureTemplate.Footer',
            comment:
                'The bottom legal text that is shown in the signature which details what the signee agrees to',
            message: raw('./../templates/defaultFooterText.ejs'),
            values,
        }),
    [templateKeys.audio]: (values) =>
        t({
            id: 'wizard.form.signatureTemplate.Audio',
            comment:
                'The script that the recruiter reads out to the signee during the audio signature',
            message: raw('./../templates/defaultAudioText.ejs'),
            values,
        }),
};
