/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import classNames from 'classnames';
import { StorageStats } from '../../../hooks/useStorageStats';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    stats: StorageStats;
}

const StorageProgressBar: FC<Props> = ({
    stats,
    className: additionalClassName,
    ...props
}) => {
    const { isWarning, isDanger, percentage } = stats;

    const className = classNames(
        'tw-flex tw-rounded-full tw-bg-[#e9ecef] tw-overflow-hidden tw-h-2',
        additionalClassName,
    );

    const percentageClassName = classNames('tw-bg-blue', {
        'bg-warning': isWarning,
        'bg-danger': isDanger,
    });

    return (
        <div {...props} className={className}>
            <div
                className={percentageClassName}
                aria-label={`${percentage}% in use`}
                role="progressbar"
                style={{ width: `${percentage}%` }}
            />
        </div>
    );
};

export default StorageProgressBar;
