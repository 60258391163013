import React, { useMemo } from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { SelectInput } from '@primitives/form/components/SelectInput';
import useAppConfig from '@hooks/useAppConfig';
import Interval from '@constants/interval';
import labels from '../wizardFormPayment/WizardFormPayment.trans';
import { intervalLabels } from '../../WizardForm.trans';
import { formPropTypes } from '../../../../../../propTypes';

const defaultIntervalOptions = [Interval.monthly, Interval.oneTime];

/**
 * @param {module:AppConfig.AppConfig} appConfig
 * @returns {object}
 */
function useIntervalOptions(appConfig) {
    return useMemo(() => {
        const options =
            appConfig?.enabledIntervalOptions || defaultIntervalOptions;
        return pick(intervalLabels, options);
    }, [appConfig?.enabledIntervalOptions]);
}

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {string=} props.defaultOption
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function IntervalSelectInput({ className, defaultOption, form }) {
    const appConfig = useAppConfig();

    const options = useIntervalOptions(appConfig);

    return (
        <SelectInput
            form={form}
            className={className}
            name="interval"
            label={labels.period}
            options={options}
            defaultOption={defaultOption}
        />
    );
}

IntervalSelectInput.propTypes = {
    className: PropTypes.string,
    defaultOption: PropTypes.string,
    form: formPropTypes.isRequired,
};

export default IntervalSelectInput;
