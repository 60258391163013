const routes = {
    home: '/',
    auth: '/auth',
    wizard: {
        root: '/wizard',
        splash: '/wizard/splash',
        form: '/wizard/form',
        thanks: '/wizard/thanks',
    },
};

export default routes;
