import { defineMessage } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import Interval from '@constants/interval';

const translations: Record<string, MessageDescriptor> = {
    [Interval.monthly]: defineMessage({
        id: 'home.entries.interval.monthly.abbreviation',
        comment: 'Abbreviation for monthly interval in entries table',
        message: 'M',
    }),
    [Interval.oneTime]: defineMessage({
        id: 'home.entries.interval.single.abbreviation',
        comment: 'Abbreviation for one-time interval in entries table',
        message: 'O',
    }),
    [Interval.yearly]: defineMessage({
        id: 'home.entries.interval.yearly.abbreviation',
        comment: 'Abbreviation for yearly interval in entries table',
        message: 'Y',
    }),
    [Interval.halfYearly]: defineMessage({
        id: 'home.entries.interval.halfyearly.abbreviation',
        comment: 'Abbreviation for half-yearly interval in entries table',
        message: 'H',
    }),
    [Interval.quarterly]: defineMessage({
        id: 'home.entries.interval.quarterly.abbreviation',
        comment: 'Abbreviation for quarterly interval in entries table',
        message: 'Q',
    }),
};

export default translations;
