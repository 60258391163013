import { useEffect, useState } from 'react';
import useAppConfig from '@hooks/useAppConfig';
import Interval from '@constants/interval';
import { resolveIdealQrIsEnabled } from '@resolver/appConfigValueResolver';
import useIdealQrState from '@hooks/useIdealQrState';
import { IdealQrStatus } from '@constants/idealQrStatus';

/**
 * @param {Function} watch
 * @returns {boolean}
 */
export default function useDetermineIfSignatureIsNeeded(watch) {
    const appConfig = useAppConfig();

    const [isNeeded, setIsNeeded] = useState(true);

    const { codeId: idealQrId, status } = useIdealQrState();

    const interval = watch('interval');
    const iban = watch('iban');

    useEffect(() => {
        const idealQrIsEnabled = resolveIdealQrIsEnabled(appConfig);

        const isOneTimeDonation = interval === Interval.oneTime;
        const idealQrPaymentIsSuccessful =
            !!idealQrId && !!iban && status === IdealQrStatus.SUCCESS;

        const signatureIsNeeded = !(
            idealQrIsEnabled &&
            isOneTimeDonation &&
            idealQrPaymentIsSuccessful
        );

        setIsNeeded(signatureIsNeeded);
    }, [appConfig, iban, idealQrId, interval, status]);

    return isNeeded;
}
