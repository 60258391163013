import React, { FC, MouseEventHandler } from 'react';
import Button from '@primitives/button/Button';
import labels from '../EntryId.trans';

type Props = {
    id: string | number;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const maxLengthOfIdDisplayed = 7;

const CopyEntryIdButton: FC<Props> = ({ id, onClick }) => {
    const idAsString = id.toString();

    const suffix =
        idAsString.length > maxLengthOfIdDisplayed && typeof id !== 'number'
            ? idAsString.substring(idAsString.length - 6, idAsString.length)
            : idAsString;

    return (
        <Button
            deflated
            onClick={onClick}
            title={labels.copyButtonTitle()}
            type="button"
        >
            {suffix}
        </Button>
    );
};

export default CopyEntryIdButton;
