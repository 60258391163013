import React from 'react';
import {
    Route,
    Switch,
    useParams,
} from 'react-router-dom/cjs/react-router-dom';
import { getFilePath } from '@integrations/service/api';
import './LandingPage.scss';
import { LandingPageFailed } from './components/landingPageFailed/LandingPageFailed';
import { LandingPageSuccess } from './components/landingPageSuccess/LandingPageSuccess';
import LandingPageTheme from './components/LandingPageTheme';
import useFetchLandingPageConfig from './hooks/useFetchLandingPageConfig';

export default function LandingPage() {
    const { campaignId } = useParams();
    const { brandImage, splashImage, websiteUrl } =
        useFetchLandingPageConfig(campaignId);

    return (
        <div className="landing-page">
            <header className="m-0 py-4 landing-page-header">
                <div className="container-fluid">
                    <div className="d-flex align-items-center">
                        <img
                            className="landing-page-logo"
                            src={getFilePath(campaignId, brandImage)}
                            alt="Campaign logo"
                        />
                    </div>
                </div>
            </header>
            <LandingPageTheme
                campaignId={campaignId}
                splashImage={splashImage}
            />
            <Switch>
                <Route
                    path="/campaigns/:campaignId/payment/success"
                    render={() => (
                        <LandingPageSuccess websiteUrl={websiteUrl} />
                    )}
                />
                <Route
                    path="/campaigns/:campaignId/payment/:status"
                    component={LandingPageFailed}
                />
            </Switch>
        </div>
    );
}
