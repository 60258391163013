import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { TextInput } from '@primitives/form/Form';
import FormText from '@primitives/form/FormText';
import { logEvent } from '@integrations/analytics/gtag';
import Button from '@primitives/button/Button';
import useEmailSuggestion from './hooks/useEmailSuggestion';
import labels from './EmailFormInputWithDomainSuggestion.trans';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {string=} props.id
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {string=} props.name
 * @param {string|React.ReactNode=} props.label
 * @param {string=} props.sup
 * @param {React.ReactNode=} props.children
 * @returns {React.ReactElement}
 */
const EmailFormInputWithDomainSuggestion = ({
    className,
    id,
    form,
    name,
    label,
    sup,
    children,
}) => {
    const { watch, setValue } = form;

    const suggestion = useEmailSuggestion(watch('email'));

    const onApplyClick = () => {
        setValue('email', suggestion);

        logEvent('email_suggestion_applied');
    };

    return (
        <div className={className}>
            <TextInput
                id={id}
                form={form}
                name={name || 'email'}
                label={label || labels.email.id}
                sup={sup}
            >
                {children}
                {suggestion && (
                    <FormText>
                        <Trans id={labels.suggestion} />{' '}
                        <strong>{suggestion}</strong>? (
                        <Button
                            type="button"
                            deflated
                            className="btn-link"
                            onClick={onApplyClick}
                        >
                            <Trans id={labels.applySuggestion} />
                        </Button>
                        )
                    </FormText>
                )}
            </TextInput>
        </div>
    );
};

EmailFormInputWithDomainSuggestion.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    form: formPropTypes.isRequired,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired, // = <Trans>
    ]),
    sup: PropTypes.string,
    children: PropTypes.node,
};

export default EmailFormInputWithDomainSuggestion;
