import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { InvalidFeedback } from '../../InvalidFeedback';
import { FormGroup } from '../../FormGroup';
import FormLabel from '../../FormLabel';
import { formPropTypes } from '../../../../propTypes';

function ensureSortedMap(options) {
    return options instanceof Map ? options : new Map(Object.entries(options));
}

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string|React.ReactNode=} props.label
 * @param {Object<string, any>} props.options
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {boolean=} props.inline
 * @param {string=} props.className
 * @param {string|number=} props.defaultValue
 * @param {Array<string|number>=} props.disabledValues
 * @param {boolean=} props.required
 * @returns {React.ReactElement}
 */
function RadioInput({
    name,
    label,
    options,
    form,
    inline = false,
    className,
    defaultValue,
    disabledValues = [],
    required = false,
}) {
    const {
        register,
        formState: { errors },
    } = form;

    const optionMap = ensureSortedMap(options);
    const optionKeys = Array.from(optionMap.keys());
    const controlClassName = classNames('form-check', {
        'form-check-inline': inline,
    });

    return (
        <FormGroup className={classNames(className)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <div className="custom-control-group">
                {optionKeys.map((key) => (
                    <div className={controlClassName} key={`${name}_${key}`}>
                        <input
                            type="radio"
                            id={`${name}_${key}`}
                            name={name}
                            defaultChecked={defaultValue === key}
                            className="form-check-input"
                            value={key}
                            disabled={disabledValues.indexOf(key) >= 0}
                            ref={register({ required })}
                        />
                        <FormLabel
                            className="form-check-label"
                            htmlFor={`${name}_${key}`}
                        >
                            {optionMap.get(key)}
                        </FormLabel>
                    </div>
                ))}
            </div>
            <InvalidFeedback error={errors[name]} />
        </FormGroup>
    );
}

RadioInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
    ]),
    options: PropTypes.object.isRequired,
    form: formPropTypes.isRequired,
    inline: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    disabledValues: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
    ),
    required: PropTypes.bool,
};

export default RadioInput;
