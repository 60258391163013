import React, { useMemo, useState, FC } from 'react';
import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import FormSection from '@primitives/form/components/FormSection';
import FormRow from '@primitives/form/components/FormRow';
import DateTextInput from '@primitives/form/components/DateTextInput';
import { asCurrencyFormatMapWithLabelAddition } from '@formatter/asCurrencyFormat';
import AmountRadioInput from '../amountRadioInput/AmountRadioInput';
import labels from '../wizardFormPayment/WizardFormPayment.trans';
import { lotteryTranslations } from './WizardFormLotteryPayment.trans';
import IntervalSelectInput from '../intervalSelectInput/IntervalSelectInput';
import PreferredPaymentDayRadio from '../preferredPaymentDayRadio/PreferredPaymentDayRadio';
import usePreferredPaymentDayOptions from '../../hooks/usePreferredPaymentDayOptions';
import IBANInput from '../ibanInput/IBANInput';
import usePreferredAmountPresetForInterval from '../../hooks/usePreferredAmountPresetForInterval';
import { WizardFormState } from '../../types/wizardFormState';

type Props = {
    extended?: boolean;
    defaultOption?: string;
    form: UseFormMethods<WizardFormState>;
    title?: string;
};

const WizardFormLotteryPayment: FC<Props> = ({
    extended = false,
    defaultOption,
    form,
    title = labels.headerTitle,
}) => {
    const [isExtended, setExtended] = useState(extended);
    const extendedClassName = { 'd-none': !isExtended };

    const onToggleOptions = () => {
        setExtended(!isExtended);
    };

    const interval = form.watch('interval');
    const amountPreset = usePreferredAmountPresetForInterval(interval);

    const customAmountOptionsMap = useMemo(
        () =>
            asCurrencyFormatMapWithLabelAddition(
                amountPreset,
                lotteryTranslations,
            ),
        [amountPreset],
    );

    const options = usePreferredPaymentDayOptions();

    return (
        <FormSection title={title} onToggleOptions={onToggleOptions}>
            <FormRow>
                <IntervalSelectInput
                    form={form}
                    className={classNames('col-sm-7', extendedClassName)}
                    defaultOption={defaultOption}
                />
                <DateTextInput
                    form={form}
                    className={classNames('col-sm-5', extendedClassName)}
                    name="startDate"
                    label={labels.startDate}
                    isDisabled={() => true}
                />

                <AmountRadioInput
                    form={form}
                    className="col-sm-9"
                    showOther={false}
                    customAmountOptionsMap={customAmountOptionsMap}
                />

                {options && (
                    <PreferredPaymentDayRadio form={form} options={options} />
                )}

                <IBANInput
                    form={form}
                    className="col-sm-12"
                    name="iban"
                    label={labels.iban}
                />
            </FormRow>
        </FormSection>
    );
};

export default WizardFormLotteryPayment;
