import React, { ChangeEventHandler, ComponentProps, FC } from 'react';
import TextInput from '@primitives/form/components/textInput/TextInput';
import { phoneNumberFormatterOnBlur } from '@formatter/phoneNumberFormatter';
import useAppConfig from '@hooks/useAppConfig';

type Props = Pick<
    ComponentProps<typeof TextInput>,
    | 'form'
    | 'name'
    | 'label'
    | 'className'
    | 'spinner'
    | 'isDisabled'
    | 'sup'
    | 'autoFocus'
    | 'children'
    | 'required'
>;

const PhoneTextInput: FC<Props> = ({
    form,
    name,
    label,
    className,
    spinner,
    isDisabled,
    sup,
    autoFocus,
    children,
    required,
}) => {
    const appConfig = useAppConfig();

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = phoneNumberFormatterOnBlur(
            event.target.value,
            appConfig.defaultCountryCode || null,
        );
    };

    return (
        <TextInput
            form={form}
            name={name}
            label={label}
            className={className}
            type="tel"
            spinner={spinner}
            isDisabled={isDisabled}
            sup={sup}
            autoFocus={autoFocus}
            required={required}
            onChange={onChange}
        >
            {children}
        </TextInput>
    );
};

export default PhoneTextInput;
