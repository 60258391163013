import React from 'react';
import PropTypes from 'prop-types';
import T from '../IdealQr.trans';

/**
 * @param {object} props
 * @param {string} props.iban
 * @returns {React.ReactElement}
 */
const SuccessView = ({ iban }) => (
    <>
        <div className="ideal-qr-check mb-3">✓</div>
        <p className="small mb-0">{`${T.statusPaymentReceived()}`}</p>
        <p>{iban}</p>
    </>
);

SuccessView.propTypes = {
    iban: PropTypes.string.isRequired,
};

export default SuccessView;
