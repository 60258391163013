import { Trans } from '@lingui/macro';
import React from 'react';

export const getSelectActiveCampaignLabels = () => ({
    campaignSelection: (
        <Trans
            id="campaign.button.campaignSelection"
            comment="dropdown selection to select a campaign"
        >
            Campaigns
        </Trans>
    ),
});
