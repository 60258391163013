import { t } from '@lingui/macro';

export default {
    title: () =>
        t({
            id: 'notification.update.title',
            comment:
                'Title that is shown in the notification to show that an update is available',
            message: 'There is an app update available.',
        }),
    label: () =>
        t({
            id: 'notification.update.label',
            comment: 'Label for the update button',
            message: 'Update',
        }),
};
