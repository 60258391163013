import React, { FC } from 'react';
import Header from '@primitives/tapraise/header/Header';
import Card from '@primitives/tapraise/Card';
import CardTitle from '@primitives/tapraise/CardTitle';
import EntriesOverview from './components/entriesOverview/EntriesOverview';
import SelectedCampaign from './components/selectedCampaign/SelectedCampaign';
import LoggedInUserDetails from './components/loggedInUserDetails/LoggedInUserDetails';
import ChangeUserPreferences from './components/changeUserPreferences/ChangeUserPreferences';
import { getHomeLabels } from './Home.trans';
import NotificationPortal from './components/notificationPortal/NotificationPortal';
import HomeFooter from './components/homeFooter/HomeFooter';
import { StartNewForm } from './components/startNewForm/StartNewForm';
import SelectActiveCampaign from './components/selectActiveCampaign/SelectActiveCampaign';
import UpdateTenantAndCampaigns from './components/updateTenantAndCampaigns/UpdateTenantAndCampaigns';
import StorageUsage from './components/storageUsage/StorageUsage';

const Home: FC = () => {
    const labels = getHomeLabels();

    return (
        <div className="tw-flex tw-flex-col tw-px-8 tw-py-8 tw-gap-8">
            <NotificationPortal />
            <div className="tw-flex tw-justify-between tw-w-full">
                <Header />
                <StartNewForm />
            </div>
            <div className="tw-flex-wrap ">
                <section className="tw-mb-6">
                    <Card>
                        <CardTitle>{labels.recruiter}</CardTitle>
                        <LoggedInUserDetails />
                    </Card>
                </section>
                <section className="tw-mb-6">
                    <Card>
                        <CardTitle>{labels.campaign}</CardTitle>
                        <SelectedCampaign>
                            <SelectActiveCampaign />
                            <UpdateTenantAndCampaigns />
                        </SelectedCampaign>
                    </Card>
                </section>
                <section className="tw-mb-6">
                    <Card>
                        <CardTitle>{labels.forms}</CardTitle>
                        <EntriesOverview>
                            <StorageUsage />
                        </EntriesOverview>
                    </Card>
                </section>
                <section className="tw-mb-6">
                    <Card>
                        <CardTitle>{labels.preferences}</CardTitle>
                        <ChangeUserPreferences />
                    </Card>
                </section>
                <HomeFooter />
            </div>
        </div>
    );
};

export default Home;
