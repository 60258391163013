import { t } from '@lingui/macro';

export const getPreferredPaymentDayLabel = {
    preferredPaymentDay: () =>
        t({
            id: 'wizard.form.preferredPaymentDay.label',
            comment: 'text for the preferred payment day label',
            message: 'Preferred payment day',
        }),
    noPreference: () =>
        t({
            id: 'wizard.form.preferredPaymentDay.options.noPreference',
            comment:
                'No preference option for the preferred payment day radio button input',
            message: 'No preference',
        }),
};
