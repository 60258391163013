import validateValueInGivenRange from '@validator/validateValueInGivenRange';
import useAmountAlternativeRange from '../../../../../hooks/useAmountAlternativeRange';

/**
 * @param {number|null} idealQrAmount
 * @returns {boolean}
 */
export default function useCheckIdealQrAmountIsValid(idealQrAmount) {
    const { minAmountAlternative, maxAmountAlternative } =
        useAmountAlternativeRange();

    if (!idealQrAmount) {
        return false;
    }

    // This assumes that the regular amount is also in range of the above provided
    // values, for simplicity
    return validateValueInGivenRange(
        idealQrAmount,
        minAmountAlternative,
        maxAmountAlternative,
    );
}
