import {
    AppConfig,
    AppConfigIdealQr,
} from '../../../shared/src/contracts/model/appConfig';

export function resolveIdealQrIsEnabled(appConfig: AppConfig): boolean {
    return !!appConfig?.idealQr?.enabled;
}

export function resolveIdealQrOptionalConfigValue(
    appConfig: AppConfig,
): NonNullable<AppConfigIdealQr['optional']> | null {
    const isEnabled = resolveIdealQrIsEnabled(appConfig);

    if (!isEnabled) {
        throw new Error(
            `Ideal QR is not enabled in this app config, so it cannot be optional or not`,
        );
    }

    const value = appConfig?.idealQr?.optional;

    return value === undefined ? null : value;
}

export function resolveAmountAlternativeRange(appConfig: AppConfig): {
    minAmountAlternative: number;
    maxAmountAlternative: number;
} {
    return {
        minAmountAlternative: appConfig?.minAmountAlternative || 1,
        maxAmountAlternative: appConfig?.maxAmountAlternative || 100000,
    };
}

export function resolveManualIBANChecksumInputIsRequired(
    appConfig: AppConfig,
): boolean {
    return typeof appConfig.manualIbanChecksumInput === 'boolean'
        ? appConfig.manualIbanChecksumInput
        : false;
}

export function resolveArePersonalOptInsDisabled(
    appConfig: AppConfig,
): boolean {
    return (
        appConfig.receiveEmailLabel === false &&
        appConfig.receiveCallsLabel === false &&
        appConfig.receiveSmsLabel === false
    );
}

export function resolveSelectedTemplate(
    appConfig: AppConfig,
    templatePath: string,
): boolean {
    const selectedTemplate = appConfig?.formLayout;
    return typeof selectedTemplate === 'string'
        ? selectedTemplate === templatePath
        : false;
}

export function resolveTaxDeductionPercentage(appConfig: AppConfig): number {
    const value = appConfig?.amountTaxDeductionPercentage;

    if (value === undefined) {
        return 0;
    }

    if (typeof appConfig?.amountTaxDeductionPercentage !== 'number') {
        throw new Error(
            'Expecting amountTaxDeductionPercentage to be a number, when defined',
        );
    }

    return appConfig.amountTaxDeductionPercentage;
}

export function resolveIsMailOptInLocked(appConfig: AppConfig): boolean {
    return appConfig.receiveMailInputLocked || false;
}

export function resolveIsEmailOptInLocked(appConfig: AppConfig): boolean {
    return appConfig.receiveEmailInputLocked || false;
}

export function resolveIsCallsOptInLocked(appConfig: AppConfig): boolean {
    return appConfig.receiveCallsInputLocked || false;
}

export function resolveIsSmsOptInLocked(appConfig: AppConfig): boolean {
    return appConfig.receiveSmsInputLocked || false;
}

export function resolveDefaultCountryCode(appConfig: AppConfig): string {
    return appConfig.defaultCountryCode?.toUpperCase() || 'NL';
}

export function resolveDefaultSignatureType(appConfig: AppConfig): string {
    return appConfig.defaultSignatureType || 'canvas';
}

// Resolve the enabled signature types, with the default signature type as the first element if it is not already in the list
// If no enabled signature types are defined, all signature types are enabled by default
export function resolveEnabledSignatureTypes(appConfig: AppConfig): string[] {
    if (appConfig.enabledSignatureTypes) {
        return [
            ...new Set([
                resolveDefaultSignatureType(appConfig),
                ...appConfig.enabledSignatureTypes,
            ]),
        ];
    }

    return ['canvas', 'sms', 'audio'];
}

export function resolveIsSocialSecurityNumberFieldEnabled(
    appConfig: AppConfig,
): boolean {
    return !!appConfig?.showSocialSecurityNumberField;
}

export function resolveIsEmergencyReSendEnabled(appConfig: AppConfig): boolean {
    return !!appConfig?.emergencyResendEnabled;
}
