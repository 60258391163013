import React from 'react';
import { Trans } from '@lingui/macro';

export const getWizardLabels = () => ({
    close: (
        <Trans
            id="button.close"
            comment="Label for a button that closes something"
        >
            Close
        </Trans>
    ),
});
