import React from 'react';
import { Trans } from '@lingui/macro';

export const getHomeFooterLabels = () => ({
    complianceDocumentation: (
        <Trans
            id="home.compliance.document"
            comment="Link to open the compliance documentation"
        >
            Compliance documentation
        </Trans>
    ),
});
