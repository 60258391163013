// noinspection JSIgnoredPromiseFromCall

import { getFilePath } from '@integrations/service/api';
import { Campaign } from '../../../../../../shared/src/contracts/model/campaign';

async function precacheFile(
    campaignId: string,
    path: string,
): Promise<unknown> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve) => {
        const src = getFilePath(campaignId, path);
        if (!src) {
            return resolve(undefined);
        }

        const image = new Image();
        image.onload = resolve;
        image.onerror = resolve;
        image.src = src;
    });
}

export default function precacheCampaignFiles(campaigns: Campaign[]): void {
    campaigns.forEach((campaign) => {
        if (campaign.appConfig?.brandImage) {
            precacheFile(campaign.id, campaign.appConfig.brandImage);
        }

        if (campaign.appConfig?.splashImage) {
            precacheFile(campaign.id, campaign.appConfig.splashImage);
        }

        if (campaign.appConfig?.thanksImage) {
            precacheFile(campaign.id, campaign.appConfig.thanksImage);
        }

        if (campaign.appConfig?.badgeImage) {
            precacheFile(campaign.id, campaign.appConfig.badgeImage);
        }
    });
}
