import firebase from 'firebase/app';
import 'firebase/auth';

const initPath = '/__/firebase/init.json';

let app: firebase.app.App | null = null;

export default async function getFirebaseAuth(): Promise<firebase.auth.Auth> {
    if (app === null) {
        const response = await fetch(initPath);
        const config = await response.json();

        if (firebase.apps.length === 0) {
            app = firebase.initializeApp(config);
        } else {
            app = firebase.app(); // if already initialized, use that one
        }
    }

    return app.auth();
}
