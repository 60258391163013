import { Trans } from '@lingui/macro';
import React from 'react';

export const getUpdateCampaignsLabels = () => ({
    update: (
        <Trans
            id="campaign.button.update"
            comment="button text for updating the available campaigns in the app"
        >
            Update
        </Trans>
    ),
});
