import { combineReducers } from '@reduxjs/toolkit';
import bufferSlice from '../slice/buffer/bufferSlice';
import onlineSlice from '../slice/online/onlineSlice';
import userSlice from '../slice/user/userSlice';
import tenantSlice from '../slice/tenant/tenantSlice';
import notificationSlice from '../slice/notification/notificationSlice';
import idealQrSlice from '../slice/idealQr/idealQrSlice';
import stateStorage from '../storage/stateStorage';
import { SliceNamespace } from '../constants/namespace';

export const resetAction = { type: 'reset' } as const;

export function createReducer() {
    const appReducer = combineReducers({
        [SliceNamespace.Buffer]: bufferSlice.reducer,
        [SliceNamespace.Online]: onlineSlice.reducer,
        [SliceNamespace.User]: userSlice.reducer,
        [SliceNamespace.Tenant]: tenantSlice.reducer,
        [SliceNamespace.Notification]: notificationSlice.reducer,
        [SliceNamespace.IdealQr]: idealQrSlice.reducer,
    });

    // As the store module is loaded only once, and in tests we want each test to have a clean store state,
    // we need a mechanism to reset it. This allows us to by executing a resetAction that resets the state.
    // @see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
    const rootReducer: typeof appReducer = (state, action) => {
        if (action.type === resetAction.type) {
            return appReducer(undefined, action);
        }

        return appReducer(state, action);
    };

    return rootReducer;
}

export function createEnhancers() {
    return [
        stateStorage([
            'buffer.entries',
            'buffer.result.lastSuccess',
            'user.details',
            'user.signedIn',
            'user.preferences',
            'user.result.lastSuccess',
            'tenant.campaigns.active',
            'tenant.campaigns.list',
            'tenant.name',
            'tenant.appConfig',
            'tenant.result.lastSuccess',
        ]),
    ];
}
