import { i18n } from '@lingui/core';

const defaultCountryCode = ['nl'];

export function getDefaultLanguageOption(
    languageOptions,
    locale = i18n.locale,
) {
    const keys = languageOptions || defaultCountryCode;
    if (keys.indexOf(locale) === -1) {
        return keys[0];
    }
    return locale;
}
