import React from 'react';
import { createPortal } from 'react-dom';
import UpdateNotification from './components/updateNotification/UpdateNotification';

export default function NotificationPortal() {
    return createPortal(
        <>
            <UpdateNotification />
        </>,
        document.body,
    );
}
