import persistStateInLocalStorage from 'redux-localstorage';
import set from 'lodash/set';
import get from 'lodash/get';

const slicer = (paths) => (state) =>
    paths.reduce((subset, path) => set(subset, path, get(state, path)), {});

export const storageKey = 'redux_v2';

const stateStorage = (paths) =>
    persistStateInLocalStorage(paths, { key: storageKey, slicer });

export default stateStorage;
