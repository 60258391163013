/* eslint-disable no-redeclare */

import { useAppSelector } from './useAppSelector';
import { Campaign } from '../../../shared/src/contracts/model/campaign';

function useActiveCampaign(throwIfNotSet: true): Campaign;
function useActiveCampaign(throwIfNotSet: false): Campaign | null;
function useActiveCampaign(throwIfNotSet: boolean): Campaign | null {
    const campaign = useAppSelector((state) => state.tenant.campaigns.active);

    if (!campaign && throwIfNotSet) {
        throw new Error(
            'Expecting active campaign to be available at this point',
        );
    }

    return campaign;
}

export default useActiveCampaign;
