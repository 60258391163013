import { useEffect, useRef } from 'react';
import { DeepMap, FieldError, FieldErrors, FieldValues } from 'react-hook-form';

export default function useScrollToFirstFormError(
    errorsForRequiredFields: DeepMap<FieldValues, FieldError>[],
    errorsForEntireForm: FieldErrors,
): void {
    const hasScrolledRef = useRef(false);

    // If required fields errors for iDeal QR flow match the number of errors in the entire form, it means the field
    // errors have also been rendered, and we are able to scroll the first one into view. This is a kind-of workaround
    // to ensure the user sees the errors, as this is not a standard form submission, and therefore react-hook-form
    // will not do the scroll into view for us.
    const same =
        Object.keys(errorsForEntireForm).length ===
        errorsForRequiredFields.length;

    useEffect(() => {
        if (hasScrolledRef.current) {
            return;
        }

        const formRowElement =
            document.querySelector('.invalid-feedback')?.parentElement;

        if (!same || !formRowElement) {
            return;
        }

        formRowElement.scrollIntoView({
            behavior: 'smooth',
        });

        hasScrolledRef.current = true;
    }, [same]);
}
