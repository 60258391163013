import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { reset } from '@store/slice/idealQr/idealQrSlice';
import routes from '../routes';
import { useAppDispatch } from './useAppDispatch';

/**
 * As we don't want to have the global iDeal QR state set when we start a new
 * form, we clear it as soon as the user navigates away from the form.
 */
export default function useResetIdealQrStateWhenNavigatingAwayFromForm() {
    const location = useLocation();

    const currentPathname = location.pathname;

    const previousPathnameRef = useRef(currentPathname);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (
            previousPathnameRef.current === routes.wizard.form &&
            currentPathname !== routes.wizard.form
        ) {
            dispatch(reset());
        }

        previousPathnameRef.current = currentPathname;
    }, [currentPathname, dispatch]);
}
