import { defineMessage } from '@lingui/macro';
import { toTranslationRecord } from '@utilities/translationUtilities';

const translations = {
    headerTitle: defineMessage({
        id: 'wizard.form.payment.headerTitle',
        comment: 'Title of the donation section of the form',
        message: 'Donation',
    }),
    headerTitleBankAccount: defineMessage({
        id: 'wizard.form.payment.headerTitleBankAccount',
        comment: 'Title of the bank account number section of the form',
        message: 'Confirm bank account',
    }),
    period: defineMessage({
        id: 'wizard.form.payment.period',
        comment: 'label for the payment period option',
        message: 'Period',
    }),
    startDate: defineMessage({
        id: 'wizard.form.payment.startDate',
        comment: 'label for the startdate input field',
        message: 'Registration date',
    }),
    iban: defineMessage({
        id: 'wizard.form.payment.iban',
        comment: 'label for the iban input field',
        message: 'IBAN',
    }),
};

export default toTranslationRecord<keyof typeof translations>(translations);
