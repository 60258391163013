import { useEffect } from 'react';
import useAppConfig from '@hooks/useAppConfig';
import Interval from '@constants/interval';
import { resolveIdealQrIsEnabled } from '@resolver/appConfigValueResolver';
import useIdealQrState from '@hooks/useIdealQrState';
import usePreferredAmountPresetForInterval from '../../../hooks/usePreferredAmountPresetForInterval';

/**
 * When using iDeal QR and the recruiter toggles from monthly to one time donation, we want
 * the amount to be reset to the iDeal QR amount, as no mandate is required for a one-time
 * donation, so it makes no sense to have a different amount there.
 *
 * @param {import('react-hook-form').UseFormMethods} form
 */
export default function useResetAmountOnIntervalChange(form) {
    const { watch, setValue } = form;

    const appConfig = useAppConfig();

    const { codeId: idealQrId, amount: idealQrAmount } = useIdealQrState();
    const idealQrChargeWasGenerated = !!idealQrId;

    const interval = watch('interval');
    const preset = usePreferredAmountPresetForInterval(interval);

    useEffect(() => {
        if (
            !resolveIdealQrIsEnabled(appConfig) ||
            interval !== Interval.oneTime ||
            !idealQrChargeWasGenerated
        ) {
            return;
        }

        if (preset.includes(idealQrAmount)) {
            setValue('amount', idealQrAmount.toString());
        } else {
            setValue('amount', 'alt');
            setValue('amountAlternative', idealQrAmount.toString());
        }
    }, [
        appConfig,
        idealQrChargeWasGenerated,
        idealQrAmount,
        idealQrId,
        interval,
        preset,
        setValue,
    ]);
}
