import { configureStore } from '@reduxjs/toolkit';
import { attachOnlineListeners } from './slice/online/onlineSlice';
import { attachOnAuthStateChangedListener } from './slice/user/userSlice';
import {
    createEnhancers,
    createReducer,
    resetAction,
} from './factory/storeFactory';
import createAnalyticsMiddleware from './middleware/createAnalyticsMiddleware';

const store = configureStore({
    reducer: createReducer(),
    enhancers: createEnhancers(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(createAnalyticsMiddleware),
});

export function resetStore(): void {
    store.dispatch(resetAction);
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export function attachGlobalListeners() {
    attachOnlineListeners(store.dispatch);
    attachOnAuthStateChangedListener(store.dispatch);
}

export default store;
