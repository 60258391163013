import useActiveCampaign from '@hooks/useActiveCampaign';
import React from 'react';
import Interval from '@constants/interval';
import Skeleton from './Skeleton';
import complianceTextPerIntervalResolver from '../resolver/complianceTextPerIntervalResolver';
import { ComplianceListComponent } from '../types/ComplianceList';

const TMGQrComplianceList: ComplianceListComponent = ({ interval }) => {
    const campaign = useActiveCampaign(true);

    const activeCampaignName =
        campaign.appConfig?.idealQr?.beneficiary ??
        (campaign.name || 'deze campagne');

    const intervalText = complianceTextPerIntervalResolver(interval);

    return (
        <Skeleton.Container>
            <Skeleton.Heading>Compliance</Skeleton.Heading>
            <Skeleton.List>
                <Skeleton.ListItem>
                    De werver heeft zichzelf voorgesteld.
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft zichzelf gelegitimeerd met de werverspas van{' '}
                    {activeCampaignName}.
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft verteld dat het gaat om {intervalText}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    De werver heeft ter controle het ingevulde formulier samen
                    met u doorlopen om de gemaakte afspraken te confirmeren.
                </Skeleton.ListItem>
                {interval !== Interval.oneTime && (
                    <>
                        <Skeleton.ListItem>
                            De werver heeft samen met u de eerste betaling via
                            QR gedaan en benadrukt dat dit de eerste betaling is
                            van uw maandelijkse donateurschap tot
                            wederopzegging.
                        </Skeleton.ListItem>
                        <Skeleton.ListItem>
                            De werver heeft verteld dat volgende betalingen
                            middels automatisch incasso zullen plaatsvinden.
                        </Skeleton.ListItem>
                        <Skeleton.ListItem>
                            De werver heeft verteld dat opzeggen door de
                            donateur zelf gedaan dient te worden, per mail of
                            via de telefoon.
                        </Skeleton.ListItem>
                        <Skeleton.ListItem>
                            De werver heeft verteld dat u wordt gebeld om de
                            gemaakte afspraken te controleren en om bovenstaande
                            punten met u door te nemen.
                        </Skeleton.ListItem>
                    </>
                )}
            </Skeleton.List>
        </Skeleton.Container>
    );
};

export default TMGQrComplianceList;
