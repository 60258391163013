/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Trans } from '@lingui/macro';

export const getErrorBoundaryLabels = () => ({
    title: (
        <Trans
            id="error.boundary.title"
            comment="title displayed on page displayed when an error occurs"
        >
            Oops! Something went wrong..
        </Trans>
    ),
    supportIntroduction: (
        <Trans
            id="error.boundary.supportIntroduction"
            comment="introduction text on error page to get support"
        >
            Help us improve this tool by informing your supervisor about this
            error. Don't forget to mention the error message above, the url and
            the steps that got you here.
        </Trans>
    ),
    whatToDoNow: (
        <Trans
            id="error.boundary.whatToDoNow"
            comment="text on error page to explain what to do now"
        >
            What would you like to do now?
        </Trans>
    ),
    retry: (
        <Trans
            id="error.boundary.retryButtonLabel"
            comment="label for button on error page to retry action"
        >
            Retry
        </Trans>
    ),
    backToDashboard: (
        <Trans
            id="error.boundary.backToDashboardButtonLabel"
            comment="label for button on error page to go back to dashboard"
        >
            Go back to dashboard
        </Trans>
    ),
    getSupport: (
        <Trans
            id="error.boundary.getSupportButtonLabel"
            comment="label for button on error page to get support"
        >
            Get support
        </Trans>
    ),
});
