import React, { FC, ReactNode } from 'react';

const Container: FC<{ children: ReactNode }> = ({ children }) => (
    <section className="mt-4 mb-4 border rounded p-4 text-muted">
        {children}
    </section>
);

const Heading: FC<{ children: string }> = ({ children }) => (
    <h1 className="fs-5">{children}</h1>
);

const List: FC<{ children: ReactNode }> = ({ children }) => <ul>{children}</ul>;

const ListItem: FC<{ children: ReactNode }> = ({ children }) => (
    <li>{children}</li>
);

export default {
    Container,
    Heading,
    List,
    ListItem,
};
