/* eslint-disable no-param-reassign */

import { CaseReducer } from '@reduxjs/toolkit';
import { BufferState, EntryCollection } from '../types';
import { captureBreadcrumb } from '../../../../utilities/errorCapturingUtilities';

const maxAge = 2 * 24 * 60 * 60 * 1000; // 2 days

export const cleanupReducer: CaseReducer<BufferState> = (state) => {
    const minTimestamp = Date.now() - maxAge;

    const keys = Object.keys(state.entries) as Array<keyof EntryCollection>;

    keys.forEach((timestamp) => {
        const timestampAsNumber = parseInt(timestamp, 10);
        if (Number.isNaN(timestampAsNumber)) {
            throw new Error(
                'Expecting entry timestamp to be a timestamp, transformable to a number',
            );
        }

        if (
            state.entries[timestamp].result &&
            timestampAsNumber < minTimestamp
        ) {
            captureBreadcrumb('Buffer entry cleanup', 'info', 'submit', {
                timestamp,
            });

            delete state.entries[timestamp];
        }
    });
};
