import oxfd from './oxfd.json';
import cde from './cde.json';
import scde from './scde.json';
import cbm from './cbm.json';
import vwv from './vwv.json';
import sn from './sn.json';
import snOneTimePurchase from './snOneTimePurchase.json';
import loh from './loh.json';
import loho from './loho.json';
import lohhk from './lohhk.json';
import pdf from './pdf.json';

export default {
    'tmg/oxfd': oxfd,
    'tmg/cde': cde,
    'tmg/scde': scde,
    'tmg/cbm': cbm,
    'tmg/vwv': vwv,
    'tmg/sn': sn,
    'tmg/snOneTimePurchase': snOneTimePurchase,
    'tmg/loh': loh,
    'tmg/loho': loho,
    'tmg/lohhk': lohhk,
    'tmg/pdf': pdf,
};
