import { getLocalizedErrorMessages } from '@integrations/errorMessages.trans';
import { formatErrorMessage } from '@integrations/createErrorMessage';

function getErrorMessage(error) {
    const localizedErrorMessages = getLocalizedErrorMessages();
    switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
            return localizedErrorMessages.invalidCredentials;
        case 'auth/user-token-expired':
        case 'auth/invalid-user-token':
            return localizedErrorMessages.unauthorized;
        case 'auth/network-request-failed':
            return localizedErrorMessages.cantReachServer;
        case 'auth/web-storage-unsupported':
        case 'auth/too-many-requests':
            return formatErrorMessage(
                localizedErrorMessages.clientError,
                error.message,
            );
        default:
            return null;
    }
}

export default async function wrapFirebaseError(wrappedFunction) {
    try {
        return await wrappedFunction();
    } catch (error) {
        const message = getErrorMessage(error);
        if (message !== null) {
            throw new Error(message);
        } else {
            throw error;
        }
    }
}
