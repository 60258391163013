/* eslint-disable jsdoc/require-param */
import { useEffect, useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import useActiveCampaign from '@hooks/useActiveCampaign';
import { WizardFormState } from '../../../types/wizardFormState';
import {
    ObjectCategory,
    StringCategory,
} from '../../../../../../../../../shared/src/contracts/model/category';

interface CategoryCollection extends ObjectCategory {
    id: string;
}

function isObjectCategory(
    category: StringCategory | ObjectCategory | undefined,
): category is ObjectCategory {
    return typeof category === 'object';
}

/**
 * This hook is used in conjunction with the <WizardFormOneTimePurchase /> layout component
 * when the amount is changed, it will check if there's a category with the same amount
 * and set the category accordingly.
 */
export default function useHandleCategoryOnAmountChange(
    form: UseFormMethods<WizardFormState>,
) {
    const { watch, setValue } = form;

    const campaign = useActiveCampaign(true);
    const amount = watch('amount');

    const categories = useMemo(() => {
        const existingCategories = campaign.categories;

        if (!existingCategories) {
            return [];
        }

        return Object.keys(existingCategories).reduce(
            (acc: CategoryCollection[], categoryId) => {
                const value = existingCategories[categoryId];

                if (!isObjectCategory(value)) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        id: categoryId,
                        ...value,
                    },
                ];
            },
            [],
        );
    }, [campaign]);

    useEffect(() => {
        if (!amount) {
            return;
        }

        const existingCategory = categories.find(
            (category) => category.amount?.toString() === amount,
        );

        if (existingCategory) {
            setValue('categories', existingCategory.id);
        }
    }, [amount, categories, setValue]);
}
