import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.label
 * @param {Function} props.onConfirm
 * @returns {React.ReactElement}
 */
function Notification({ title, label, onConfirm }) {
    return (
        <div className="show toast fixed-bottom m-gutter">
            <div className="toast-body d-flex flex-row align-items-center">
                <p className="me-auto mb-0">{title}</p>
                <button
                    type="button"
                    className="btn btn-primary btn-sm ms-1"
                    onClick={() => onConfirm(true)}
                >
                    {label}
                </button>
                <button
                    type="button"
                    className="ms-auto btn-close"
                    data-dismiss="toast"
                    onClick={() => onConfirm(false)}
                    aria-label="Close"
                />
            </div>
        </div>
    );
}

Notification.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default Notification;
