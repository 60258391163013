import { defineMessage } from '@lingui/macro';

export default {
    placeholder: defineMessage({
        id: 'wizard.form.address-completion.placeholder',
        comment: 'Address autocompletion placeholder',
        message: 'City, street or zip code…',
    }),
    label: defineMessage({
        id: 'wizard.form.address-completion.label',
        comment: 'Address autocompletion label',
        message: 'Find address',
    }),
};
