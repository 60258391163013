import React, { useEffect } from 'react';
import { Trans } from '@lingui/react';
import { ensureFirstLetterIsUppercase } from '@utilities/textUtilities';
import { contextLogger } from '@utilities/logger';
import translations, {
    fieldTranslations,
} from './formValidationErrorsOverview.trans';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods}  props.form
 * @returns {React.ReactElement|null}
 */
function FormValidationErrorsOverview({ form }) {
    const {
        formState: { isSubmitted, errors },
    } = form;

    // For better insight in Sentry, log form errors when user submits
    useEffect(() => {
        if (isSubmitted && Object.keys(errors).length > 0) {
            contextLogger.warn('Encountered form errors', errors);
        }
    }, [errors, isSubmitted]);

    const errorEntries = Object.entries(errors);
    if (!isSubmitted || errorEntries.length === 0) {
        return null;
    }

    return (
        <section className="my-4 p-4 alert alert-danger" role="alert">
            <h3 className="fs-5 mb-3">{translations.title()}</h3>
            <ul>
                {errorEntries.map(([field, error]) => {
                    const fieldTranslation = fieldTranslations[field];

                    let fieldLabel;
                    if (typeof fieldTranslation === 'function') {
                        fieldLabel = fieldTranslation();
                    } else if (typeof fieldTranslation === 'object') {
                        fieldLabel = <Trans id={fieldTranslation.id} />;
                    } else {
                        fieldLabel = ensureFirstLetterIsUppercase(field);
                    }

                    return (
                        <li key={field}>
                            <strong>{fieldLabel}:</strong> {error.message}
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

FormValidationErrorsOverview.propTypes = {
    form: formPropTypes.isRequired,
};

export default FormValidationErrorsOverview;
