import { useState } from 'react';
import {
    UseFormMethods,
    FieldValues,
    DeepMap,
    FieldError,
} from 'react-hook-form';
import { ValidationError } from 'yup';
import { useDebouncedEffect } from '@hooks/useDebouncedEffect';
import useAppConfig from '@hooks/useAppConfig';
import { IdealQrState } from '@store/slice/idealQr/types';
import useIdealQrState from '@hooks/useIdealQrState';
import createValidationSchema from '../../../../../factory/validationSchemaFactory';
import type { AppConfig } from '../../../../../../../../../../../shared/src/contracts/model/appConfig';

async function checkPersonalAndContactDataIsValid(
    values: FieldValues,
    appConfig: AppConfig,
    idealQrState: IdealQrState,
) {
    const validatorSchema = createValidationSchema(appConfig, idealQrState);

    const partialValidatorSchema = validatorSchema.omit([
        'amount',
        'amountAlternative',
        'iban',
        'signaturePayload',
        'signatureType',
    ]);

    await partialValidatorSchema.validate(values, {
        abortEarly: false, // Do not abort after first validation error and gather all errors
        context: {
            appConfig,
        },
    });
}

export type ValidationErrors = DeepMap<FieldValues, FieldError>[];

export default function useHasValidPersonalAndContactData(
    form: UseFormMethods,
): { valid: boolean; errors: ValidationErrors } {
    const [isValid, setIsValid] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<ValidationErrors>([]);

    const { getValues } = form;

    const appConfig = useAppConfig();
    const idealQrState = useIdealQrState();

    const values = getValues();

    useDebouncedEffect(
        () => {
            (async () => {
                try {
                    await checkPersonalAndContactDataIsValid(
                        values,
                        appConfig,
                        idealQrState,
                    );

                    setFieldErrors([]);
                    setIsValid(true);
                } catch (error: unknown) {
                    if (error instanceof ValidationError) {
                        setIsValid(false);
                        setFieldErrors(error.inner);

                        return;
                    }

                    throw error;
                }
            })();
        },
        1000,
        [values, appConfig],
    );

    return { valid: isValid, errors: fieldErrors };
}
