import { t } from '@lingui/macro';

export const getSignatureRecorderLabels = (remainingDuration) => ({
    microphoneError: t({
        id: 'wizard.form.confirmation.audioSignature.error.microphone',
        comment:
            'Error that is shown when the app does not have permission to use the microphone',
        message:
            'Cannot start recording, please check your microphone&apos;s permission settings',
    }),
    savingRecordingError: t({
        id: 'wizard.form.confirmation.audioSignature.error.savingRecording',
        comment:
            'Error that is shown when the app does not have permission to use the microphone',
        message: 'An error occured whilst saving the recording',
    }),
    title: t({
        id: 'wizard.form.confirmation.audioSignature.title',
        comment: 'Title for the audio signature section',
        message: 'Audio Recording',
    }),
    stop: t({
        id: 'wizard.form.confirmation.audioSignature.button.stop',
        comment: 'Label for the button to stop the recording process',
        message: 'Stop',
    }),
    record: t({
        id: 'wizard.form.confirmation.audioSignature.button.record',
        comment: 'Label for the button to start the recording process',
        message: 'Start recording',
    }),
    retry: t({
        id: 'button.retry',
        comment:
            'Label for the button that allows the user to reset and retry their signature',
        message: 'Retry',
    }),
    secondsRemaining: t({
        id: 'wizard.form.confirmation.audioSignature.secondsRemaining',
        comment:
            'text indication the amount of time is left to record the signature',
        message: `${remainingDuration} seconds remaining.`,
    }),
});
