import { useEffect } from 'react';
import useDetermineDefaultValues from '../../../hooks/useDetermineDefaultValues';

/**
 * @param {boolean} signatureIsNeeded
 * @param {Function} setValue
 */
export default function useResetSignatureFieldsWhenNotNeeded(
    signatureIsNeeded,
    setValue,
) {
    const defaultValues = useDetermineDefaultValues();

    useEffect(() => {
        if (!signatureIsNeeded) {
            setValue('signaturePayload', defaultValues.signaturePayload);
            setValue('signatureType', defaultValues.signatureType);
        }
    }, [
        defaultValues.signaturePayload,
        defaultValues.signatureType,
        setValue,
        signatureIsNeeded,
    ]);
}
