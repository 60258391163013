import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.ReactElement}
 */
function Modal({ children }) {
    return (
        <>
            <div className="modal-backdrop show" />
            <div className="modal d-block" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">{children}</div>
                </div>
            </div>
        </>
    );
}

Modal.propTypes = {
    children: PropTypes.node,
};

export default Modal;
