import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

type Props = Pick<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'role'
> & {
    children: ReactNode;
};

const FormText: FC<Props> = ({ children, ...otherProps }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...otherProps} className="form-text">
        {children}
    </div>
);

export default FormText;
