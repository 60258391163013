import React from 'react';
import { Trans } from '@lingui/macro';

export const getCampaignNotificationModalLabels = () => ({
    modalTitle: (
        <Trans
            id="modal.campaignNotification.modalTitle"
            comment="Title for a modal that is shown when the user has not yet selected a campaign"
        >
            Select a campaign
        </Trans>
    ),
    message: (
        <Trans
            id="modal.campaignNotification.message"
            comment="Message for a modal that is shown when the user has not yet selected a campaign"
        >
            You need to select a campaign before continuing.
        </Trans>
    ),
});
