import { t } from '@lingui/macro';
import { useMemo } from 'react';

export const useSexOptions = (includeOtherSex, includeOptionalGender) =>
    useMemo(
        () => ({
            M: t({
                id: 'wizard.form.sex.options.male',
                comment: 'Male option for the sex radio button input',
                message: 'Male',
            }),
            V: t({
                id: 'wizard.form.sex.options.female',
                comment: 'Female option for the sex radio button input',
                message: 'Female',
            }),
            ...(includeOtherSex === true && {
                O: t({
                    id: 'wizard.form.sex.options.other',
                    comment: 'Other option for the sex radio button input',
                    message: 'X',
                }),
            }),
            ...(includeOptionalGender === true && {
                N: t({
                    id: 'wizard.form.sex.options.optional-gender',
                    comment:
                        'None of the above option for the sex radio button input',
                    message: 'None of the above',
                }),
            }),
        }),
        [includeOtherSex, includeOptionalGender],
    );

const options = {
    language: {
        nl: 'Nederlands',
        en: 'English',
        de: 'Deutsch',
        fr: 'Français',
    },
    countryCodes: ['NL', 'BE', 'DE', 'FR', 'LU'],
    amountPresets: {
        default: [7.5, 10, 12.5],
        bracket: [10, 15],
        highYield: [15],
    },
};

export default options;
