import React, { ReactNode, FC } from 'react';
import {
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryPropsWithFallback,
} from 'react-error-boundary';
import { captureError } from '@utilities/errorCapturingUtilities';
import OnErrorFallback from './components/OnErrorFallback';

type Props = {
    children: ReactNode;
};

const onError: ErrorBoundaryPropsWithFallback['onError'] = (error, info) => {
    captureError(error, {
        extra: info,
        level: 'error',
    });
};

const ErrorBoundary: FC<Props> = ({ children }) => (
    <ReactErrorBoundary FallbackComponent={OnErrorFallback} onError={onError}>
        {children}
    </ReactErrorBoundary>
);

export default ErrorBoundary;
