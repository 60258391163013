import React, { FC } from 'react';
import { formatAsLocaleDateTime } from '@utilities/dateTimeUtilities';
import { getLoggedInUserDetailsLabels } from '../LoggedInUserDetails.trans';

type Props = {
    lastSuccess: number | null;
};

const SignedInUserInfo: FC<Props> = ({ lastSuccess }) => {
    const labels = getLoggedInUserDetailsLabels();

    return (
        <>
            {labels.loggedIn}{' '}
            {lastSuccess && formatAsLocaleDateTime(lastSuccess)}
        </>
    );
};

export default SignedInUserInfo;
