import { useCallback, useEffect, EffectCallback, DependencyList } from 'react';

export function useDebouncedEffect(
    effect: EffectCallback,
    delay: number,
    deps: DependencyList,
): void {
    // eslint-disable-next-line
    const callback = useCallback(effect, deps);

    useEffect(() => {
        let cleanup: ReturnType<EffectCallback> | null = null;

        const handler = setTimeout(() => {
            cleanup = callback();
        }, delay);

        return () => {
            clearTimeout(handler);

            if (cleanup && typeof cleanup === 'function') {
                cleanup();
            }
        };
    }, [callback, delay]);
}
