import React from 'react';
import PropTypes from 'prop-types';
import { getFilePath } from '@integrations/service/api';

/**
 * @param {object} props
 * @param {string} props.campaignId
 * @param {string} props.splashImage
 * @returns {React.ReactElement}
 */
function LandingPageTheme({ campaignId, splashImage }) {
    return (
        <style
            // eslint-disable-next-line react/no-children-prop
            children={`
              .theme {
                background-image: url(${getFilePath(campaignId, splashImage)});
              }
            `}
        />
    );
}

LandingPageTheme.propTypes = {
    campaignId: PropTypes.string.isRequired,
    splashImage: PropTypes.string.isRequired,
};

export default LandingPageTheme;
