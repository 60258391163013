/* eslint-disable jsx-a11y/no-redundant-roles */

import React from 'react';
import './Form.scss';
import PropTypes from 'prop-types';
import { formPropTypes } from '../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {React.ReactNode} props.children
 * @param {Function=} props.onSubmit
 * @returns {React.ReactElement}
 */
export function FormContainer({ form, children, onSubmit }) {
    return (
        <form onSubmit={form.handleSubmit(onSubmit)} role="form">
            {children}
        </form>
    );
}

FormContainer.propTypes = {
    form: formPropTypes.isRequired,
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func, // Some forms are not supposed to do anything after submitting
};

export { default as TextInput } from './components/textInput/TextInput';
export { default as RadioInput } from './components/radioInput/RadioInput';
export { CheckInput } from './components/CheckInput';
export { SelectInput } from './components/SelectInput';
