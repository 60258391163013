/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import FormLayout from '@primitives/formLayout/FormLayout';
import useAppConfig from '@hooks/useAppConfig';
import defaultLayout from './layouts/default.json';
import defaultAddressCompletionLayout from './layouts/defaultAddressCompletion.json';
import exampleLayout from './layouts/example.json';
import idealLayout from './layouts/ideal.json';
import tmgLayouts from './layouts/tmg';
import svhkLayouts from './layouts/svhk';
import lohLayouts from './layouts/loh';
import registry from './registry/registry';
import { formPropTypes } from '../../../../../../propTypes';

const layouts = {
    default: defaultLayout,
    defaultAddressCompletion: defaultAddressCompletionLayout,
    example: exampleLayout,
    ideal: idealLayout,
    ...tmgLayouts,
    ...svhkLayouts,
    ...lohLayouts,
};

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @returns {React.ReactElement}
 */
function WizardFormLayout({ form }) {
    const appConfig = useAppConfig();
    const layout = layouts[appConfig?.formLayout] || layouts.default;

    return (
        <FormLayout form={form} registry={registry}>
            {layout.children}
        </FormLayout>
    );
}

WizardFormLayout.propTypes = {
    form: formPropTypes.isRequired,
};

export default WizardFormLayout;
