import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from '@primitives/form/components/radioInput/RadioInput';
import { getPreferredPaymentDayLabel } from './PreferredPaymentDayRadio.trans';
import { formPropTypes } from '../../../../../../propTypes';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormMethods} props.form
 * @param {Object<string, any>} props.options
 * @returns {React.ReactElement}
 */
function PreferredPaymentDayRadio({ form, options }) {
    return (
        <RadioInput
            form={form}
            inline
            options={options}
            name="preferredPaymentDay"
            label={getPreferredPaymentDayLabel.preferredPaymentDay()}
        />
    );
}

PreferredPaymentDayRadio.propTypes = {
    form: formPropTypes.isRequired,
    options: PropTypes.object.isRequired,
};

export default PreferredPaymentDayRadio;
