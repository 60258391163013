import React from 'react';
import PropTypes from 'prop-types';
import Button from '@primitives/button/Button';
import T from '../IdealQr.trans';

/**
 * @param {object} props
 * @param {Function} props.onClick
 * @param {boolean} props.disabled
 * @param {boolean} props.loading
 * @returns {React.ReactElement}
 */
const RetrieveStatusButton = ({ onClick, disabled, loading }) => (
    <Button
        type="button"
        disabled={disabled}
        className="btn btn-secondary"
        onClick={onClick}
        spinner={loading}
    >
        {`${T.retrieveStatus()}`}
    </Button>
);

RetrieveStatusButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default RetrieveStatusButton;
