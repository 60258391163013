import Card from '@primitives/form/components/Card';
import { CheckInput } from '@primitives/form/components/CheckInput';
import FormRow from '@primitives/form/components/FormRow';
import RadioInput from '@primitives/form/components/radioInput/RadioInput';
import FormSection from '@primitives/form/components/FormSection';
import { SelectInput } from '@primitives/form/components/SelectInput';
import SelectOrTextInput from '@primitives/form/components/SelectOrTextInput';
import TextInput from '@primitives/form/components/textInput/TextInput';
import DateTextInput from '@primitives/form/components/DateTextInput';
import Image from '../../image/Image';
import WizardFormPersonal from '../../wizardFormPersonal/WizardFormPersonal';
import WizardFormCustom from '../../wizardFormCustom/WizardFormCustom';
import WizardFormAddress from '../../wizardFormAddress/WizardFormAddress';
import WizardFormAddressOptInsFormSection from '../../wizardFormAddressOptInsFormSection/WizardFormAddressOptInsFormSection';
import WizardFormPayment from '../../wizardFormPayment/WizardFormPayment';
import WizardFormConfirmation from '../../wizardFormConfirmation/WizardFormConfirmation';
import SignatureFormRow from '../../signatureFormRow/SignatureFormRow';
import CountryInput from '../../countryInput/CountryInput';
import InitialsTextInput from '../../initialsTextInput/InitialsTextInput';
import '../academicTitleOptions.trans';
import AddressCompletionInput from '../../addressCompletionInput/AddressCompletionInput';
import IBANInput from '../../ibanInput/IBANInput';
import WizardFormIdealPledge from '../../wizardFormIdealPledge/WizardFormIdealPledge';
import EmailFormInputWithDomainSuggestion from '../../emailFormInputWithDomainSuggestion/EmailFormInputWithDomainSuggestion';
import WizardFormIdealPayment from '../../wizardFormIdealPayment/WizardFormIdealPayment';
import WizardFormProduct from '../../wizardFormProduct/WizardFormProduct';
import WizardFormOneTimePurchase from '../../wizardFormOneTimePurchase/WizardFormOneTimePurchase';
import WizardFormLotteryPayment from '../../wizardFormLotteryPayment/WizardFormLotteryPayment';
import PhoneTextInput from '../../phoneTextInput/PhoneTextInput';
import WizardFormPersonalOptInsFormSection from '../../wizardFormPersonalOptInsFormSection/WizardFormPersonalOptInsFormSection';

export default {
    Card,
    CheckInput,
    FormRow,
    Image,
    RadioInput,
    FormSection,
    SelectInput,
    SelectOrTextInput,
    TextInput,
    DateTextInput,
    WizardFormPersonal,
    WizardFormCustom,
    WizardFormAddress,
    WizardFormAddressOptInsFormSection,
    WizardFormPayment,
    WizardFormConfirmation,
    WizardFormIdealPledge,
    WizardFormIdealPayment,
    SignatureFormRow,
    CountryInput,
    PhoneTextInput,
    InitialsTextInput,
    IBANInput,
    AddressCompletionInput,
    EmailFormInputWithDomainSuggestion,
    WizardFormProduct,
    WizardFormOneTimePurchase,
    WizardFormLotteryPayment,
    WizardFormPersonalOptInsFormSection,
};
