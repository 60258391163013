import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {React.ReactNode|React.ReactNode[]} props.children
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */
export function FormGroup({ children, className }) {
    return (
        <div className={classNames('form-group mb-3', className)}>
            {children}
        </div>
    );
}

FormGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
