import { createAsyncThunk } from '@reduxjs/toolkit';
import * as identityPlatformApi from '@integrations/auth/identityPlatformApi';
import type { RootState } from '../../../index';
import { SliceNamespace } from '../../../constants/namespace';
import {
    captureBreadcrumb,
    clearCapturedLoggedInUser,
} from '../../../../utilities/errorCapturingUtilities';

export const fetchSignOutUser = createAsyncThunk(
    'user/fetch/signOut',
    async () => {
        captureBreadcrumb('Signing out user', 'info', 'auth');

        await identityPlatformApi.signOut();

        clearCapturedLoggedInUser();
    },
    {
        condition: (_payload, { getState }) => {
            const state = getState() as RootState;

            const { result } = state[SliceNamespace.User];

            // When a sign in or out is pending, don't try again
            // noinspection RedundantIfStatementJS
            if (result?.pending === true) {
                return false;
            }

            return true;
        },
    },
);
