import { Trans } from '@lingui/macro';
import React from 'react';

export const getSendEntryTranslations = () => ({
    send: (
        <Trans
            id="entries.button.send"
            comment="label for button to send gathered forms to the database"
        >
            Send
        </Trans>
    ),
});
