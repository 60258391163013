import { useMemo } from 'react';
import ejs from 'ejs';
import { flattenObject } from '@utilities/objectUtilities';
import trans from './useTextTemplate.trans';

/**
 * @param {module:AppConfig.AppConfig} appConfig
 * @param {string} templateKey
 * @param {Object<string, any>} data
 * @returns {string|null}
 */
export default function useTextTemplate(appConfig, templateKey, data) {
    const renderFunction = useMemo(() => {
        const translationValues = flattenObject(data);

        const template =
            appConfig?.[templateKey] || trans[templateKey]?.(translationValues);

        return template && ejs.compile(template);
    }, [appConfig, data, templateKey]);

    return renderFunction?.(data) || null;
}
