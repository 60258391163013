import { defineMessage } from '@lingui/macro';

export default {
    headerTitle: defineMessage({
        id: 'wizard.form.confirmation.headerTitle',
        comment: 'Title for the confirmation section of the form',
        message: 'Confirmation',
    }),
    signatureTitle: defineMessage({
        id: 'wizard.form.confirmation.signatureTitle',
        comment: 'Title for the signature section of the form',
        message: 'Signature',
    }),
    recurringConfirmationLabelText: defineMessage({
        id: 'wizard.form.confirmation.periodConfirmation.label',
        comment: 'Label for explicit consent on frequent donations',
        message:
            'Yes, I am aware this is not a one-time donation and I support until cancellation.',
    }),
    noMandateRequiredNotice: defineMessage({
        id: 'wizard.form.confirmation.noMandateRequired.notice',
        comment: 'Text of notice displayed when no mandate is required',
        message:
            'Mandate is not needed as this is a one-time donation and the donation is already paid for.',
    }),
};
