/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import * as identityPlatformApi from '@integrations/auth/identityPlatformApi';
import type { AppDispatch } from '../../index';
import { fetchSignInUser } from './thunkAction/fetchSignInUser';
import { updateSignedInReducer } from './reducer/updateSignedInReducer';
import { updatePreferencesReducer } from './reducer/updatePreferencesReducer';
import {
    fetchSignInUserFulfilledReducer,
    fetchSignInUserPendingReducer,
    fetchSignInUserRejectedReducer,
} from './reducer/fetchSignInUserReducer';
import {
    fetchSignOutUserFulfilledReducer,
    fetchSignOutUserPendingReducer,
    fetchSignOutUserRejectedReducer,
} from './reducer/fetchSignOutUserReducer';
import { fetchSignOutUser } from './thunkAction/fetchSignOutUser';
import { SliceNamespace } from '../../constants/namespace';
import { UserState } from './types';

export const initialState: UserState = {
    signedIn: false,
    details: null,
    preferences: {
        amountPreset: null,
    },
    result: {
        pending: false,
        lastSuccess: null,
        errorMessage: null,
    },
};

export const createUserSlice = () =>
    createSlice({
        name: SliceNamespace.User,
        initialState,
        reducers: {
            updateSignedIn: updateSignedInReducer,
            updatePreferences: updatePreferencesReducer,
        },
        extraReducers: {
            [fetchSignInUser.pending.toString()]: fetchSignInUserPendingReducer,
            [fetchSignInUser.fulfilled.toString()]:
                fetchSignInUserFulfilledReducer,
            [fetchSignInUser.rejected.toString()]:
                fetchSignInUserRejectedReducer,
            [fetchSignOutUser.pending.toString()]:
                fetchSignOutUserPendingReducer,
            [fetchSignOutUser.fulfilled.toString()]:
                fetchSignOutUserFulfilledReducer,
            [fetchSignOutUser.rejected.toString()]:
                fetchSignOutUserRejectedReducer,
        },
    });

const userSlice = createUserSlice();
export default userSlice;

export const { updateSignedIn, updatePreferences } = userSlice.actions;

export function attachOnAuthStateChangedListener(dispatch: AppDispatch) {
    identityPlatformApi.onAuthStateChanged((signedIn: boolean) => {
        dispatch(updateSignedIn(signedIn));
    });
}
